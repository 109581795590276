import { Box, Button, Center, IconButton, Tooltip } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { formatDuration } from "@src/utils/formatters";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";

const PopoverActivator = () => {
  const { timeTrackingPopoverStore: store, timeTrackingStore } = useStore();
  const ActivatorIcon = () => (
    <Icon name="clock-stopwatch" w="6" h="6" color={store.activatorColor} />
  );

  return timeTrackingStore.runningEntry ? (
    <Tooltip label={store.longRunningText}>
      <Button
        pos="relative"
        overflow="hidden"
        color={store.activatorColor}
        fontSize="lg"
        fontWeight="bold"
        bg={store.bg}
        _hover={{ bg: store.bg }}
        _active={{ bg: store.bg }}
        _focus={{ bg: store.bg }}
        rightIcon={
          <Box w="7">
            <Center
              pos="absolute"
              top="0"
              right="0"
              bottom="0"
              w="10"
              bg={store.iconBg}
              rounded="lg"
            >
              <ActivatorIcon />
            </Center>
          </Box>
        }
        variant="subtle"
      >
        {formatDuration(
          timeTrackingStore.runningEntry.tracked_time_with_running_timer,
          {
            noPadStart: true,
          },
        )}
      </Button>
    </Tooltip>
  ) : (
    <IconButton
      aria-label={t`open track timer`}
      icon={<ActivatorIcon />}
      variant="ghost"
    />
  );
};

export default observer(PopoverActivator);
