import {
  Box,
  Button,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useBoolean,
} from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import {
  useAddScopedUsersToTaskMutation,
  User,
  UserScopeEnum,
} from "@src/__generated__/graphql";
import { AddButton } from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import { onError } from "@src/utils/apollo";
import { useStore } from "@src/utils/hooks";
import { userScopeToType } from "@src/utils/userScopeToType";
import { UserSelect } from "@src/widgets/UserSelect";
import { capitalize } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

export type AddScopedUsersPopoverProps = {
  userIds: User["id"][];
  usersIdsFormOtherScope: User["id"][];
  scope: UserScopeEnum;
};
export const AddScopedUsersPopover = observer(function AddScopedUsersPopover({
  scope,
  userIds,
  usersIdsFormOtherScope,
}: AddScopedUsersPopoverProps) {
  const { taskDetailModalStore, createUserModalStore, UIStore } = useStore();
  const [isLoading, setIsLoading] = useBoolean(false);
  const [user_ids, setUserIds] = React.useState<User["id"][]>([]);

  const [updateTask] = useAddScopedUsersToTaskMutation({
    ...onError(),
  });

  useEffect(() => {
    setUserIds(userIds);
  }, [userIds]);

  const handleUserIdsChange = async (newUserIds: User["id"][]) => {
    setIsLoading.on();
    setUserIds(newUserIds);
    const res = await updateTask({
      variables: {
        id: taskDetailModalStore.taskId.value!,
        user_ids: [...newUserIds, ...usersIdsFormOtherScope],
      },
    });

    if (res.data?.updateTask) {
      UIStore.toast({
        title: t`Users updated`,
        status: "success",
      });
      taskDetailModalStore.fetchTask({ silently: true });
    }

    setIsLoading.off();
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Box>
          <AddButton
            variant="outline"
            rounded="full"
            colorScheme="grey"
            size="sm"
            iconType="normal"
          >
            {capitalize(scope)}
          </AddButton>
        </Box>
      </PopoverTrigger>

      <PopoverContent>
        <PopoverBody p="5">
          <Heading mb="3" size="xs">
            <Trans>Add</Trans>
            {" " + capitalize(scope)}
          </Heading>
          <UserSelect
            isMulti
            asPortal
            isLoading={isLoading}
            userType={userScopeToType(scope)}
            topExtraContent={
              <Button
                mb="2"
                p="1"
                leftIcon={<Icon name="plus" />}
                onClick={() =>
                  createUserModalStore.modalState.onOpen({
                    taskId: taskDetailModalStore.taskId.value,
                    userType: userScopeToType(scope),
                    onSubmit: () => taskDetailModalStore.fetchTask(),
                  })
                }
                variant="link"
              >
                <Trans>Invite</Trans> {" " + capitalize(scope)}
              </Button>
            }
            value={user_ids}
            onChange={handleUserIdsChange}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
});
