import { Box, Button, HStack } from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { useCreateLinkTaskMutation } from "@src/__generated__/urql-graphql";
import { IOption, Select } from "@src/components/ui-kit/Select";
import { TLinkedTasks } from "@src/components/widgets/Modals/ModalCommunication/components/tabs/LinkedTasksTab";
import { TaskSelect } from "@src/widgets/TaskSelect/TaskSelect";
import { FunctionComponent, useEffect, useState } from "react";

interface LinkTasksInputProps {
  taskId: string;
  taskLinkTypes: IOption[] | [];
  onCreateTask: (task: TLinkedTasks[0]) => void;
}

export const LinkedTasksInput: FunctionComponent<LinkTasksInputProps> = ({
  taskId,
  taskLinkTypes,
  onCreateTask,
}) => {
  const [linkedTaskId, setLinkedTaskId] = useState<string>("");
  const [taskLinkTypeId, setTaskLinkTypeId] = useState<string>(
    taskLinkTypes[0].value,
  );
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    if (linkedTaskId && taskLinkTypeId) {
      setIsValid(true);
    }
  }, [taskLinkTypes, linkedTaskId, taskLinkTypeId]);

  const onLinkTypeSelectChange = (value: string) => {
    setTaskLinkTypeId(value);
  };

  const onTaskSelectChange = (value: string) => {
    setLinkedTaskId(value);
  };

  const [{ data, fetching }, createLinkTask] = useCreateLinkTaskMutation();

  useEffect(() => {
    setIsValid(false);
    setLinkedTaskId("");
    if (data?.addTaskLink) {
      onCreateTask(data.addTaskLink);
    }
  }, [data]);

  const onSave = () => {
    createLinkTask({
      task_id: taskId,
      link_type_id: taskLinkTypeId,
      linked_task_id: linkedTaskId,
    });
  };

  return (
    <Box>
      <HStack mt="4" mb="2">
        <Box w="25%">
          <Select
            label={""}
            value={taskLinkTypeId}
            onChange={onLinkTypeSelectChange}
            options={taskLinkTypes}
          />
        </Box>
        <Box w="75%">
          <TaskSelect
            value={linkedTaskId}
            placeholder={t`Search for task`}
            onChange={(value) => onTaskSelectChange(value as string)}
            options={[]}
          />
        </Box>
      </HStack>

      <Box textAlign="right">
        <Button isDisabled={!isValid} isLoading={fetching} onClick={onSave}>
          <Trans>Link</Trans>
        </Button>
      </Box>
    </Box>
  );
};
