import { Brand } from "@src/__generated__/urql-graphql";

export type ToBrandOptionsParams = {
  brands: Pick<Brand, "id" | "name">[];
  modifier: (option: BrandOption) => BrandOption;
};

export type BrandOption = {
  label: Brand["name"];
  value: Brand["id"];
};

export const toBrandOptions = (brands: ToBrandOptionsParams['brands'], modifier: ToBrandOptionsParams["modifier"] = (option) => option,): BrandOption[] =>
  brands.map(({name, id}) => modifier({
        label: name,
        value: id,
      }),
    );
