import {
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  StackProps,
} from "@chakra-ui/react";
import {
  SimpleTaskFragment,
  TaskPriority,
  TaskSystemPriorityEnum,
} from "@src/__generated__/urql-graphql";
import { Icon } from "@src/components/ui-kit/Icon";
import { WrapComponent } from "@src/utils/components/WrapComponent";
import { IconNames } from "@src/utils/types/iconNames";
import { FunctionComponent } from "react";

const PRIORITY_ICON_NAME: Record<TaskSystemPriorityEnum, IconNames> = {
  [TaskSystemPriorityEnum.Low]: "chevron-down",
  [TaskSystemPriorityEnum.Medium]: "equal",
  [TaskSystemPriorityEnum.High]: "chevron-up",
  [TaskSystemPriorityEnum.Urgent]: "alert-square",
};

type TaskPriorityBadgeProps = {
  priority: SimpleTaskFragment["priority"];
} & Omit<StackProps, "onChange"> &
  (
    | {
        isEditable: boolean;
        taskPriorities: TaskPriority[] | undefined;
        onChange: (priorityId: TaskPriority["id"]) => void;
        isLoading?: boolean;
      }
    | {
        isEditable?: never;
        taskPriorities?: never;
        onChange?: never;
        isLoading?: never;
      }
  );

export const TaskPriorityBadge: FunctionComponent<TaskPriorityBadgeProps> = ({
  priority,
  isEditable,
  taskPriorities,
  onChange,
  isLoading,
  ...props
}) => {
  return (
    <WrapComponent
      if={isEditable ?? false}
      with={(children) => (
        <Menu>
          <MenuButton onClick={(e) => e.stopPropagation()}>
            {isLoading ? <Spinner color="teal.500" size="xs" /> : children}
          </MenuButton>
          <Portal>
            <MenuList>
              {taskPriorities?.map(({ name, id }) => (
                <MenuItem
                  key={name}
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange?.(id);
                  }}
                >
                  {name}
                </MenuItem>
              ))}
            </MenuList>
          </Portal>
        </Menu>
      )}
    >
      <HStack
        flex="0"
        color="gray.500"
        fontFamily="heading"
        fontSize="xs"
        fontWeight="semibold"
        whiteSpace="nowrap"
        spacing="2"
        {...props}
      >
        <Icon
          w="5"
          h="5"
          color="grey.500"
          name={PRIORITY_ICON_NAME[priority.system_priority!]}
        />
        <span>{priority.name}</span>
      </HStack>
    </WrapComponent>
  );
};
