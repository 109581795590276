import { WorkspaceSettingsQuery } from "@src/__generated__/urql-graphql";
import { SettingsModalStore } from "@src/stores/SettingsModalStore";
import { CompaniesSettingsState } from "@src/stores/forms/SettingsModalState/CompaniesSettingsState";
import { FormState } from "formstate";
import { WorkspaceSettings } from "../../models/WorkspaceSettings";
import { FinancialSettingsState } from "./FinancialSettingsState";
import { NotificationSettingsState } from "./NotificationSettingsState";
import { PlanningUtilizationState } from "./PlanningUtilizationState";
import { ProjectsSettingsState } from "./ProjectsSettingsState";
import { TasksSettingsState } from "./TasksSettingsState";
import { UserManagementState } from "./UserManagementState";

export class SettingsModalState {
  store: SettingsModalStore;
  notificationsState: NotificationSettingsState;
  tasksState: TasksSettingsState;
  companiesState: CompaniesSettingsState;
  financialState: FinancialSettingsState;
  userManagementState: UserManagementState;
  planningUtilizationState: PlanningUtilizationState;
  projectsState: ProjectsSettingsState;

  form: FormState<{
    notifications: typeof NotificationSettingsState.prototype.form;
    tasks: typeof TasksSettingsState.prototype.form;
    companies: typeof CompaniesSettingsState.prototype.form;
    financial: typeof FinancialSettingsState.prototype.form;
    userManagement: typeof UserManagementState.prototype.form;
    planningUtilization: typeof PlanningUtilizationState.prototype.form;
    projects: typeof ProjectsSettingsState.prototype.form;
  }>;

  constructor(store: SettingsModalStore) {
    this.store = store;
    this.notificationsState = new NotificationSettingsState(store);
    this.tasksState = new TasksSettingsState();
    this.companiesState = new CompaniesSettingsState();
    this.financialState = new FinancialSettingsState();
    this.userManagementState = new UserManagementState(store);
    this.planningUtilizationState = new PlanningUtilizationState();
    this.projectsState = new ProjectsSettingsState();

    this.form = new FormState({
      notifications: this.notificationsState.form,
      tasks: this.tasksState.form,
      companies: this.companiesState.form,
      financial: this.financialState.form,
      userManagement: this.userManagementState.form,
      planningUtilization: this.planningUtilizationState.form,
      projects: this.projectsState.form,
    });
  }

  init(settings: WorkspaceSettingsQuery, workspaceSettings: WorkspaceSettings) {
    this.notificationsState.init(settings.WorkspaceNotifications);
    this.tasksState.init(settings.workspaceTaskSettings);
    this.companiesState.init(settings.workspaceCompaniesSettings);
    this.financialState.init(
      settings.workspaceFinancialSettings,
      settings.workspaceAccountingAccountSetting,
    );
    this.userManagementState.init(settings.workspaceUserManagementSetting);
    this.planningUtilizationState.init(
      settings.updateWorkspacePlanningSettings,
      workspaceSettings.default_planning_view_settings,
    );
    this.projectsState.init(settings.workspaceProjectSettings);
  }
}
