import { Stack } from "@chakra-ui/layout";
import { Switch } from "@chakra-ui/react";
import { Trans } from "@lingui/macro";
import { FormRow } from "@src/components/ui-kit";
import { fieldToCheckboxProps } from "@src/utils/forms/inputHelpers";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";

export const NotificationSettings = observer(function NotificationSettings() {
  const { editUserModalStore } = useStore();

  return (
    <Stack>
      <FormRow title={<Trans>Mute email notifications</Trans>}>
        <Switch
          {...fieldToCheckboxProps(
            editUserModalStore.form.muted_mail_notifications,
          )}
        />
      </FormRow>
    </Stack>
  );
});
