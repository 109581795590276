import {
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { LoadingOverlay } from "@components/ui-kit";
import { Trans, t } from "@lingui/macro";
import { HourlyRates } from "@src/components/modules/users/listing/form/HourlyRates";
import { NotificationSettings } from "@src/components/modules/users/listing/form/NotificationSettings";
import { PersonalSettings } from "@src/components/modules/users/listing/form/PersonalSettings";
import { PlanningUtilization } from "@src/components/modules/users/listing/form/PlanningUtilization";
import { UserStatus } from "@src/components/modules/users/listing/form/UserStatus";
import { TCategory } from "@src/stores/EditUserModalStore";
import { UserType } from "@src/stores/models/Me";
import { can } from "@src/utils/components/permissions";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";

const MENU_COMPONENT_MAP = {
  personal: <PersonalSettings />,
  notifications: <NotificationSettings />,
  "planning-utilization": <PlanningUtilization />,
  "user-status": <UserStatus />,
  "hourly-rates": <HourlyRates />,
} as const;

type MenuItem = {
  key: TCategory;
  label: string;
  accessByUserType: UserType[];
  accessByPermissions: boolean;
};

export const EditUserModal = observer(function EditUserModal() {
  const { editUserModalStore } = useStore();
  const MENU_ITEMS: MenuItem[] = useMemo(() => {
    return (
      [
        {
          key: "personal",
          label: t`Personal settings`,
          accessByUserType: ["internal", "client", "partner"],
          accessByPermissions: true,
        },
        {
          key: "notifications",
          label: t`Notifications`,
          accessByUserType: ["internal", "client", "partner"],
          accessByPermissions: true,
        },
        {
          key: "planning-utilization",
          label: t`Planning & Utilization`,
          accessByUserType: ["internal"],
          accessByPermissions: true,
        },
        {
          key: "user-status",
          label: t`User status`,
          accessByUserType: ["internal", "client", "partner"],
          accessByPermissions: can("user_updateAllfredData"),
        },
        {
          key: "hourly-rates",
          label: t`Internal hourly rates`,
          accessByUserType: ["internal"],
          accessByPermissions: can("user_accessAccountingAttributes"),
        },
      ] as MenuItem[]
    ).filter((item) => {
      const accessByUserType = item.accessByUserType.includes(
        editUserModalStore.modalState.additionalData?.userType!,
      );

      return accessByUserType && item.accessByPermissions;
    });
  }, [editUserModalStore.modalState.additionalData?.userType]);

  return (
    <Modal
      isCentered
      isOpen={editUserModalStore.modalState.isOpen}
      onClose={editUserModalStore.modalState.onClose}
      size={{
        base: "full",
        md: "6xl",
      }}
      trapFocus={false}
    >
      <ModalOverlay />

      <ModalContent>
        <LoadingOverlay
          isLoading={editUserModalStore.isLoading}
          position="absolute"
          transparent={false}
        >
          <Grid templateRows="60vh auto" templateColumns="25% 1fr">
            <GridItem
              borderInlineEndColor="grey.200"
              borderInlineEndStyle="solid"
              borderInlineEndWidth="1px"
              rowSpan={2}
            >
              <ModalHeader as="h2">
                <Trans>Edit user</Trans>
              </ModalHeader>
              <ButtonGroup
                flexDir="column"
                w="full"
                mt="1"
                orientation="vertical"
                paddingBlock="1"
                paddingInline="3"
                variant="ghost"
              >
                {MENU_ITEMS.map((item) => (
                  <Button
                    key={item.key}
                    justifyContent="start"
                    isActive={editUserModalStore.category === item.key}
                    onClick={() => editUserModalStore.setCategory(item.key)}
                  >
                    {item.label}
                  </Button>
                ))}
              </ButtonGroup>
            </GridItem>
            <GridItem pos="relative" overflowY="auto">
              <Stack justify="center" h={63} px="6">
                <Heading size="md">
                  {
                    MENU_ITEMS.find(
                      (item) => item.key === editUserModalStore.category,
                    )?.label
                  }
                </Heading>
                <ModalCloseButton bg="white" />
              </Stack>
              <Stack p="6">
                {MENU_COMPONENT_MAP[editUserModalStore.category]}
              </Stack>
            </GridItem>
            <GridItem
              textAlign="end"
              borderBlockStartColor="grey.200"
              borderBlockStartStyle="solid"
              borderBlockStartWidth="1px"
              paddingBlock="2"
              paddingInline="6"
            >
              <Button
                colorScheme="violet"
                isLoading={editUserModalStore.isSubmitting}
                onClick={() => editUserModalStore.onSubmit()}
              >
                <Trans>Update</Trans>
              </Button>
            </GridItem>
          </Grid>
        </LoadingOverlay>
      </ModalContent>
    </Modal>
  );
});
