import {
  Box,
  Button,
  HStack,
  Square,
  Stack,
  StackProps,
  Text,
  useToken,
} from "@chakra-ui/react";
import { Link } from "@components/ui-kit";
import {
  Brand,
  OurWorkBudgetItem,
  Project,
  Task,
  TimeTrackingItem,
  TimeTrackingWorkType,
} from "@src/__generated__/urql-graphql";
import { Icon } from "@src/components/ui-kit/Icon";
import {
  EMPTY_EDITOR_LENGTH,
  TextEditor,
  TextEditorRef,
} from "@src/components/ui-kit/TextEditor/TextEditor";
import { useSetEditorContent } from "@src/components/ui-kit/TextEditor/hooks/useSetContent";
import { useStore } from "@src/utils/hooks";
import { kebabCase } from "lodash";
import { observer } from "mobx-react-lite";
import React, { FunctionComponent, useRef } from "react";

interface TimeTrackingLabelProps extends StackProps {
  project: Pick<Project, "id" | "code" | "title">;
  brandName: Brand["name"];
  budgetItemTitle: OurWorkBudgetItem["title"];
  taskName: Task["name"];
  taskId: Task["id"];
  workTypeTitle: TimeTrackingWorkType["title"];
  note?: TimeTrackingItem["note"];
  isFromPlanning?: boolean;
}

export const TimeTrackingLabel: FunctionComponent<TimeTrackingLabelProps> =
  observer(function TimeTrackingLabel({
    project,
    brandName,
    taskName,
    taskId,
    budgetItemTitle,
    workTypeTitle,
    note,
    isFromPlanning,
    ...props
  }) {
    const { taskDetailModalStore } = useStore();
    const [grey500] = useToken("colors", ["grey.500"]);
    const editorRef = useRef<TextEditorRef>(null);

    useSetEditorContent({
      ref: editorRef?.current,
      note: note,
    });

    return (
      <HStack overflow="hidden" px="1" py="4" spacing="3" {...props}>
        <Square bg="grey.50" rounded="lg" size="14">
          <Icon
            name={isFromPlanning ? "calendar" : "clock-stopwatch"}
            w="6"
            h="6"
            color={grey500}
          />
        </Square>
        <Stack flex="1" overflow="hidden" dir="vertical" spacing="0">
          <Link
            href={{
              pathname: "/projects/detail",
              query: { id: project.id },
              hash: kebabCase(budgetItemTitle),
            }}
            color="grey.500"
            fontSize="xs"
          >
            <Text display="initial" fontWeight="semibold">
              {project.code}
            </Text>{" "}
            - {budgetItemTitle}
          </Link>

          <Button
            display="block"
            maxW="450"
            textAlign="left"
            whiteSpace="initial"
            onClick={() => {
              taskDetailModalStore.drawerState.onOpen({ id: taskId });
            }}
            variant="link"
          >
            <Text fontWeight="semibold" textOverflow="ellipsis" noOfLines={1}>
              {taskName}
            </Text>
          </Button>
          <Box>
            <Text color="grey.500" fontSize="sm">
              {brandName} • {workTypeTitle}
              {note && note.length > EMPTY_EDITOR_LENGTH && (
                <React.Fragment>
                  {" • "}
                  <TextEditor
                    ref={editorRef}
                    initialValue={note}
                    onChange={() => {}}
                    isEditable={false}
                    attachments={[]}
                    color="grey.500"
                  />
                </React.Fragment>
              )}
            </Text>
          </Box>
        </Stack>
      </HStack>
    );
  });
