import { Box, HStack, Stack } from "@chakra-ui/layout";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  useDisclosure,
} from "@chakra-ui/react";
import { InputDatePicker } from "@components/ui-kit";
import { t, Trans } from "@lingui/macro";
import { ActivateUserModal } from "@src/components/modules/users/listing/table/ActivateUserModal";
import { DeactivateUserModal } from "@src/components/modules/users/listing/table/DeactivateUserModal";
import { appStore } from "@src/stores/AppStore";
import { useStore } from "@src/utils/hooks";
import { ProjectManagerSelect } from "@src/widgets/ProjectManagerSelect";
import { observer } from "mobx-react-lite";

export const UserStatus = observer(function UserStatus() {
  const { editUserModalStore } = useStore();
  const archiveUserDialog = useDisclosure();
  const activateUserDialog = useDisclosure();

  const toggleActive = () => {
    if (editUserModalStore.user?.archived_at) {
      activateUserDialog.onOpen();
    } else {
      archiveUserDialog.onOpen();
    }
  };

  return (
    <Stack spacing="10">
      <Stack>
        <HStack spacing="3">
          <FormLabel w="130px" mr="0">
            <Trans>From</Trans>
          </FormLabel>
          <FormLabel w="130px" mr="0">
            <Trans>To</Trans>
          </FormLabel>
          <FormLabel w="80px" mr="0">
            <Trans>User status</Trans>
          </FormLabel>
          {editUserModalStore.user?.permissions.user_haveProjectsAssigned && (
            <FormLabel w="150px" mr="0">
              <Trans>Project manager</Trans>
            </FormLabel>
          )}
        </HStack>
        <Stack spacing="4">
          {editUserModalStore.user?.statusRanges.map((range) => (
            <HStack key={range.id} pos="relative">
              <HStack alignItems="start" spacing="3">
                <InputDatePicker
                  usePortal
                  w="130px"
                  isDisabled
                  selected={new Date(range.valid_from)}
                  onChange={() => {}}
                  placeholder=""
                />
                <InputDatePicker
                  usePortal
                  w="130px"
                  isDisabled
                  selected={
                    range.valid_to ? new Date(range.valid_to) : undefined
                  }
                  onChange={() => {}}
                  placeholder={t`Not specified`}
                />
                <FormControl w="80px">
                  <Checkbox h="36px" isChecked={range.active} isDisabled>
                    <Trans>Active</Trans>
                  </Checkbox>
                </FormControl>
                <Box width="262px">
                  {!!range.projectManager?.id && (
                    <ProjectManagerSelect
                      asPortal
                      isDisabled
                      value={range.projectManager.id}
                    />
                  )}
                </Box>
              </HStack>
            </HStack>
          ))}
        </Stack>
      </Stack>

      <Button
        w={150}
        colorScheme="grey"
        onClick={toggleActive}
        variant="outline"
      >
        {editUserModalStore.user?.archived_at
          ? t`Activate user`
          : t`Deactivate user`}
      </Button>
      <DeactivateUserModal
        {...archiveUserDialog.getDisclosureProps()}
        {...archiveUserDialog}
        userId={editUserModalStore.user?.id}
        canHaveAssets={
          editUserModalStore.user?.permissions.user_haveProjectsAssigned
        }
        onConfirm={() => {
          editUserModalStore.fetchUser();
          appStore.UIStore.toast({
            title: t`User was deactivated`,
            status: "success",
          });
        }}
      />
      <ActivateUserModal
        {...activateUserDialog.getDisclosureProps()}
        {...activateUserDialog}
        userId={editUserModalStore.user?.id}
        onConfirm={() => {
          editUserModalStore.fetchUser();
          appStore.UIStore.toast({
            title: t`User was activated`,
            status: "success",
          });
        }}
      />
    </Stack>
  );
});
