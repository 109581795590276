export enum TaskTabIdEnum {
  Description = 0,
  Communication = 1,
  Attachments = 2,
  LinkedTasks = 3,
  ClientCommunication = 4,
  PartnerCommunication = 5,
}

export const COMMUNICATION_TABS = [
  TaskTabIdEnum.Communication,
  TaskTabIdEnum.ClientCommunication,
  TaskTabIdEnum.PartnerCommunication,
];