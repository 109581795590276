/* eslint-disable lingui/no-unlocalized-strings */
import { appStore } from "@src/stores/AppStore";
import { Me } from "@src/stores/models/Me";
import { getHighestRole } from "@src/utils/roles";
import Script from "next/script";
import { Fragment, useEffect, useMemo, useState } from "react";

function getContact(user: Me): string {
  return JSON.stringify({
    first_name: user?.first_name ?? "",
    last_name: user?.last_name ?? "",
    work_email: user?.email ?? "",
    role: getHighestRole(user?.roles ?? []),
    position: user?.profile?.defaultWorktype.title ?? "",
    workspace: appStore.workspaceStore.settings?.title,
  });
}

function getTeamLeaderSurvey(user: Me): string {
  return `try {
      SurveyWidget.init({
        type: "default",
        delay: 5,
        include_unsubscribe :true,
        survey_id: "84c60f7b-57de-4800-a1bb-8265527dd74d",
        survey_collector_id: "SC_-6",
        contact: ${getContact(user)},
      })
    } catch (e) {
      console.error(e)
    }`;
}

function getDirectorSurvey(user: Me): string {
  return `try {
      SurveyWidget.init({
        type: "default",
        delay: 5,
        include_unsubscribe :true,
        survey_id: "96bfdcb4-f0ce-4d5e-984a-a00b11d272cc",
        survey_collector_id: "SC_-5",
        contact: ${getContact(user)},
      })
    } catch (e) {
      console.error(e)
    }`;
}

function getCreativesSurvey(user: Me): string {
  return `try {
      SurveyWidget.init({
        type: "default",
        delay: 5,
        include_unsubscribe :true,
        survey_id: "b0572635-d153-41e0-8f3e-5cdca3cf1cf5",
        survey_collector_id: "SC_-3",
        contact: ${getContact(user)},
      })
    } catch (e) {
      console.error(e)
    }`;
}

// function getUserSatisfactionSurvey(user: Me): string {
//return `try {
//    SurveyWidget.init({
//      type: "cozy",
//      delay: 5,
//      include_unsubscribe :true,
//      survey_id: "7273d63d-b95c-4330-bff9-da392cc64338",
//      survey_collector_id: "SC_-1",
//      contact: ${getContact(user)},
//    })
//  } catch (e) {
//    console.error(e)
//  }`
// }

function userHasRoles(user: Me, roles: string[]): boolean {
  return roles.some((role) =>
    user?.roles?.map(({ name }) => name)?.includes(role),
  );
}

const disabledSites = ["dev", "demo", "planning", "staging", "localhost"];

function isSurveyAllowed(hostname: string): boolean {
  let allowed = true;
  const host = hostname.split(".")?.[0];
  if (!host) return allowed;

  for (const site of disabledSites) {
    if (host.includes(site)) {
      allowed = false;
      break;
    }
  }

  return allowed;
}

export const FreshsurveyWidget = () => {
  const [delayed, setDelayed] = useState(false);
  const { user } = appStore.authStore;

  const roleSurvey: string | undefined = useMemo(() => {
    if (!user) return undefined;

    if (
      userHasRoles(user, ["admin", "managing-director", "cfo", "financier"])
    ) {
      return getDirectorSurvey(user);
    } else if (userHasRoles(user, ["team-leader", "project-manager"])) {
      return getTeamLeaderSurvey(user);
    } else {
      return getCreativesSurvey(user);
    }
  }, [user]);

  const isAllowed = useMemo(() => {
    return isSurveyAllowed(window.location.hostname);
  }, [window.location.hostname]);

  useEffect(() => {
    setTimeout(() => {
      setDelayed(true);
    }, 10000);
  }, []);

  if (!isAllowed) return null;

  return (
    <Fragment>
      {delayed && roleSurvey && (
        <Script id="freshsurvey-role">{roleSurvey}</Script>
      )}
      <Script src="https://cdn.surveyserv.com/widget.min.js" />
    </Fragment>
  );
};
