import { isAfterOrSame, isBeforeOrSame, now } from "../dates";

const TODAY = now();

/**
 * @param forDate - Uses today's date by default
 */
export function isTrackingEnabled(
  settings: {
    tracking_enabled: boolean;
    tracking_enabled_from?: string | null | Date;
    tracking_enabled_to?: string | null | Date;
  },
  forDate: Date = TODAY,
): boolean {
  const {
    tracking_enabled: enabled,
    tracking_enabled_from: from,
    tracking_enabled_to: to,
  } = settings;

  const afterOrSame = from && isAfterOrSame(forDate, new Date(from));
  const beforeOrSame = to && isBeforeOrSame(forDate, new Date(to));

  // Enabled flag (if enabled but no dates specified)
  if (enabled && !to && !from) return true;

  // Date Range (if in date range)
  if (from && to && afterOrSame && beforeOrSame) return true;

  // Date From (if is after from date)
  if (from && !to && afterOrSame) return true;

  // Date To (if is before to date)
  if (to && !from && beforeOrSame) return true;

  return false;
}
