import { Box, Select, Switch, Tooltip } from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { ProjectCodeGeneratorEnum } from "@src/__generated__/urql-graphql";
import { FormRow } from "@src/components/ui-kit";
import { useStore } from "@src/utils/hooks";
import { range } from "lodash";
import { observer } from "mobx-react-lite";
import { FunctionComponent, PropsWithChildren } from "react";

type NoEditTooltipProps = {
  canEditProjectCode: boolean;
};
const NoEditTooltip: FunctionComponent<
  PropsWithChildren<NoEditTooltipProps>
> = ({ canEditProjectCode, children }) => (
  <Tooltip
    hasArrow
    label={
      !canEditProjectCode
        ? t`This setting can be edited only before you create your first project`
        : undefined
    }
  >
    {children}
  </Tooltip>
);

export const Projects: FunctionComponent = observer(function Projects() {
  const {
    settingsModalStore: { projectsStore },
  } = useStore();

  return (
    <Box marginBlock="6">
      <FormRow
        title={<Trans>Project code generator</Trans>}
        description={
          <Trans>
            The project code consists of Brand code-YYYY(optional)-Project
            serial number. Choose whether project codes should generate serial
            numbers separately for each brand or sequentially for all projects
            regardless of brand.
          </Trans>
        }
      >
        <NoEditTooltip canEditProjectCode={projectsStore.canEditProjectCode}>
          <Select
            isDisabled={!projectsStore.canEditProjectCode}
            name="project_code_generator"
            onChange={(event) =>
              projectsStore.form.project_code_generator.onChange(
                event.target.value as ProjectCodeGeneratorEnum,
              )
            }
            value={projectsStore.form.project_code_generator.value}
          >
            {Object.values(ProjectCodeGeneratorEnum).map((value) => (
              <option key={value} value={value}>
                {
                  {
                    [ProjectCodeGeneratorEnum.NumberPerBrand]: (
                      <Trans>Number pre brand</Trans>
                    ),
                    [ProjectCodeGeneratorEnum.NumberPerWorkspace]: (
                      <Trans>Number pre workspace</Trans>
                    ),
                  }[value]
                }
              </option>
            ))}
          </Select>
        </NoEditTooltip>
      </FormRow>
      <FormRow
        title={<Trans>Project code length</Trans>}
        description={
          <Trans>
            Specifies the desired number of digits for the project serial number
            within the project code.
          </Trans>
        }
      >
        <NoEditTooltip canEditProjectCode={projectsStore.canEditProjectCode}>
          <Select
            isDisabled={!projectsStore.canEditProjectCode}
            name="project_code_length"
            onChange={(event) =>
              projectsStore.form.project_code_length.onChange(
                Number(event.target.value),
              )
            }
            value={projectsStore.form.project_code_length.value}
          >
            {range(1, 7).map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </Select>
        </NoEditTooltip>
      </FormRow>
      <FormRow
        title={<Trans>Project codes with year</Trans>}
        description={
          <Trans>
            Decide whether project codes should include the current year
            (example: XYZ-2024001)
          </Trans>
        }
      >
        <NoEditTooltip canEditProjectCode={projectsStore.canEditProjectCode}>
          <Box as="span">
            <Switch
              isChecked={projectsStore.form.project_code_with_year.value}
              isDisabled={!projectsStore.canEditProjectCode}
              name="project_code_with_year"
              onChange={(event) =>
                projectsStore.form.project_code_with_year.onChange(
                  event.target.checked,
                )
              }
            />
          </Box>
        </NoEditTooltip>
      </FormRow>
      <FormRow
        title={<Trans>Close budget items with unapproved expenses</Trans>}
        description={
          <Trans>
            Enables the closure of production or media budget items even if not
            all assigned expenses have been approved by the CFO. Closing a
            budget item blocks additional expense assignments until it is
            reopened.
          </Trans>
        }
      >
        <Switch
          isChecked={
            projectsStore.form
              .can_close_expense_budget_item_with_unapproved_expenses.value
          }
          name="can_close_expense_budget_item_with_unapproved_expenses"
          onChange={(event) =>
            projectsStore.form.can_close_expense_budget_item_with_unapproved_expenses.onChange(
              event.target.checked,
            )
          }
        />
      </FormRow>
      <FormRow
        title={<Trans>Automatic tasks creation</Trans>}
        description={
          <Trans>
            Automatically generate one task for each budget item within Allfred.
            Tasks will be named after their corresponding budget items, with
            pre-filled hours and positions if specified in the budget, but
            without assignees, descriptions, or durations.
          </Trans>
        }
      >
        <Switch
          isChecked={projectsStore.form.create_default_task_enabled.value}
          name="create_default_task_enabled"
          onChange={(event) =>
            projectsStore.form.create_default_task_enabled.onChange(
              event.target.checked,
            )
          }
        />
      </FormRow>
      {projectsStore.hasMoreOurWorkBudgetCategories && (
        <FormRow
          title={
            <Trans>Automatic tasks creation only for Our work department</Trans>
          }
          description={
            <Trans>
              Activate automatic task creation exclusively for the “Our Work”
              department if multiple departments are being utilized within the
              workspace.
            </Trans>
          }
        >
          <Tooltip
            hasArrow
            label={
              !projectsStore.form.create_default_task_enabled.value
                ? t`Turn on Automatic tasks creation first`
                : undefined
            }
          >
            <Box as="span">
              <Switch
                isChecked={
                  projectsStore.form
                    .create_default_task_only_to_our_work_category.value
                }
                isDisabled={
                  !projectsStore.form.create_default_task_enabled.value
                }
                name="create_default_task_only_to_our_work_category"
                onChange={(event) =>
                  projectsStore.form.create_default_task_only_to_our_work_category.onChange(
                    event.target.checked,
                  )
                }
              />
            </Box>
          </Tooltip>
        </FormRow>
      )}
    </Box>
  );
});
