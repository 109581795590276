import {
  TaskCommentsQueryVariables,
  useTaskCommentsLazyQuery,
} from "@src/__generated__/graphql";
import { ModalCommunicationStore } from "@src/components/widgets/Modals/ModalCommunication/ModalCommunicationStore";
import { CommentModel } from "@src/components/widgets/Modals/ModalCommunication/models/CommentModel";
import { UserType } from "@src/stores/models/Me";
import { onError } from "@src/utils/apollo";
import { userTypeToScope } from "@src/utils/userTypeToScope";
import { useEffect } from "react";

const useComments = (
  args: { taskId?: TaskCommentsQueryVariables["id"]; userType: UserType },
  isModalOpen: boolean,
  store: ModalCommunicationStore,
): [
  CommentModel[],
  {
    loadingComments: boolean;
    hasMoreComments: boolean;
    fetchMoreComments: () => void;
    addComment: (comment: CommentModel) => void;
    replaceComment: (toReplaceId: string, comment: CommentModel) => void;
    deleteComment: (id: string) => void;
  },
] => {
  const { comments, pagination } = store.COMMUNICATION_MAP[args.userType];

  const [fetchComments, { loading }] = useTaskCommentsLazyQuery({
    onCompleted(result) {
      if (result.taskComments) {
        comments.set([
          ...comments.value,
          ...result.taskComments.data.map(
            (comment) => new CommentModel(comment),
          ),
        ]);
        pagination.setFromPaginatorInfo(result.taskComments.paginatorInfo);
      }
    },
    context: {
      fetchOptions: {
        signal: null,
      },
    },
    ...onError(),
  });

  const getLastCommentID = (): string | undefined => {
    if (comments.value.length === 0) return undefined;
    return comments.value[comments.value.length - 1].id;
  };

  const fetchMore = () => {
    if (comments.value && args.taskId) {
      fetchComments({
        variables: {
          id: args.taskId,
          scope: userTypeToScope(args.userType),
          lastCommentId: getLastCommentID(),
        },
      });
    }
  };

  const addComment = (comment: CommentModel) => {
    comments.set(
      comments.value ? [comment, ...comments.value] : comments.value,
    );
  };

  const replaceComment = (toReplaceId: string, comment: CommentModel) => {
    comments.set(
      comments.value
        ? comments.value.map((i) => (i.id === toReplaceId ? comment : i))
        : comments.value,
    );
  };

  const deleteComment = (id: string) => {
    comments.set(
      comments.value
        ? comments.value.filter((i) => i.id !== id)
        : comments.value,
    );
  };

  useEffect(() => {
    if (isModalOpen && args.taskId) {
      comments.set([]);
      fetchComments({
        variables: { id: args.taskId, scope: userTypeToScope(args.userType) },
      });
    } else {
      comments.set([]);
    }
  }, [isModalOpen, args.taskId, args.userType]);

  const hasMore = !!pagination.hasNextPage;

  return [
    comments.value,
    {
      loadingComments: loading,
      hasMoreComments: hasMore,
      fetchMoreComments: fetchMore,
      addComment,
      replaceComment,
      deleteComment,
    },
  ];
};

export default useComments;
