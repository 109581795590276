import { Box, Flex, HStack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { FC, ReactNode } from "react";

interface SummaryBoxProps {
  label?: ReactNode;
  justify?: "space-around" | "space-between" | "space-evenly" | "stretch";
}

export const SUMMARY_BOX_BG = "#f1f6f8";

const SummaryBox: FC<React.PropsWithChildren<SummaryBoxProps>> = ({
  label,
  justify,
  children,
}) => (
  <Box mb="4" px="4">
    <Flex align="center" flex="1" bg={SUMMARY_BOX_BG} dir="column" rounded="lg">
      <Box h="full">
        {!!label && (
          <Flex px="4">
            <label className="font-semibold text-inkLighter">{label}</label>
          </Flex>
        )}
      </Box>
      <HStack align="center" justify={justify} flex="1" spacing="2">
        {children}
      </HStack>
    </Flex>
  </Box>
);

export default observer(SummaryBox);
