import { Box } from "@chakra-ui/layout";
import {
  Icon as ChakraIcon,
  IconProps as ChakraIconProps,
} from "@chakra-ui/react";
import { IconNames, IconVariants } from "@src/utils/types/iconNames";
import { FunctionComponent, memo } from "react";

export interface IconProps extends ChakraIconProps {
  name: IconNames;
  variant?: IconVariants;
}
const IconComponent: FunctionComponent<IconProps> = ({
  name,
  variant = "line",
  ...props
}) => {
  const Svg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg {...props}>
      <use href={`/sprite.svg#${variant}-${name}`} />
    </svg>
  );

  return <ChakraIcon w="4" h="4" {...props} as={Svg} />;
};
export const Icon = memo(IconComponent);

export const CircleTickIcon: FunctionComponent = () => (
  <Box as="span" w="2rem" h="2rem">
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z"
        fill="#BBE5B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 15.718a5.714 5.714 0 100-11.429 5.714 5.714 0 000 11.429z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.286 10A5.72 5.72 0 0110 4.284 5.72 5.72 0 0115.714 10 5.72 5.72 0 0110 15.714a5.72 5.72 0 01-5.714-5.715zm1.142 0c0 2.52 2.051 4.57 4.572 4.57 2.52 0 4.571-2.05 4.571-4.57A4.576 4.576 0 0010 5.428a4.577 4.577 0 00-4.572 4.571z"
        fill="#108043"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.31 8.453l-1.88 1.881-.74-.738a.57.57 0 10-.807.808l1.143 1.142a.57.57 0 00.808 0l2.285-2.285a.57.57 0 10-.808-.808"
        fill="#108043"
      />
    </svg>
  </Box>
);

/** @deprecated */
export const CollapseIcon: FunctionComponent = (props: ChakraIconProps) => (
  <ChakraIcon viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      d="M19 0a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h18zM2 7h16V2H2v5zm6 11a1 1 0 0 1 0 2H6a1 1 0 0 1 0-2h2zm6 0h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2zm5-1a1 1 0 0 0-1 1 1 1 0 0 0 0 2h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1zM2 18a1 1 0 0 0-2 0v1a1 1 0 0 0 1 1h1a1 1 0 0 0 0-2zm-1-3a1 1 0 0 0 1-1v-2a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1zm18-4a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1z"
    />
  </ChakraIcon>
);

export const SharepointIcon: FunctionComponent<ChakraIconProps> = (props) => (
  <ChakraIcon viewBox="0 0 48 48" {...props}>
    <linearGradient
      id="a"
      x1="16.145"
      x2="26.377"
      y1="6.428"
      y2="20.715"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#048489" />
      <stop offset="1" stopColor="#03676a" />
    </linearGradient>
    <path
      fill="url(#a)"
      d="M23 4c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12S29.627 4 23 4z"
    />
    <linearGradient
      id="b"
      x1="27.122"
      x2="41.023"
      y1="18.616"
      y2="35.799"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#18939a" />
      <stop offset=".41" stopColor="#168c93" />
      <stop offset="1" stopColor="#117981" />
    </linearGradient>
    <path
      fill="url(#b)"
      d="M33.5 16C27.701 16 23 20.701 23 26.5S27.701 37 33.5 37 44 32.299 44 26.5 39.299 16 33.5 16z"
    />
    <linearGradient
      id="c"
      x1="16"
      x2="32"
      y1="36"
      y2="36"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#37c6d0" />
      <stop offset="1" stopColor="#37c6d0" />
    </linearGradient>
    <path fill="url(#c)" d="M24 28a8 8 0 1 0 0 16 8 8 0 0 0 0-16z" />
    <path
      d="M22.319 13H11.393A12.017 12.017 0 0 0 11 16c0 6.627 5.373 12 12 12 .04 0 .078-.006.118-.006l.009.056A8 8 0 0 0 16 36c0 .339.028.672.069 1h6.25A3.68 3.68 0 0 0 26 33.319V16.681A3.68 3.68 0 0 0 22.319 13z"
      opacity=".05"
    />
    <path
      d="M16 36h6.213a3.12 3.12 0 0 0 3.121-3.121V16.454a3.12 3.12 0 0 0-3.121-3.121H11.308A11.962 11.962 0 0 0 11 16c0 6.627 5.373 12 12 12 .04 0 .078-.006.118-.006l.009.056A8 8 0 0 0 16 36z"
      opacity=".07"
    />
    <path
      d="M22.106 13.667H11.231A12.033 12.033 0 0 0 11 16c0 6.627 5.373 12 12 12 .04 0 .078-.006.118-.006l.009.056A7.997 7.997 0 0 0 16.07 35h6.037a2.56 2.56 0 0 0 2.56-2.56V16.227a2.56 2.56 0 0 0-2.561-2.56z"
      opacity=".09"
    />
    <linearGradient
      id="d"
      x1="4.586"
      x2="23.414"
      y1="14.586"
      y2="33.414"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#058f92" />
      <stop offset="1" stopColor="#027074" />
    </linearGradient>
    <path
      fill="url(#d)"
      d="M22 34H6a2 2 0 0 1-2-2V16a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2z"
    />
    <path
      fill="#fff"
      d="M10.432 28.75v-2.298c.426.349 2.391 1.048 2.9 1.048.299 0 2.138.088 2.138-1.041 0-1.633-5.082-1.494-5.082-4.725 0-.536.066-3.059 4.133-3.059 1.041 0 2.271.261 2.628.395v2.147c-.176-.12-.89-.718-2.496-.718-1.877 0-2.04.883-2.04 1.041 0 1.359 4.998 1.544 4.998 4.711 0 3.172-3.614 3.074-4.177 3.074-.577 0-2.62-.383-3.002-.575z"
    />
  </ChakraIcon>
);
