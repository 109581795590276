import { Button, Stack, Text, useToast } from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { useEffect, useState } from "react";

import { captureEvent } from "@sentry/nextjs";
import pkg from "../../../package.json";

export function useDeployAvailableNotification() {
  const toast = useToast();
  const [hasNewDeploy, setHasNewDeploy] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      return;
    }

    const checkNewDeploy = async () => {
      try {
        const newestVersionResponse = await fetch("/version.txt");
        if (!newestVersionResponse.ok) {
          throw new Error("Newest version response not ok");
        }

        const newestVersion = await newestVersionResponse.text();
        if (newestVersion.trim() !== pkg.version) {
          setHasNewDeploy(true);
        }
      } catch (error) {
        captureEvent({
          message: "Error checking for new deployment",
          extra: {
            error,
          },
        });
      }
    };

    const interval = setInterval(checkNewDeploy, 1000 * 30);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (hasNewDeploy) {
      toast({
        position: "bottom-right",
        title: t`New version available`,
        description: (
          <Stack>
            <Text>
              <Trans>
                An improved version of Allfred is available, Please reload this
                window now to update.
              </Trans>
            </Text>
            <Button
              mt="1"
              colorScheme="white"
              onClick={() => window.location.reload()}
              size="sm"
              variant="link"
            >
              <Trans>OK - reload</Trans>
            </Button>
          </Stack>
        ),
        status: "info",
        isClosable: false,
        duration: null,
      });
    }
  }, [hasNewDeploy]);
}
