import { Maybe, ProjectListTableDataQuery } from "@src/__generated__/graphql";
import { action, makeObservable, observable } from "mobx";
import { MarkRequired } from "ts-essentials";

type TProject = NonNullable<ProjectListTableDataQuery["projects"]>["data"][0];

export class Project {
  id: TProject["id"];
  title: TProject["title"];
  code: string;
  brand: TProject["brand"];
  note?: TProject["note"];
  projectManager: TProject["projectManager"];
  otherProjectManagers?: TProject["otherProjectManagers"];
  budgetovacBudget?: TProject["budgetovacBudget"];
  ourWorkStructured?: TProject["ourWorkStructured"];
  expensesStructured?: TProject["expensesStructured"];
  stats?: TProject["statsCached"];
  @observable.ref
  lastWorkInProgressProjectStat?: TProject["lastWorkInProgressProjectStat"];
  billingPlanIsMissing?: TProject["billingPlanIsMissing"];
  @observable.ref billable?: TProject["billable"];
  type?: TProject["type"];
  is_pinned: TProject["is_pinned"];
  finished_at?: Maybe<string>;
  archived_at?: Maybe<string>;
  cloudUrl?: TProject["cloudUrl"];
  categories?: TProject["categories"];
  scopes?: TProject["scopes"];

  __subRows: SubRow[];

  constructor(
    src: MarkRequired<Partial<TProject>, "is_pinned" | "projectManager">,
  ) {
    makeObservable(this);
    this.id = src.id!;
    this.title = src.title!;
    this.code = src.code!;
    this.note = src.note;
    this.brand = src.brand!;
    this.projectManager = src.projectManager;
    this.otherProjectManagers = src.otherProjectManagers;
    this.budgetovacBudget = src.budgetovacBudget;
    this.ourWorkStructured = src.ourWorkStructured;
    this.expensesStructured = src.expensesStructured;
    this.stats = src.statsCached;
    this.lastWorkInProgressProjectStat = src.lastWorkInProgressProjectStat;
    this.billingPlanIsMissing = src.billingPlanIsMissing;
    this.billable = src.billable;
    this.is_pinned = src.is_pinned;
    this.finished_at = src.finished_at;
    this.archived_at = src.archived_at;
    this.cloudUrl = src.cloudUrl;
    this.type = src.type;
    this.scopes = src.scopes;
    this.categories = src.categories ?? [];
    this.__subRows = src.ourWork?.map((i) => new SubRow(i)) ?? [];
  }

  @action.bound
  updateCurrentWorkInProgressItemStat(
    src: NonNullable<TProject["lastWorkInProgressProjectStat"]>,
  ) {
    this.lastWorkInProgressProjectStat = src;
  }
}

type TOurWork = TProject["ourWork"][0];

export class SubRow {
  id: TOurWork["id"];
  title: TOurWork["title"];
  stats: TOurWork["stats"];
  categoryName: TOurWork["category"]["name"];
  @observable
  lastWorkInProgressItemStat: TOurWork["lastWorkInProgressItemStat"];

  constructor(src: TOurWork) {
    makeObservable(this);
    this.id = src.id;
    this.title = src.title;
    this.stats = src.stats;
    this.lastWorkInProgressItemStat = src.lastWorkInProgressItemStat;
    this.categoryName = src.category.name;
  }

  @action.bound
  updateCurrentWorkInProgressItemStat(
    src: Omit<
      NonNullable<SubRow["lastWorkInProgressItemStat"]>,
      "work_in_progress_id"
    >,
  ) {
    if (this.lastWorkInProgressItemStat) {
      this.lastWorkInProgressItemStat = {
        ...this.lastWorkInProgressItemStat,
        ...src,
      };
    }
  }
}
