import { t } from "@lingui/macro";
import {
  ClientsProjectListTableDataDocument,
  ClientsProjectListTableDataQuery,
  ClientsProjectListTableDataQueryVariables,
  ProjectInvoicedStatusEnum,
  ProjectListFilterOptionsDocument,
  ProjectListFilterOptionsQuery,
  ProjectListFilterOptionsQueryVariables,
  ProjectOrderByColumnEnum,
  ProjectsWhereColumn,
  ProjectTypeEnum,
  SortOrder,
  SqlOperator,
} from "@src/__generated__/graphql";
import { getBillingCategoryOptions } from "@src/components/modules/projects/list/Store";
import { ProjectCategorySelectBottomExtraContent } from "@src/components/ui-kit/ProjectCategorySelect";
import { client } from "@src/services/apollo-client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { commonSearchParams } from "@src/utils/apolloHelpers";
import { Filter, Filters } from "@src/utils/components/filters/models";
import { OrderBy } from "@src/utils/components/sorting/OrderBy";
import mapToOptions from "@src/utils/map-to-options";
import { PaginationState } from "@src/utils/mobx/states/PaginationState";
import { action, computed, makeObservable, observable } from "mobx";

type TProject = NonNullable<
  ClientsProjectListTableDataQuery["projects"]
>["data"][0];

export class ProjectListingForClientsStore implements BaseStore {
  appStore: AppStore;
  @observable.ref projects: TProject[] = [];
  refetch?: () => void;

  @observable searchTerm = "";
  @observable isLoading = false;

  pagination = new PaginationState("clients-projects", {
    onChangePagination: () => {
      this.projects = [];
      this.fetchData();
    },
  });

  orderBy = new OrderBy<ProjectOrderByColumnEnum>([
    { column: ProjectOrderByColumnEnum.ProjectCode, order: SortOrder.Asc },
  ]);

  where = new Filters<ProjectsWhereColumn>(
    [
      new Filter({
        column: ProjectsWhereColumn.BrandId,
        operator: SqlOperator.In,
        title: t`Client/Brand`,
        tagTitle: t`Brand`,
        options: [],
        sectioned: true,
        hasSelectAllOption: true,
      }),
      new Filter({
        column: ProjectsWhereColumn.ActiveInTimeframe,
        operator: SqlOperator.Between,
        title: t`Active in time frame`,
        options: [],
        dateRange: true,
      }),
      new Filter({
        column: ProjectsWhereColumn.StartDate,
        operator: SqlOperator.Between,
        title: t`Project start date`,
        options: [],
        dateRange: true,
      }),
      new Filter({
        column: ProjectsWhereColumn.ExpectedEndDate,
        operator: SqlOperator.Between,
        title: t`Project end date`,
        options: [],
        dateRange: true,
      }),
      new Filter({
        column: ProjectsWhereColumn.ProjectManagerId,
        operator: SqlOperator.In,
        title: t`Project Manager`,
        options: [],
        hasSelectAllOption: true,
      }),
      new Filter({
        column: ProjectsWhereColumn.OtherProjectManagerId,
        operator: SqlOperator.In,
        title: t`Other Project Manager`,
        options: [],
        hasSelectAllOption: true,
      }),
      new Filter({
        column: ProjectsWhereColumn.TeamId,
        operator: SqlOperator.In,
        title: t`Team`,
        options: [],
      }),
      new Filter({
        column: ProjectsWhereColumn.InvoicedStatus,
        operator: SqlOperator.In,
        title: t`Status`,
        options: [
          {
            label: t`uninvoiced`,
            value: ProjectInvoicedStatusEnum.Uninvoiced,
          },
          {
            label: t`partially invoiced`,
            value: ProjectInvoicedStatusEnum.PartiallyInvoiced,
          },
          {
            label: t`invoiced`,
            value: ProjectInvoicedStatusEnum.Invoiced,
          },
        ],
      }),
      new Filter({
        column: ProjectsWhereColumn.Billable,
        operator: SqlOperator.Eq,
        title: t`Billing category`,
        options: getBillingCategoryOptions(),
      }),
      new Filter({
        column: ProjectsWhereColumn.Type,
        operator: SqlOperator.Eq,
        title: t`Type`,
        options: [
          {
            value: ProjectTypeEnum.FixedPrice,
            label: t`Fixed-price`,
          },
          {
            value: ProjectTypeEnum.TimeMaterials,
            label: t`Time & Materials`,
          },
        ],
      }),
      new Filter({
        column: ProjectsWhereColumn.QuickFilter,
        operator: SqlOperator.Eq,
        title: t`Projects`,
        hidden: true,
        options: [],
      }),
      new Filter({
        column: ProjectsWhereColumn.CategoryId,
        operator: SqlOperator.In,
        title: t`Project category`,
        options: [],
        bottomExtraContent: <ProjectCategorySelectBottomExtraContent />,
      }),
    ],
    {
      onChange: () => {
        this.projects = [];
        this.fetchData();
        this.pagination.resetPage();
      },
    },
  );

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  get quickFilter() {
    return this.where.filtersByColumn.get(ProjectsWhereColumn.QuickFilter)!;
  }

  @computed get searchParams() {
    return commonSearchParams(this);
  }

  @action async fetchData() {
    this.isLoading = true;
    const { data } = await client.query<
      ClientsProjectListTableDataQuery,
      ClientsProjectListTableDataQueryVariables
    >({
      query: ClientsProjectListTableDataDocument,
      variables: {
        ...this.pagination.asParams,
        filters: {
          orderBy: this.orderBy.asWhereParam,
          where: this.where.asGraphQueryParam,
          search: this.searchTerm || undefined,
        },
      },
    });

    if (data.projects?.data) {
      this.projects = data.projects.data;
      this.pagination.setFromPaginatorInfo(data.projects.paginatorInfo!);
    }

    this.isLoading = false;
  }

  @action async fetchFilterOptions() {
    const { data } = await client.query<
      ProjectListFilterOptionsQuery,
      ProjectListFilterOptionsQueryVariables
    >({
      query: ProjectListFilterOptionsDocument,
    });
    const pmOptions = mapToOptions.projectManagers.toOptions(
      data.projectManagerSimpleMap,
    );

    this.where.filtersByColumn
      .get(ProjectsWhereColumn.ProjectManagerId)
      ?.setOptions(pmOptions);
    this.where.filtersByColumn
      .get(ProjectsWhereColumn.OtherProjectManagerId)
      ?.setOptions(pmOptions);

    this.where.filtersByColumn.get(ProjectsWhereColumn.BrandId)?.setOptions(
      data.clientsSimpleMap.map((i) => ({
        value: i?.id,
        label: i?.name,
        options: i?.brands.map((brand) => {
          return {
            label: brand.name,
            value: brand.id,
          };
        })!,
      })),
    );

    this.where.filtersByColumn.get(ProjectsWhereColumn.TeamId)?.setOptions(
      data.teamSimpleMap.map((i) => ({
        value: i?.id,
        label: i?.name,
      })),
    );
  }
}
