import { FetchResult } from "@apollo/client";
import { Button, Flex, SimpleGrid, TabPanel } from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import {
  CreateAssignableFileMutation,
  useCreateAssignableFileMutation,
  useUpdateTaskFilesMutation,
} from "@src/__generated__/urql-graphql";
import { DropZone } from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import { AttachmentView } from "@src/components/widgets/Modals/ModalCommunication/components/Attachments/TaskAttachment";
import { trackEvent } from "@src/services/amplitude";
import { toApiDate } from "@src/utils/dates";
import { useStore as useAppStore, useStore } from "@src/utils/hooks";
import { orderBy, uniqueId } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useUnmount } from "react-use";
import Uploading from "../Attachments/Uploading";

interface UploadingFile {
  filename: string;
  uploading: boolean;
  upload: () => Promise<FetchResult<CreateAssignableFileMutation>>;
  abort: () => void;
}

export const TaskAttachmentsTab = observer(function TaskAttachmentsTab() {
  const appStore = useAppStore();
  const { taskDetailModalStore: store } = useStore();
  const [uploadingFilesMap, setUploadingFilesMap] = useState(
    new Map<string, UploadingFile>(),
  );

  const _add = (data: UploadingFile) => {
    setUploadingFilesMap((state) => {
      const newState = new Map([...state]);
      newState.set(uniqueId(), data);
      return newState;
    });
  };
  const _update = (key: string, data: { uploading?: boolean }) => {
    setUploadingFilesMap((state) => {
      if (!state.has(key)) return state;
      const obj = state.get(key)!;
      return state.set(key, { ...obj, ...data });
    });
  };
  const _remove = (key: string) => {
    setUploadingFilesMap((state) => {
      if (!state.has(key)) return state;
      const newMap = new Map([...state]);
      newMap.delete(key);
      return newMap;
    });
  };

  const [{}, updateTaskFiles] = useUpdateTaskFilesMutation();
  const [{}, upload] = useCreateAssignableFileMutation();

  const onDropFiles = (newFiles: File[]) => {
    newFiles.forEach((newFile) => {
      const controller = new AbortController();
      const _upload = () =>
        upload(
          { file: newFile },
          {
            fetchOptions: { signal: controller.signal, credentials: "include" },
          },
        );
      _add({
        filename: newFile.name,
        uploading: false,
        upload: _upload,
        abort: () => controller.abort(),
      });
      trackEvent("task", "Uploaded attachment");
    });
  };

  const abortAllUploads = () => {
    uploadingFilesMap.forEach((value) => value.abort());
  };
  useUnmount(abortAllUploads);

  useEffect(() => {
    uploadingFilesMap.forEach((uploadingFile, key) => {
      if (uploadingFile.uploading === true) return;

      _update(key, { uploading: true });

      uploadingFile
        .upload()
        .then((result) => {
          const publicId = result.data?.createAssignableFile.public_id;
          publicId &&
            updateTaskFiles({
              task_id: store.taskId.value!,
              files: [publicId],
            }).then((updateResult) => {
              if (!updateResult.data) return;
              store.task.value?.setFiles(
                updateResult.data.addTaskFiles.files.map((f) => ({
                  ...f,
                  created_at: toApiDate(f.created_at),
                })),
              );
            });
        })
        .catch(() => {
          appStore.UIStore.toast({
            status: "error",
            title: `${t`Uploading failed`}: ${uploadingFile.filename}`,
          });
        })
        .finally(() => {
          _remove(key);
        });
    });
  }, [uploadingFilesMap]);

  const allFiles = orderBy(
    store.task.value?.files || [],
    (file) => new Date(file.created_at),
  );
  const uploadingFiles = Array.from(uploadingFilesMap.entries()).filter(
    ([_, file]) => file.uploading,
  );

  const downloadAllAttachments = () => {
    if (!store.task.value) return;
    window.open(store.task.value.attachments_download_url, "_blank");
  };

  return (
    <TabPanel px="8" pt="6">
      <SimpleGrid w="full" columns={6} marginBlockEnd="8" spacing="3">
        <Flex flex="1" h="120">
          <DropZone simple allowMultiple onDrop={onDropFiles} />
        </Flex>
        {allFiles.map((file) => (
          <AttachmentView
            mimeType={file.mime_type}
            w="auto"
            minW="auto"
            flex="1"
            height="120"
            key={file.public_id}
            id={file.public_id}
            name={file.filename}
            urls={file.urls}
            onRemoved={store.task.value?.removeFile}
          />
        ))}
        {uploadingFiles.map(([key, uploadingFile]) => (
          <Uploading key={key} name={uploadingFile.filename} />
        ))}
      </SimpleGrid>
      {allFiles.length > 0 && (
        <Button
          leftIcon={<Icon name="download-02" />}
          onClick={downloadAllAttachments}
          variant="subtle"
        >
          <Trans>Download all attachments</Trans>
        </Button>
      )}
    </TabPanel>
  );
});
