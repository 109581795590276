import { t } from "@lingui/macro";

export const AppName = "Allfred";

export const ApiDateFormat = "yyyy-MM-dd";
export const ApiDateTimeFormat = "yyyy-MM-dd HH:mm:ss";

export const getMonths = () => [
  t`January`,
  t`February`,
  t`March`,
  t`April`,
  t`May`,
  t`June`,
  t`July`,
  t`August`,
  t`September`,
  t`October`,
  t`November`,
  t`December`,
];

const [subdomain, domainName, tld] =
  typeof location !== "undefined"
    ? location.hostname.replace("-local", "").split(".")
    : [];

export const API_BASE_URL = `https://${subdomain}-api.${domainName}.${tld}`;
export const BUDGETOVAC_BASE_URL = `https://${subdomain}-budgetovac.${domainName}.${tld}/`;
export const BUDGETOVAC_API_URL = `https://${subdomain}-budgetovac-api.${domainName}.${tld}/api`;
const PLANOVAC_BASE_URL = `https://${subdomain}-planovac.${domainName}.${tld}/`;

/* eslint-disable @cspell/spellchecker, lingui/no-unlocalized-strings */
const VAPID_MAP = (hostname: string) => {
  const map: Record<string, string> = {
    // Production
    "brackets.allfred.io":
      "BELSpNZ12F-mfYzM_ssn_V5fnzfDU-qurNatR-AgsDDyFcMb_ajDiUnbBcYUXGJVKrBYVfn5xuPwUUx3ZEr4sFs",
    "kontentino.allfred.io":
      "BN65-iS5IF_pC4Aqa4Af9Gj8ghFmo4F14d-UfsVaA7qOyuomvyb-PolNk-zQnwQMtyvUpsVao9PEwTcyYoyMAyQ",
    "triadsk.allfred.io":
      "BAQYuPt4LhWyww1SAGYk3hCNMHRBA1niDNUVYpZ0EmMpAYzIJt7CVgWpveMUOx3Tuq7hywbrVwUHEKRylBMwK5U",
    "triadcz.allfred.io":
      "BJXqyMO8Z7IqX4THAU5CTofkNwMrSeXseh9wkxgfD2fHvs22N4kXFtPdHyUZywu0Evumus2EwpMClgm-Zdch6lU",
    "psdigital.allfred.io":
      "BLjtcAJmx1pWpEo4-BWvp4QyRRJr36Vy9rvcYjiqoEkKMZ1YwScOq0ebFvgaaH6DwzeOrclFw_ygsRXGKMmR-cw",
    "effeadsk.allfred.io":
      "BE2b8_aD6vw8PkuOngJb_AWV36eCzMeVCpcDQpFjhrtIYvCQblSmUpPX9cLe9vw4atkAD_ZayoJTaADr1bXza8I",
    "effeadcz.allfred.io":
      "BA5IJiKHWcQj4CxcDQwx47tlmirPaOTTSsvPNgjcu1u6MjUTV9cuNUHEmzVJhZ8K6hROHTlT1bu-UbgdnjKmHlU",
    "mlggk.allfred.io":
      "BHvJc08eLUzYCW-uEbA_nnDThKW7IdVXd5UXi66sAKrqBkirfKXKFMypAe_dLCMz0mbjs9a0C1KJp0BsFkXB7No",
    "muw.allfred.io":
      "BHcTMd9EEsAbY5YVY6gNLP5Ii94i_CaTfMxi3aZr528IiXJ4DCss3GuB8pj39Pem023cUnxAf9zOfbmbeO1-noQ",
    "madebyvaculik.allfred.io":
      "BPYqcKhZcs4JmSPO1ffj97lENzFmz1UFUD-TAsXfGjSiu3K0oZwg9mFjUR1vtKKxhKOK2BlgWwwtaA5Cojuz0u4",
    "ambi.allfred.io":
      "BHdcovdnr4bRjMA-a90t6vcQG3yt_FSh_zs9fbPSN9Ic38CwAkqPp5tXpsqH7woU0lHLjEUPDbC1zmaX0ae8SBo",
    "mannschaft.allfred.io":
      "BMyFI8Y4DpNIsmSwVZyxl2w0luThKmr8vRu8VB-joBKfiZ3AbpKZs_AgxlGuLLWaNflrrCWoyx626iRo1M73BXU",
    "rightstuff.allfred.io":
      "BIbZrz55_rxCio_J_HOqBPDat9r7qLwFxKh-b7IQwITaWlFVZ8nzwO_Wt54OT_7l78yHc6PQVHH4Bv2jnwhbjcA",
    "alien.allfred.io":
      "BEY5m9NdSuQSZ4UcBw_c1fDN2OdWa3aSV4wO-DjJt7oulhE7AtggezuApxWSo1co3qxJsWlYO7i2v7Cm71GnA78",
    "allfred.allfred.io":
      "BGHiocNdZNk5c1IctHLT8-X_bsys8GUWOAFcMEsoqqp-rYfV9FnnkoRq3YOdrmeY3kzCLHYXnaCQ4UfY0aOj1Ng",
    "lici.allfred.io":
      "BCEAqXXIPX1m6LNktKx_dQG206pG3C9k28EpAx25EqEwn5fozTXmBTYY3lLqIZ0rLzrwDsR18VStE1fI3sPG4TM",
    "pssunrise.allfred.io":
      "BN0TfkTUHnjdBnIxtoXmh9zOFiBr8j3ckiIa8dVEKjfPtsEVcg0KPVtescf9lNsP4DjQ9LX2g3ANFg3N_SFL_RU",
    "slovakfriends.allfred.io":
      "BEuD4CKk1V2md4Rp25HXk5_t0xsXHvGv_1c8Us7wGGGIvWfJQpz7-yf8c5HNA3QuVpws8LfJ9uZp86RQwc4VLoE",
    "proscons.allfred.io":
      "BLv-FXHlxNSYsL_fBdBemH65zHG2NQeTVPVorIcfrPaRar8o3rUQCqTLQ3pmUIUS9u5Q3PxTu3g1O66L3KC_To0",
    "lemur.allfred.io":
      "BNnvvALGMRBl5IDu22ghvsoKulNwBM0j42Xid3_EgD5XI_956dbsOLtgEGFqedSDhwwuWRHay55KMks4ooFNf8U",
    "zgruntu.allfred.io":
      "BPFDy0fJHSPtlAoPgn89pNOz0FTC9KbwnfsuizesLjUq4XbIg-uU1a6NDxVk9jDUqiKdICxmy3nw2E8sEeqyL8I",
    "visualgang.allfred.io":
      "BKIfu2qxBxkMUaRqKfhFJ1i-6jMDWJOc0VspR1LtdyzPa5FoUJoP9-yGIxhoha2RJ5Dh2LHG1-ny8lOCDeoiEUM",
    "putnici.allfred.io":
      "BK0cIxwwuO9RqHys1-59f7RbfA2XHwMliN8lAIajFK8PiU6N0zhelXIcn_vzPCPB_OxAix1hPIS5eKYEvInRsRY",
    "pragmatig.allfred.io":
      "BG_gDZQmJwucuwW-x7RRJG2hFsQIcfjWU6gcMuE5SWEKz2wJFwsVeNR2vVNpxKol6RGe2SpXZN7hePkarcLiBkc",

    "40.allfred.io":
      "BKTJxc6GybY-p7y8kuJQh0ANfVYljJt-gKnEND3DtUPbmTAo4-4hdawKPPor5L9Qv4wVGgrNLxbAPr7C-o2BIBA",
    "applepie.allfred.io":
      "BH6a6yVC-3aoDMoAwThZnufatyjB7a500Jxw662yeW78cT_0BYHBsjVEL7rvgj0R9kvaQ1BGl0ghqevuggsDOss",
    "communicationgroup.allfred.io":
      "BEGVsHYTFbBG3K7HXj5jWy0wzvhx1mWxjabB1h1JE-ScwsmMmZIuWyb5KMD34NowZXT1WUrj-XXAoYUQZHL0jec",
    "ideamedia.allfred.io":
      "BAbLIIywt-BqzTyOtRsEWq8ytkth3ywClvoVu0tz8f1IkFN9VKL1Wot8AXtzLlLx2mtuiqOiWkddAMAJpvM841Q",
    "inspirationsminar.allfred.io":
      "BLKsdz4vI3O2Chr65izYBWi9eHI8g6xn8FUa1HN_QDcFTIEBLIEXZaYE7_SFl5sgNsbV8cDWbY6bN8eeGSk6SLQ",
    "istropolitana.allfred.io":
      "BL5Ewwvc3ul7PVAdAr5qigULFlgLUyspt0NXjv0tNcwfcyA6_hVJzL8EXwCYNCyecb9FrDgOJvshf2Zjz2MwAVE",
    "ogilvyke.allfred.io":
      "BF_UjZjkG9tgrYG9pA3d9-dHmlnkDXKSiLjkt6mALPQQkXcav_oWiWganom1tqyFovbBTTpyaCJVKrfD-jXTp9U",
    "zaraguzask.allfred.io":
      "BAUYNJ9lSXUV7CKCGTHDzd7gG6TvpblemHhyoortHvWR7JsiSwveWAvdsj0HHKD0TKZicWJw7EWITisPgUgZgPA",
    "zaraguzacz.allfred.io":
      "BCKsoWwEfrNMsD7nP-ExHZNQI8x1yKE47D1w8sIbZ-NRB0CZ6J5WCYQVdX9s0RCsQXUf9jyLjfdZ2Fiy_Nwie9E",
    "woo.allfred.io":
      "BKz6giEhscYRmEJXmh1I57dqz9aOjarsOhn6Tzk_TKLrTLsdrfySdCwSTm2i63Gv_WUxBBU7K4htr8PG00mytxA",
    "vacuum.allfred.io":
      "BJOS3s2-IZRyTOBTrHJMOvKuKFxr4UnxuauWiWkBzDlpQQcmUrifBxdsSjoboL-mdsAnK7165PfchYzJOdNRYY4",
    "flo.allfred.io":
      "BDF_wLL003dGqiT7XHPzOxIX5V0ClzWvmCvtT1iNk6doWUNBJ7_Nc9wkIWiwsC6-b_5KH_5DXlw70KD0oeAWCd0",
    "primetime.allfred.io":
      "BBlIkwQX--oFM3HVhsMIazbSon1tPP4fVMjjc1_iDovML9G4Ed3x4bOzXfh1dduAq3Uh6jxXtqWb_hv69bJ17nU",
    "madebyvaculikcz.allfred.io":
      "BBsMsKqG-jj5om6ATCf3Zet790krWI9HM6szGS3EiWX_ZLGtk-WQ0QkWQYknxLtJ47Z4m3SipDSPeDNNegTjNnM",
    "ynk.allfred.io":
      "BKJdkZvl5zAbyWPXdFtkWgcuX0jyVowDmMaXci3AFCa9PqoxEogEVL7lEjiyIXvktCJfbZZTliSDfPlpR12En9Q",
    "reequest.allfred.io":
      "BFK1OK74pvRDhEXTnTZ2sWdoDNeDx0nU9_GPnD3iuqXA0_obQczq_agNH5Le1eDjXhxUu-BD8_ci-N8fwzrOajA",
    "mybrandagency.allfred.io":
      "BHgxTHJ6cDM0LepwUYXVTZfI6OtrHYpQa-gbwZGhHQMLCYaK11no6NJ1uslKsUgb8AIFED-8CBzcU29KR4lhwh0",
    "volse.allfred.io":
      "BElmpDhX75r7y3W_uLIKjwVCMZcv09IebLYXUvRF-WG5ZxXXlxFcFknscIdInMoiJ8cXrV3tcqC-apCgM6DVTSg",
    "supersede.allfred.io":
      "BJkYsFXFzOPR9WUqbT3lOPgGVWjoiTRlmTBAe54J0iHrr9cSVLn9SOh6SEf7YZeQH1E6p9bWgg3nUbzhbn03akQ",
    "kontento.allfred.io":
      "BIlcfEQlwX32g9R-oqLCcT0mfByhyfNEtCU2R4DcHdpD-rm7OgAgkqx7BXqTdHiTYFalpa_D8obtVyJeI5tpCjs",
    "mijena.allfred.io":
      "BDqQcMn6k65Pd69HYgIxKaP9WumgYmMDrYAstNsW8Bu60o_FO0ZMQ_Ug5cFcgUcLJN_RKxuTaN9a7UCjMf22Wcc",
    "mediacrew.allfred.io":
      "BFz6Z0YkYXO5PH7u5Vndd_GDqO4EskNw6E1e4DhLttKI4LiMsWWZG34NHuvGht04lRP53CWRT5IZB9KYX1ywzQs",
    "gigaassociates.allfred.io":
      "BMbqmEml8pa855ie9GqH2Hg07pGLA45K0JeAobBm4GU0haO7oLOSNH61gi2IVHB9b2xrohz_Dhcnvyn8Dv3-7Xs",
    "b-m.allfred.io":
      "BIP0Hwj8Bn5YfBHzpOaJMh6tFl9FxHJTDLlordKlXNgrF5iYq2NZOxzv01nriDxWXcW2iT7aEKecqN9Y1dXT-ZA",
    "greentalk.allfred.io":
      "BOdSWaQAzv54DxedR5udXaJpyzJCsG8Lh7JjkVLt3ZOh8PKU_P_InjJ6fhwzVwkBXJ589fdJ8H1QxHxarGjOc_U",
    "datarevolt.allfred.io":
      "BFafUHa2sjgoNKSAFHEG5B3dNWkz4u4-X-itfQi73NomPkhnK5Xy_nvA2nQZtFiPux9KkB5fXhe270EOPhyk7v4",
    "socialelements.allfred.io":
      "BDouVX-GYAE1U_BNvkEC3mmgkG51l3FEqoK9rIcwEC8bJ7jOVa2MRTKWaKCvSn7_oyyfaTTD8PZ9qnZtRGwjp7M",
    "roimark.allfred.io":
      "BOUo537dI8h4AoKS2u1-Iy1Rr7SKHjvr6fK5Rlndkx8gXt0Nb-g7TIBka3sVNB0v7KAoDzcrDk33JMc4UH2Ia64",
    "yyy.allfred.io":
      "BJx-kctbYNFRb5O-Mv5j3yIe1zbLGvTtf8IK5NO6RgZvRFGqHUvPnAX_20D5ldCBOpFA-maMF71TR2HDL45x4hQ",
    "dinetix.allfred.io":
      "BKVLyQB1bB13Ty_kN9lsLr9GtV0C96WDpCS11r0wdnmorg19boNLDjzArdzYRrNpyCLAgoenVGTMQe_2fQB77Vs",
    "ideascr.allfred.io":
      "BLVGfCkytI74ki7-NpT54oFwAJlLFsJFDVzjvVJm9UTYyCMaD2R1poDZz3WxOVlaYBQvYEOXceA5cmq8UyB055k",
    "twomc.allfred.io":
      "BMF9KVRvtSj4Fr0zHqvJPwN2hUvGS2bZw40pQg5KSabWb7qr8rQFUmaH4MZaH0QT_0-ndofIUWiUOU1CiJMfGcw",
    "forecom.allfred.io":
      "BMF9KVRvtSj4Fr0zHqvJPwN2hUvGS2bZw40pQg5KSabWb7qr8rQFUmaH4MZaH0QT_0-ndofIUWiUOU1CiJMfGcw",
    "casual.allfred.io":
      "BJk_27raK-82mPRLfN_3EUs94mpFsXeWB1z1vEHUWqYzq66jpSk7vhSfONKq7eN1gHbblQTLpR_dkoU5UJ6m7GQ",
    "owicreative.allfred.io":
      "BOnJE-HXkuxP-cP2DpxDUi9QtezEVPTCg3TPhYYnXwnBA1dTJ7Ye3edB8V4egS9sQae6Z7kmRIgIdp_yAj4qjgI",
    "grizzlink.allfred.io":
      "BOJcXcHQ8Y69xDIs_hZKmXZCsjuXAQZIgehNso-08KiICuLoBNaU0XZWdwTzlsp1rL7y_fK5go-Apcz5QcTXe8E",
    "themilk.allfred.io":
      "BNIbbKme2DkMwrEFoNsB4jIkRnRenBVJiRxTFFCTithBLSRlkiR8dswaJzo_5zCG-nR_CWqZJeWuFe3zy_XeTIs",
    "pssummer.allfred.io":
      "BE00bJeQOKUFnA3S8emdtYkUMGB5rOQPE70zynio83y08mkvLyPtWbYh7SU3A2m8g47DcOXQDc0Jg5KGct327Yk",
    "siedmedia.allfred.io":
      "BJWH8fOO4KFVSCnkbXxaKMtwR4su2nLDwgQrqnaGQKfJuV-QEcdCWMvdT3k24u6-TBlXALO1-CD993n8Kex-Hy8",
    "jotabequ.allfred.io":
      "BL71ftHAOjWmzsR34uN9TCx2IYO7OngCjmLkE3zqo7slRxNrK-Dc_PIN7Y9s6D_RUFRr0V77zF9pUwmBM7pDPMA",
    "fulltimepr.allfred.io":
      "BNE11X810Dr9HfwEFfZgeW__Na2BzD-_eVUyllQ6XqRX--3lcz7FcDLL4HstF1WKAT8bdQorgRi72g9U5E6YinY",
    "riesenia.allfred.io":
      "BMTq15OVvo7UtBIY4h73IDyVKTqpqOek32PYPq4PO5ql_fPQoxYXLsNBSIjpxOgb6OJfv2haGJWqZL_TlYmrUVo",
    "stre.allfred.io":
      "BC_0PlR8cr8edCnW_TgK6eHfSqtvLpBfJReQ7wRmjvzbVXgZxQnmDPSKHZfGNoVBjOXk_2SNKhCkVclExD9HIrk",
    "nativepr.allfred.io":
      "BMO9sgJ_dellSmXyyvFInJ8f6UmQsw9l6XRCRUZZzpJMLj790udjwyoyvw6KBSMD9nlvXLdyndbd_Ito2sCq_6o",
    "bajan.allfred.io":
      "BPZbIbWjTIbNKiCr6z7zPKe2UOSBp2u_S61F6p2M8xjfq94DkNP_n5pqbdYDrGWrBdHbRSPHdwk4dPRDAV84d5U",
    "nekudadm.allfred.io":
      "BIHGfrH31LKhfznBS43sbcoFVfpbtc-m1bNNGvXylHWwpyxDxSEg1uYAj8EqATWYdrtg7obskGKVQEunCJHl4Q0",
    "upterdam.allfred.io":
      "BNg_Ul9QfOZsKusbhLwnPKYZgNXZND3vy_N-wGGbBX6-Qu0Ce-mdlVLEHpiWGCvFdP6dEjsDvdknnftJzLZ_SQk",
    "brandevous.allfred.io":
      "BPpYpA3GouBuMYxoWyrGamVQSBh5aYnqwq1PKmjnU2xIoXtBYbx3sil_PPCwJKywaepOBlzJxvfQq7iWPjfL9YY",
    "volis.allfred.io":
      "BCFpI90BaB_XdPY_50Evb34nKJmEDFt8Y8JXBPwgAEYQY3LHLlbTobNVNQ_e4KqhvdeRyYOtcBe9Ofrtkx4RfhU",
    "dpmarketing.allfred.io":
      "BFYd3ZbqS5kbCUwpFROVG8j8pjTpsKKCKc4joSYws_8Nd35wNSz2o9e_ZyVhKYmCe2HlTrDXCfOXGDv0YM1Dufo",
    "cheilcentrade.allfred.io":
      "BFXHQATTaC9LCn1L57tOYqdhJZcqnEtpkMfuj0HLyIMk2Qs6X1PdkxvoFY2Gp3f3vL6r6ZF55cP36rb-Oi-HXOI",
    "aritmija.allfred.io":
      "BHGJv2yq6d0MT2bz3YZr3Hrb60mpR0Gr6IdRqcam3_2uSo9u60k5UiTxhOHRBuwCSUu4R8bMx8Eb-85BpusZtfc",
    "wmc.allfred.io":
      "BGwdCMIa2vBaEvn3XycaCC_G-oLjaj9p_rbNd2CJlkC_nadUDnCPHzKfkrLgG25geNSjgas23Z63t-X6InrLS-E",
    "trendis.allfred.io":
      "BIfrRgBRGXzVLbDA5Um5VUtrSbZ4SRQcU34QhyempZXcgaS2PfoM_9GJaZ90snw44aEBSUKX_aykxFgMWnP5iJI",
    "andaction.allfred.io":
      "BFkIvOc1pbVw4L4npUnitXUoJdJ7i5OiIGvkGhLr4DRAzqS-WBREc8W4wbbtIWkFgW8h3S4uvUNXSf_8xHluSaI",
    "thelab3.allfred.io":
      "BKmd63BGbkvNVT383SnwrrkE8u3pK0ty_psyWBEOH_e0jvawU-uJBX4gNN_L2ZbHaWpr1BGx6l6ppFvCksyiwmM",
    "socialmind.allfred.io":
      "BDl3TcXicb5tmcWkOjwJwvBlBCWvUK7cenuB_-i_2EOUgMtrpEf9K3ChSOyGqFANUi_vh4j8StNZY_4amPKvBbs",
    "scaledon.allfred.io":
      "BNQZ9QWthuTVJvMIN4HL1NrwvTx0ahozhpTk-kVEev2bL6e7vwbtrVE20m6h0XMfFsgtPMDf8kvDUh7bzTIB1Vo",
    "searchdisplay.allfred.io":
      "BKvw9WG26QY8sfm3yfq-cYeMSthNonjkNaI8cg87fxHGFuVApuJcS5uOwupaPHtxVKXYC6WWcyFR_Z6_hDENLn4",
    "plakativ.allfred.io":
      "BOovTiqyQz4HnKvJh-osA7f1_0nP0iDRc7dHGnFATPKseNRojSHfflZimvmJb3vm5pixYuiYlG9iFJbsGkSzPWY",
    "fiveelementsdigital.allfred.io":
      "BArye8XeCN8aEbtuOqpwa5Dc6ACF3nCG-w0C6_MV1e5CyRO-FsT_7rrXHVnRNFHNYl5ZWmTLdNs9GmsN9314QFI",
    "adison.allfred.io":
      "BPHHfSYS_BSDDzcWmFpVp5fDztxbAZ7jlQUd-o8h97GlMPBXznzPo4Q5HeDnV-hCYNfI74GIxzqNp17aA7ot5Bs",
    "outbox.allfred.io":
      "BJiMB3nQOch6GuxRqG3TRoOGHEbpTNrZCDKeLgU45lQkdZa3y2sKf1OVfHdVgIy9n3jpaOpgI9RVA1YGp9Riu_8",
    "kreativnadvojica.allfred.io":
      "BHkWGQOj4y1xl_q2iobriG1zRE1FBYse8rrSJb0I7BlLFr4rsmt2Pc_wjSjfERZQpDYNyz83x-D9Z_7EEh3Z9zo",
    "acg.allfred.io":
      "BCXGoX5U0AMCXwIelg6S0uum6W9jr2RmLmbSGGic6TKveezrGmIyVsFu8U4_PbFF9YkW_oMvu9cr2YIuiqTTots",
    "bickerstaff734.allfred.io":
      "BHmSF8wPxmQtHQUs9diIJoWpqPWTZFe-a7b3-6g1ymszYtyffJ91YnRLtDffWw15-3NYalBLkhJeld1teYTkKMk",
    "jamsessionagency.allfred.io":
      "BMEmA6F3aRtsg3qPle9-e1XBZwhby_a9aUCWxyRhdxfoIaW4FulbPziyW1GoRlr9Q-a5C5TC0Si8NlWhadSqEUU",
    "groow.allfred.io":
      "BFQeXnFE_5nbEmEw9I5GRLt4Ug2LgJtB1OcQOpJkio6UyAl6NMzQUTj6EiXjH_zJjrZqIfAjqTmnx1kfLg-N3QE",
    "backrushsocial.allfred.io":
      "BMAzOT8B_mIWQ2KIh7qJbTzeEhRtJyHgVAaQTICMF4wMIx7l2SOOA9_2mMAI5njtRXKVxbQwIisaVSSO9os-l5A",
    "fanatical.allfred.io":
      "BByk-yKSNMzovJjqz8TLLJRQF_QusL_Ji1OGYr6oUp6_qtaQAEOjhB-TuE9cgIZrFXgyadD8OaF3Zr2U1quBs_U",
    "vsee.allfred.io":
      "BMxw-dxEDcoyEAN2lBUBPoAjip2Ige7bxT4oini-EcRJM_ZA68J0Pygg3o6-XBaUOvg98P1zY7Rf0Inx6f4jp7g",
    "webetter.allfred.io":
      "BNfE5LDwcSvLL9kzWIBEL_6mqEAt5R70CyWsIebcpIC8y8WBenjtjjlf73m55qTun4Ex07pMr1gGLCEJUniFC5c",
    "nmd.allfred.io":
      "BEdrwchckvlSix6uCnlnRokpJhtaRyEDMq59gg9EdOj36Y0ZfCz88xWGFI7kGWINkJw_fmnMY_jJNPgtI64MOvE",
    "inveo.allfred.io":
      "BCSwhA2QJnrFpq-d4SFtLVpr7jCIzBBM-fNcEpuDCdg7by-sKuRweCGsZjQAAsszbsC1vhmbwuO9GIKPSGbttp4",
    "connectmedica.allfred.io":
      "BMHPIE-3I_j2aLOpp-y3owEUTlpmkvfTr_qQZu3c67T8Ho3yVd-PkpryaPuD3Oi02ZGLNLFUHXdyuZN3C5XrLfg",
    "vmf.allfred.io":
      "BMfRDhf7jk5VNsnVoF7qHNuyvIHpGcfhG6CXAZKYOUFEzJSzt6prSHGMTW2_KojfOAJZH9I1U-Nc8zhzHidsqFc",
    "bradley.allfred.io":
      "BPt_dEWyTq6QU0c0busUrn2ugdykcN3fOs_-v14bxdwRl4zaJxIOiqViVe9dN6m1oHxVw0YzyMpiZcXLu6c2_ik",
    "obsahovka.allfred.io":
      "BJBL6KyUm28nwymL_MxnEl11MG5kgUI9NNWMLFBILtz7n8S3HVV8vmG3a-Jux-wmTbn0AVcs9edEpVeOxccuHyU",
    "curaprox.allfred.io":
      "BNEGqE3OSdFiFTCUPAkdQsrICTFEx8UGiONVuz4aQLiWaXndSuZfaEntQlP2SfwK5Ls-iEb-QWn7IwOhMEN_HoQ",
    "mojandroid.allfred.io":
      "BAQ19fAV4nB91Dkixr-WqTCUzUBgoCpNOCuboQYBdKxfpLvzv4vNficgIji8ezzuJGcPD05p2d5JlwKJ9ek6yOk",
    "evoluo.allfred.io":
      "BCHQQTvCmdbuCxnWurJmRPUqHiYpBvqfztg9EGSa85SXixTgzLFsYkuZFMH1PRVxzhkeS54x76_oFaFvrwqBgmw",
    "khs.allfred.io":
      "BNSFlsCoA8d7hNWnUac0TjcZ8rUQaSReomlnpkxv_uRzEmA2cxInYkUvyVYalyU53cSoFga9sPcEtM91hMRj95o",
    "catapult.allfred.io":
      "BBP3Wvf4WosgYwQWnDyqOt5_k37bOEFgMHl9JhICD1SJedul_Tl7DZTcksOBqcquGNdZzETVnH4a9r4ccp5ai1M",
    "agendapr.allfred.io":
      "BJcCr5ESEor_tQ374z84PpmjuwEcdNJjeedwY2GikehNvZymoqHctaUp2Z-EKhiEew122ZTMeqiAjh5w0Sb2xpM",
    "modestudios.allfred.io":
      "BMYXj8ZFIrkkxeIOokrZSm0sVzvh48z2oRUoHKOpctlfwH0W5HirRZeS4s-yccQ3MlPLglBdqIEzxTGbD9BArLY",
    "digitalpartners.allfred.io":
      "BNG9TCofYdotc9taTOyub0n_mavSevDTVo9MmRvBxOi3gWrstvBvn3UlowRn8vWKbeP6rlZusK3PZFGeyM7Ub9A",
    "jedlicka.allfred.io":
      "BIUeulXyvOW_cJceJBPrmLFY2FdfxjYInjNjANXKLUHxvbvzEJuxg99RZ0d0Fj3J79WU8F0Z1Ilht73gbqdN6Ug",
    "daremedia.allfred.io":
      "BBxlaly4CvCJ3QAzyEA4j3xMaiGvLnSnlNdPjFcmog7IPA8LY59Je6ORNf1Ec8A-F_ZOm-ybz_Xo9ANmJxiXOGA",
    "greenagency.allfred.io":
      "BEW1WPh3E46YApn50e4R_rSQJyTgYgXX92jt4MNA6MBr2oY6vPAiTI0pGxGUL2PDAi7hiqkSTyHn2KRL_gdSMvk",
    "picards.allfred.io":
      "BNJgeSFDmwcu1O57M_7Q8omD6W8F-baCwxk9tRfZ72To1FK-YurqmehM6PUKBWDQyB_osYeRncouDM18QrkWDfQ",
    "anotherartfactory.allfred.io":
      "BEmfEP_y18o6Um2kBHW8jnpIjqq3YD4BkGDFev0bhpqK2ryJ2lhi3g_UuCvYJV0RKmpuqXPodBaBBRMtyX8aC8Q",
    "arthurmedia.allfred.io":
      "BMJjInv6JHMiUGRTmlCtWxvMM9lDvSe1rB5ALxhE44YNIjffuDAfAjooq9ZHoKXP1QClcOWrWbtyLcIx_jeKhN8",
    "mds.allfred.io":
      "BO0MRLNKseieRuoO8iNBhu85lCH3X8stLupMpkHbvgLK9ZMhS7aCnGCnlOukmLfSEekJ5DNZycuEQXE9hupRrIo",
    "better.allfred.io":
      "BPYCTHivbPhqFiD3F7xErHddz66ekS2H4HiScifR-YRc3dFPYxtN-mtvIGxGNaPFNAfKfp0sg8xeAU-GaBlHyGk",
    "krazyfishmedia.allfred.io":
      "BB3N_riSGsSCqowdT1UBazAHfAz5tF3aTDLmcI-sH8gZ8oOnw9sfJipQ-plSKCAYQdKycj_s9V6ExvcTjF0Aaew",
    "mamarketing.allfred.io":
      "BLHYerGX_PJ9GbcJZkpJkQuWx0opXB8Dtzh-T_NKoacYLnHrUnehrPbfA6bBozBS3FyFAZYfg7VJfKPKjtA5B94",
    "acenta.allfred.io":
      "BFCydS_dgMQpBa063B-oGfYYE8Cth5bvvZNc12GzMWK89e_u3dGoOdjL0XMvQsRWrqfrJIYIpP_EsLhJmJ6OBBM",
    "popcorn.allfred.io":
      "BIz59DkqHPTjxtE8dsqofEJOgwATWB2pCKVAXLR72Wb3yTNBNoXAj_4ZsnR0LGB5JAKA2M3o3gEqNNPRh4dSRiY",
    "creativecity.allfred.io":
      "BD2ehk8AzuYI77NxknBLd9A_rZcSTAiuX90_fvlE5DcSWQzhUmuV-XACk2jWSg55LYEGKFNIp1z5V8jiZAHXvlE",
    "macromator.allfred.io":
      "BKKW1oy7dCNs2-VUdMLNPTsnQ4u329KGXYq-QvVZOCC61IELzdanOut4OSNjTVnr8gfyxrCFtAKfao_7BX767ZU",
    "lafluence.allfred.io":
      "BGv1zA_SP7m1Mx7AVDQXqqKSxqmDLiP8Ks_DcZ-XZtn9t5iv6HYmQ7hAhbO7W1gW9SFVV_L5iXlWSwEFEiYbGZw",
    "ponyhouse.allfred.io":
      "BGtwnkTJAIP0xrYzuouxt29dxBpFqRshCd8FMfKLzYVBj48-HtP19KEUsYnRXxcfFx75ewj7WuVRI5v848N7puQ",
    "2score.allfred.io":
      "BMP8AH7Oq-lhRAgD8n7AWj4DtDa3Jydg3VAqU5NWV2OzImzjqWMSf-pzDfmqEogu0AqeuLaGedoeLft13IwCBPU",
    "czechpromotion.allfred.io":
      "BN5F9RBv__PrpTnqDNsG_8_I-bckWv3GNivkFGxRhaEy0dajgIM_tIedSJt7_PFytPEntxohtJjJPFdV3Nhpq4Y",
    "theflashpack.allfred.io":
      "BNhl7i1fjEoBe9-DA9mY25cCfR9-UJLtKFq21hZ9VX9AbOLMldjElR1u_OQexowEGEgpgj3pGUHtJQCnUGtUz8s",
    "katz83.allfred.io":
      "BNALeVNe4kjUsYxIhDa7LU6QzflNQjOPUSWcVILCb9ijklxY65SCbE4cdb_QXj3A9LzVSZ6Kieq2SiHL-cbu7xw",
    "rainbowidea.allfred.io":
      "BBRpGDtI77akU0SyjSUAhjWV9mTVn-a9hVjFrzKD_SaXo0GwwuVDEyT3dYVbFVCMf7_7W7R9hr6qdCzPMapmiBk",
    "effectix.allfred.io":
      "BAkUWPzE8edZhfVO8ZJCOPwmOBwpo5CpHbfeAdMxbk-t2VoLaO0adDbr__LbE10_5hEAR5F3c7RtaUD7QLb5UTU",
    "mattiabizzoni.allfred.io":
      "BL67s4ZPQzzZkOgtVNoSZlOO4EdrtOMO4Ukus6tJHAR1cZZefDGuzyWLBZX6A5UJjHhb7b3A_AbnGDsjYGSAXDM",
    "unexpectedvisuals.allfred.io":
      "BCVHAtqRRFhZ487bR3z5sxv0SXq38YnlZO8ll2YAKCdYUZH77uysePtULyitRUi9tG-myWIL_LiC05ihf1YXTec",
    "atmt.allfred.io":
      "BJj9d9lmCB4XEEPc2a419sdU7HTLIerEoQhn8lWEF0cjhxljutCg0E1aG7PJc-QJKydZx4h1YDEM14U7UkdkAvc",
    "misudesign.allfred.io":
      "BOlUwdRxcTWy_8nwb91QZrGGPRCuZHEOdbyN3Z1kLsQ-_M1K9V54ZWih_9ceyiYRs3rbq-JwRerPtjave9OI0e4",
    "allencreative.allfred.io":
      "BBlsSCNDx0vl61KuEQln_aAibgBKleiUY_oS9SXpt5yyDz2OgFW0fdBV1QIf1sHFfVadCIPbmO9dGh0ebJdil2M",
    "ide3.allfred.io":
      "BDml54hIG49siDZiixrK5tBuNUj6AL9vnsP9LRnEIjxAGuzkYxvEAk_PrlN_T0MqFcpDeOwdVfZCN7CMlZ9lp1U",
    "scalemakers.allfred.io":
      "BKH92xdL2T3iTJ320p02JH5kwMYYoi7yu_HeasMdziaP2O4MZvNfqNaSDvNu-g-5GYYRvm6hQM_FdOh6C984I-U",
    "dabl.allfred.io":
      "BNlZhBQHG2DJy4t6mEGQ3g21x92e1waeNTn6SdKnGsD03bvX0fFQAxD5YP0aeLISbWhZvO5La5pD9PN5cPNr7l8",
    "pipehack.allfred.io":
      "BBkAVRg5wOe5Gw84fpFUPRXM0fmZEuo4zzhcdQ9btCabMSaYtHx2PHvynXYyg2mdeGYrjgkRwOwLKLy2-ylBTyA",
    "cheilau.allfred.io":
      "BA22YpPGeD2A-pip_vwdChA7S6ZtW3txD7LhZ9vgYfSTcOwUKmQa_T3Z5cSSfJF2UMNAlbW1pYdxHV8jkFp_Wq8",
    "blackfish.allfred.io":
      "BHHdoN4O-cOWB3flgNyXtOXOrJTmLp9HDaQ9gPJ3BJA8aueiJ4PgoGa2MIHkKq5SuySzk7HSR9lfc0SI2LGTrOw",
    "rexaromanpartners.allfred.io":
      "BOpXzY-xnDyUgJtXp-kmsox6S4AaFe4N67OZClF90VDMx72PUNn7wTT0ntOEwUAr68tmvQcie05_0oWqbVbGN9Y",
    "cognitivecreators.allfred.io":
      "BJasqFhB8kz2U4KUhhJ4bAX-0-xZs1gy28d70DFH2rtmFA-kDROkPLX1WBkCbEzJLO1BknKn8xR4iWHj97m0Xt4",
    "wekadoo.allfred.io":
      "BKKyF_8M1y7lU24FU1KlXopx1CPRzjBFFDKuPrBH7sy2Phufr2MPoF4I7cKAkFfe7ztjEXK_r-ZKiNyRfeGuATU",
    "fiokaanimationstudio.allfred.io":
      "BKtJPsNy01iysyQGrzu4suvkfQM-crzC4m1Zh97JZsFGdT3xxZ2IVTviGP7cGryHMxN0-O203dOnlRTA0NA6QFY",
    "mevea.allfred.io":
      "BCuRHErGpUnhdOzBGy_SeKeg7qKfdwn2Xn_DSUicW27R0X-dSr_la1Azpp_ZQodLN7kRF-ui3KPVwbObxK-3m3U",
    "craqdesignstudio.allfred.io":
      "BEdyaJWQsdDOvNT1ykgGKX2G6R-gKPRcoX7dP6NeQsfeQ7cjj-o_XV4j8J4UDwO0vKW3Fk_5v05nShgnjSEk07A",
    "ideefixe.allfred.io":
      "BKU8dImyMMeMwEhnAKQVZ0SOUXiOqbkcOvgkJbhPnsEGxvkIatfZ3CtzFdLc5fT-ssAUlYF5VouJFZk1WcQ9FN4",
    "newage.allfred.io":
      "BOLx-QKIEB-b8rSqzWsSkzMP8ctmY7TWpfk_uRJkCa1SbfGZs4GmEii4Q69uDCQnjOOghIPdgNttCUIZpD_35R4",
    "grupambe.allfred.io":
      "BMVJfKRd5V6pQ_SLQdQAi7x3KjqYt24JVnHbzum9nqXoHN9QuywlDPSkG8Pt3WfDWTwIe6SGYqMfA9tjUZzpQ_A",
    "rightandstuff.allfred.io":
      "BICm0wSG4BVmErYtVk1rvUcnT353AwsJuvSukaSgutzMfLof3xPqoHk81I3t4s-VuXp3DQ3s8b9Fb6I_zJviho8",
    "avraham.allfred.io":
      "BBwPUn_w7jCdx2_-NP4rctlKNuTm4M6IxVFCRRh7AsERvYE63jrqTcfBwIs2fNhkDniz5ylUA2_gzGgroyiW3so",
    "cristicucu.allfred.io":
      "BDSRYBnxyN_ox-mwpZxnzkN05v2RcCeeXc0DZlB3WR5NbLJW-0x1KwCxAsS495uFnCWCrl8ELNi_h0XSaIe5Vgw",
    "camping.allfred.io":
      "BA4IeKzL7x4cWZ7h0e_-Uz0F-6tq_Iymn5MtT8epEbXMMx_OA-tXFz3KzQvAxPbVFcoWmLBc0c7plySOYrIDgSg",
    "arrowmarketing.allfred.io":
      "BJuUlLu2j8KfsO0-PDrSRHfNXLpIYqdnCK0f42bqLs2ZgXbtFuE7zkHhqZYc-TZN-BHfv-3Mn-IlvPWmty8HEYg",
    "3nyble.allfred.io":
      "BOh-6AjAkr8cAejt-EfqW8GNu-guQ3V5FKQ2GsYyT0MBC0C9ZpWbyPQrlKm_b5tNwLELuE-fofN9V2khvwF7djU",
    "travelbeat.allfred.io":
      "BCUkA8sTyRAlu_6LkHRn-Qnik2GUK0ofZIHoXHvKzTLeDCui6vdjr0aQ6OagyRZofno1v7p_MjUs09R_VWrIjQ0",
    "bavariatt.allfred.io":
      "BIVaUR0LQxuDEXs9oRNp6sSk9DS_2Ja9Jh9IGv1hslTMxsvgClZB9OTao57lHIh9wO1SHY3sI_60iC_QIeF7Z-c",
    "sixtydegrees.allfred.io":
      "BB9HoF3epgSXvUhvo6ZJMvsq2RJtF4KdHHTnSS2z7syaguwCG3wqJ0U37Bnbgwh7H_ON5U6_F84wA4ETC6bXTKA",
    "robsayles.allfred.io":
      "BLh0cJgCbRvo_JFGcORbVUV8n37SGmuBpLObwp7Pa79nEZbqIhO6Dkhgslq06Gfr-7WbwsOQja4PEwa6z_-RUC8",
    "oxyshop.allfred.io":
      "BHkflu-_Z9LaDg7RSnMl-3yp4vBgk-yX6xv0AknUhFGt1f78h0Yz355U29tJINftkQNmzrIL5u_EJhwbAhz8PLk",
    "scrdesign.allfred.io":
      "BBp1CNBog2KzbUEAFkEo8QMBgnz7FxzoJQ81OdF4EbvP6L766Djov8pALHiUQfA4PHE6eXN-xjLSYSLRH3gwm-A",
    "fcb.allfred.io":
      "BHShRpX3MtMYR3xw_4CuedHtE-QD8AZADjaSaouKUP5wh-5pdyszASQfn-ta4TfTu3Od0-4MjwkQVrAKYp-mqUI",
    "sevenmedia.allfred.io":
      "BC5iO_iMRVVzQm_Tn76rjDb8x3zBngg1iaySKEduAcBj2gd9T-AJZoGkcxatRTJKbqElajp5p8jVF0ueSTurBWo",
    "feedersagency.allfred.io":
      "BKlN2ViaKrL1TAfja_usaUO8-gnqGAJsN0RPCX1JmHCFlXMofYbTSEZ3nyaEWmf6irZNjgs4LtJbOCayh1cWIl8",
    "asabell.allfred.io":
      "BBcfuftEgDJEq6nfIldWFF67jr2E81W_E45EnLzaCLhhVcUyclj54RACDGnTiyhTe-gdz1bgTemV_aRznN0EIEI",
    "measuredesign.allfred.io":
      "BHINEKyj0fZ3qOcURGSLrlJ_pNjQAh9maisly3Cstdun4APKqcKX51p1oNbKI_f3uS2b4yFtd8u9LLWPMsTl4Hs",
    "scifi.allfred.io":
      "BDWxVWI-kkM4gANsHJHe20AAs_4tE6vZLh7vbdbsUv4vBv2MNIik5hFC5_y-4VE4BzJCYCs7cbLX1E_boC8mwik",
    "marklab.allfred.io":
      "BFiIhluihbQNRX6UbCA2BhMwi2RlmKuKyQoyhFuT6U7hnrJ46RPBSI2hTuEjSfdy2j0rfVxO9HXqQ4nZ1LNhzY4",
    "jdmarketing.allfred.io":
      "BG4Pfe4w3uDCnJJSFD4C3jbm5TXS32RMstOz-hkhAvCIA5FrNJzkejnUImteMonMRjOKuOy4BB6o6WTH_e2d8oY",
    "inforo.allfred.io":
      "BPSYSA6d23qmxfblDKDS335net9umLw1qwIpmgbSecI_QOUuGPdSe9xdMuR86uTM2qom160ot_gA13uE256Eg8g",
    "kras.allfred.io":
      "BGmxKNlBlmTKAWjw8JAT1eLulPx0YdnUrtw2aC1p3SFIxXNlCjm42kjqEQSL_pKw4WLtjjF_N96-35-q7F8F9gI",
    "fabula.allfred.io":
      "BCZKF4qmvp9t9CGEMNC1ZUDQYqsWPV2uQjLr8iJBFa0F5ffj5tb7qSSKBeG0usKXcG9KZxEqH6GPmnSycba9Gso",
    "popular.allfred.io":
      "BOBosb3cPH09w0a3-VcgSXsYmyW7hZcDgbXm_a4RV4C_xIKkK9Ere04SwunIrCWilx4-F-S6IHF7nWRuM1L5Iks",

    // Demo
    "demo.allfred.io":
      "BNYiJ1TfUsNuKvY1O4_39CubF3QybDTC5LCwVXijX96IvyO9TpFSLD7NG7BaINAEBjGe16v-ZCSx-lgZ8xMe_bg",
    "demo2.allfred.io":
      "BDM0YCsJnEEDyaC-cVJiuJkFTBfRvQj2l8NbK7FUOciyQoYIatKI1OIqaQ-jGimIAd_gqqIHFTFuBgTclKevoY0",
    "demo3.allfred.io":
      "BHvcjiuESqW6UqBEa6YZ713evmtkNKeCmRwE7UcZTj9vTEn9h2Z79w2Z6XC4XOV4pvwI1v53Ii8Mt-FPSm0OYY4",
    "demo4.allfred.io":
      "BIUt0wSbKnAWQ6-jxlL_ixf0y8_qwh9ZcaZVZaeqOJ1FJqLZ0CQ2fXqkNyx3pIUw6Uah9nrZTwn9_WOJ-xPDl2s",
    "demo5.allfred.io":
      "BK1NAphLWkG_V90a8yeMYbpTxcPwMSIgpFCDyXzvqBlCEBB9-6O2Zl7k3kjF1u98wMqTOMjSPxaumIiwL0X1pno",
    "template.allfred.io":
      "BIJ4XnuaGMR4ugbx9LWzrn2Rz70mb70TmbtFpAni1K4UDg87j51slL5FDfjSCIJ7BmUb_QeNEcOCGIJZw9nnzZU",
    "presentation.allfred.io":
      "BIJ4XnuaGMR4ugbx9LWzrn2Rz70mb70TmbtFpAni1K4UDg87j51slL5FDfjSCIJ7BmUb_QeNEcOCGIJZw9nnzZU",

    // Development
    "dev.allfred.io":
      "BK4QGduJeJgtKiW4ZaquwdLwwRa-LXaTeVid3rSh4EZuKffsOL6UMsQeCE_-HxKwyd27LwbnkP_bZdBbqHjlfMs",
    "staging.allfred.io":
      "BCt5_meQjA7m6OXgip8W25VfHL79-Fcb1Bq00pWk3Hfv-85S5gLZo7Rfr-w_6BFXdnfzErZ5m62WjCisjy6GF_w",
    "planning.allfred.io": "",
    "release.allfred.io":
      "BHWA4VBW-jI1P7T91H4mC1Slmyx3K2gtRIuB2KSYNnV4hh43ILcQoRZsl6tvedyynMcuk-KmmY5_sEfwRJI7Cek",
  };

  return (
    map[hostname] ??
    "BK4QGduJeJgtKiW4ZaquwdLwwRa-LXaTeVid3rSh4EZuKffsOL6UMsQeCE_-HxKwyd27LwbnkP_bZdBbqHjlfMs"
  );
};
/* eslint-enable @cspell/spellchecker, lingui/no-unlocalized-strings */

export const API_URL = `${API_BASE_URL}/api`;
export const planovacUrl = PLANOVAC_BASE_URL;
export const VAPID = VAPID_MAP(
  typeof location !== "undefined" ? location.hostname : "",
);
