import { createStandaloneToast } from "@chakra-ui/react";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { theme } from "@src/theme";
import { DialogsStore } from "./DialogsStore";

const { toast } = createStandaloneToast({ theme });

export class UIStore implements BaseStore {
  appStore: AppStore;
  readonly toast = toast;
  onBackButtonClick?: () => Promise<void>;
  dialogs = new DialogsStore();

  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }
}
