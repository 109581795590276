import { t } from "@lingui/macro";
import { useBrandSelectOptionsQuery } from "@src/__generated__/urql-graphql";
import { Select, SelectProps, SelectRef } from "@src/components/ui-kit";
import mapToOptions from "@src/utils/map-to-options";
import {
  BrandOption,
  ToBrandOptionsParams,
} from "@src/utils/map-to-options/brands";
import { forwardRef, useEffect, useState } from "react";

type BrandSelectProps = Omit<SelectProps, "options"> & {
  optionModifier?: ToBrandOptionsParams["modifier"];
  options?: SelectProps["options"];
};

export const BrandSelect = forwardRef<SelectRef, BrandSelectProps>(
  function BrandSelect(
    { optionModifier = (option) => option, options: passedOptions, ...props },
    ref,
  ) {
    const [options, setOptions] = useState<BrandOption[]>([]);
    const [{ data, fetching }] = useBrandSelectOptionsQuery({
      pause: !!passedOptions,
    });

    useEffect(() => {
      if (!data?.brandsSimpleMap) return;

      setOptions(mapToOptions.brands(data.brandsSimpleMap, optionModifier));
    }, [data]);

    return (
      <Select
        ref={ref}
        isLoading={fetching}
        placeholder={t`Select brand`}
        options={passedOptions ?? options}
        {...props}
      />
    );
  },
);
