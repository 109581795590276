import {
  ExpensesWhereColumn,
  InvoiceProjectByCurrentMonthBillingPlanDocument,
  InvoiceProjectByCurrentMonthBillingPlanMutation,
  InvoiceProjectByCurrentMonthBillingPlanMutationVariables,
  NotificationType,
  ProjectStatusEnum,
  ProjectsWhereColumn,
  RequestForInvoicingStatusEnum,
  RequestForInvoicingTypeEnum,
  RequestsForInvoicingWhereColumn,
} from "@src/__generated__/graphql";
import {
  ExpenseStatusEnum,
  SqlOperator,
} from "@src/__generated__/urql-graphql";
import { TIME_TRACKING_ID_QUERY_KEY } from "@src/components/modules/time-tracking/TimeSheet/components/DayMode";
import { TASK_ID_QUERY_KEY } from "@src/components/widgets/Modals/ModalCommunication/CommunicationModalHeader";
import { client } from "@src/services/apollo-client";
import { action, makeObservable, observable } from "mobx";
import { default as Router } from "next/router";
import { TNotification } from "./types";

export class NotificationModel {
  id: TNotification["id"];
  message: TNotification["message"];
  type: TNotification["type"];
  createdAt?: Date;
  objectId: TNotification["object_id"];
  url?: TNotification["url"];
  @observable isRead?: boolean;

  constructor(src: TNotification) {
    makeObservable(this);
    this.id = src.id;
    this.message = src.message;
    this.url = src.url;
    this.type = src.type;
    this.createdAt = src.created_at ? new Date(src.created_at) : undefined;
    this.objectId = src.object_id;
    this.isRead = Boolean(src.read_at);
    this.exeAction = this.exeAction.bind(this);
  }

  @action
  setIsRead(value: boolean) {
    this.isRead = value;
  }

  async exeAction() {
    if (this.url) {
      await Router.push(this.url);
      return;
    }

    switch (this.type) {
      case NotificationType.BillingPlanMustBeChecked:
      case NotificationType.ChangingProjectToProBono:
      case NotificationType.BudgetExceededByThreshold:
      case NotificationType.ProjectEndsInThresholdDays:
      case NotificationType.SpentBudgetExceededGivenPercentageOfTotalBudget:
      case NotificationType.SpentBudgetExceededByThresholdOnZeroBudgetProject:
      case NotificationType.RealExpensesExceededTotalBudgetOnExpenseBudgetItem:
      case NotificationType.RealExpensesExceededThresholdOnExpenseBudgetItemTypeOther:
      case NotificationType.SpentBudgetExceededByThresholdOnProjectWithUnapprovedBudget:
      case NotificationType.OwbiSpentTimeExceededGivenPercentageOfTotalTime:
      case NotificationType.ProjectWithUnapprovedBudget:
        if (this.objectId) {
          await this._openProject(this.objectId);
        }
        break;

      case NotificationType.ExpenseAddedOnBrand:
      case NotificationType.ExpenseUpdatedOnBrand:
      case NotificationType.ExpenseAddedOnProject:
      case NotificationType.ExpenseUpdatedOnProject:
      case NotificationType.NewExpenseToApprove:
        if (this.objectId) {
          await this._openExpenseEdit(this.objectId);
        }
        break;

      case NotificationType.ExpenseItemApprovalRequested:
      case NotificationType.ExpenseItemApproved:
      case NotificationType.ExpenseItemUnapproved:
      case NotificationType.ApprovedExpenseItemChanged:
        if (this.objectId) {
          await this._openExpenseEdit(this.objectId);
        }
        break;

      case NotificationType.ProjectCanBeFinished:
        if (this.objectId) {
          await this._openProjectWithCloseAction(this.objectId);
        }
        break;

      case NotificationType.NewRfisToApprove:
        await this._openSubmittedRFIs();
        break;

      case NotificationType.NewExpensesToApprove:
        await this._openExpenseDrafts();
        break;

      case NotificationType.BillingDateApproaching:
        if (this.objectId) {
          await this._invoiceProjectByBillingPlan(this.objectId);
        }
        break;
      case NotificationType.BrandCreditAccountCreated:
        if (this.objectId) {
          await this._openCreditAccount(this.objectId);
        }
        break;
      case NotificationType.TimerAutostop:
        if (this.objectId) {
          await this.openTimeTracking(this.objectId);
        }
        break;
      case NotificationType.TaskSpentTimeExceededGivenPercentageOfTotalTime:
        if (this.objectId) {
          await this.openTask(this.objectId);
        }
        break;
      default:
        console.warn(`Unhandled notification. Type: {${this.type}}`);
        break;
    }
  }

  async _openProject(id: string) {
    await Router.push({ pathname: "/projects/detail", query: { id } });
  }

  async _openExpenseEdit(id: string) {
    await Router.push({ pathname: "/expenses/edit", query: { id } });
  }

  async _openProjectWithCloseAction(id: string) {
    await Router.push({
      pathname: "/projects/detail",
      query: { id, action: "close_project" },
    });
  }

  async _openFinishedProjects() {
    await Router.push({
      pathname: "/projects",
      query: {
        where: JSON.stringify({
          AND: [
            {
              column: ProjectsWhereColumn.Status,
              value: ProjectStatusEnum.Finished,
              operator: "EQ",
            },
          ],
        }),
      },
    });
  }

  async _openSubmittedRFIs() {
    await Router.push({
      pathname: "/invoices/for-invoicing",
      query: {
        where: JSON.stringify({
          AND: [
            {
              column: RequestsForInvoicingWhereColumn.Status,
              value: RequestForInvoicingStatusEnum.Submitted,
              operator: "EQ",
            },
          ],
        }),
      },
    });
  }

  async _openExpenseDrafts() {
    await Router.push({
      pathname: "/expenses",
      query: {
        where: JSON.stringify({
          AND: [
            {
              column: ExpensesWhereColumn.Status,
              value: ExpenseStatusEnum.Draft,
              operator: SqlOperator.Eq,
            },
          ],
        }),
      },
    });
  }

  async _openCreditAccount(id: string) {
    await Router.push({ pathname: "/credit-accounts/detail", query: { id } });
  }

  async _invoiceProjectByBillingPlan(projectId: string) {
    const result = await client.mutate<
      InvoiceProjectByCurrentMonthBillingPlanMutation,
      InvoiceProjectByCurrentMonthBillingPlanMutationVariables
    >({
      mutation: InvoiceProjectByCurrentMonthBillingPlanDocument,
      variables: { projectId, type: RequestForInvoicingTypeEnum.Invoice },
    });

    const id = result.data?.invoiceProjectByCurrentMonthBillingPlan?.id;
    if (id) {
      await Router.push({
        pathname: "/invoices/for-invoicing/edit",
        query: { id },
      });
    }
  }

  async openTimeTracking(id: string) {
    await Router.push({
      pathname: "/time-tracking/day",
      query: { [TIME_TRACKING_ID_QUERY_KEY]: id },
    });
  }

  async openTask(id: string) {
    await Router.push({
      query: { [TASK_ID_QUERY_KEY]: id },
    });
  }
}
