import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { MarkOptional } from "ts-essentials";

type SliderArrowProps = {
  onClick: () => void;
  direction: "left" | "right";
} & MarkOptional<IconButtonProps, "aria-label">;

export function SliderArrow({
  onClick,
  direction,
  ...props
}: SliderArrowProps) {
  const placement = direction === "right" ? { right: 6 } : { left: 6 };
  return (
    <IconButton
      pos="absolute"
      zIndex="1"
      top="50%"
      {...placement}
      bg="grey.100"
      transform="translateY(-50%)"
      transition="all 0.4s ease-in-out"
      aria-label={
        direction === "right" ? t`next attachments` : t`prev attachments`
      }
      colorScheme="grey"
      icon={<Icon name={`arrow-narrow-${direction}`} w="5" h="5" />}
      onClick={onClick}
      rounded="full"
      variant="outline"
      {...props}
    />
  );
}
