import { t } from "@lingui/macro";
import {
  SimpleTaskFragment,
  TaskFragmentFragment,
  useCheckTaskRevisionQuery,
  useGetProjectOptionsLazyQuery,
  useTaskForScopedUsersQuery,
  useTaskQuery,
  useUpdateScopedTaskMutation,
  useUpdateTaskMutation,
} from "@src/__generated__/graphql";
import { IOption } from "@src/components/ui-kit";
import { ModalTaskForm } from "@src/components/widgets/Modals/ModalTask";
import { useAutocompletePositionsFromOurWorkBudgetItem } from "@src/components/widgets/Modals/ModalTask/useAutocompletePositionsFromOurWorkBudgetItem";
import { trackEvent } from "@src/services/amplitude";
import { appStore } from "@src/stores/AppStore";
import { useStore } from "@src/utils/hooks";
import mapToOptions from "@src/utils/map-to-options";
import { FormMode } from "@src/utils/types";
import { omit } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { TASK_REVISION_CHECK_INTERVAL } from "../ModalCommunication/ModalCommunication";
import { TaskModel } from "../ModalCommunication/models";
import { ModalTask, ModalTaskProps } from "./ModalTask";

type ModalTaskEditProps = Omit<ModalTaskProps, "onSubmit" | "mode" | "form"> & {
  taskId: TaskModel["id"];
  onUpdate: (data: SimpleTaskFragment | TaskModel) => void;
  mode?: FormMode;
};

export const ModalTaskEdit = observer(function ModalTaskEdit({
  onUpdate,
  taskId,
  mode = FormMode.EDIT,
  ...props
}: ModalTaskEditProps) {
  const { UIStore, authStore } = useStore();
  const [form] = useState(() => new ModalTaskForm(appStore));
  const [task, setTask] = useState<TaskModel | null>(null);
  const [alreadyEdited, setAlreadyEdited] = useState(false);
  const [updateTask, { loading: updateLoading }] = useUpdateTaskMutation();
  const [updateScopedTask, { loading: scopedLoading }] =
    useUpdateScopedTaskMutation();
  const [projectOptions, setProjectOptions] = useState<IOption[]>([]);

  const queryForInternalUser = useTaskQuery({
    variables: { id: taskId },
    skip: !authStore.isInternalUser && !taskId && !props.isOpen,
  });
  const queryForScopedUser = useTaskForScopedUsersQuery({
    variables: { id: taskId },
    skip: authStore.isInternalUser && !taskId && !props.isOpen,
  });

  const { loading, data, refetch } = useMemo(
    () =>
      authStore.isInternalUser ? queryForInternalUser : queryForScopedUser,
    [authStore.isInternalUser, taskId, props.isOpen],
  );

  const { refetch: checkTaskRevision, data: taskRevisionData } =
    useCheckTaskRevisionQuery({
      skip: true,
      variables: { id: taskId },
    });

  const [fetchProjectOptions] = useGetProjectOptionsLazyQuery({
    onCompleted(data) {
      if (data.projectsSimpleMap) {
        setProjectOptions(mapToOptions.projects(data.projectsSimpleMap));
      }
    },
  });

  const { autocompletePositions } =
    useAutocompletePositionsFromOurWorkBudgetItem(form);

  useEffect(() => {
    if (!props.isOpen) return;
    fetchProjectOptions();
  }, [props.isOpen]);

  useEffect(() => {
    if (data?.task) {
      console.log("hereeee");
      setTask(
        new TaskModel(
          data.task as unknown as TaskFragmentFragment,
          "taskPositionStat" in data ? data.taskPositionStat : undefined,
        ),
      );
      form.editorRef.current?.setContent(data.task.description?.body ?? "");
      form.initialBillability = "billable" in data.task && data.task.billable;
    } else {
      setTask(null);
      form.initialBillability = undefined;
      form.editorRef.current?.setContent("");
    }
  }, [data]);

  useEffect(() => {
    if (!task) return;
    if (!task.positions.length) {
      if (!task.ourWorkBudgetItem) return;
      autocompletePositions({
        variables: {
          our_work_budget_item_id: task.ourWorkBudgetItem.id,
        },
      });
    }
    form.fillTaskForm(task);
  }, [task]);

  useEffect(() => {
    if (!task?.id) return;
    if (props.isOpen) {
      checkTaskRevision({ id: task.id });
    }

    const interval = setInterval(() => {
      if (!props.isOpen) return;
      checkTaskRevision({ id: task.id });
    }, TASK_REVISION_CHECK_INTERVAL);

    return () => clearInterval(interval);
  }, [task?.id, props.isOpen]);

  useEffect(() => {
    if (!task?.revision || !taskRevisionData?.task?.revision) return;
    setAlreadyEdited(task.revision !== taskRevisionData.task.revision);
  }, [taskRevisionData, task?.revision]);

  const onSubmitWrapper = async (hasError: boolean) => {
    if (hasError) return false;
    const input = form.serialize();
    if (authStore.isInternalUser) {
      const { data } = await updateTask({
        variables: {
          input: { id: taskId, ...omit(input, "files"), addFiles: input.files },
          propagate_billable:
            (mode === FormMode.EDIT || mode === FormMode.MOVE) &&
            form.billabilityChanged
              ? form.propagate_billable.$
              : false,
        },
      });

      if (!data?.updateTask) return false;

      onUpdate(data.updateTask);
      trackEvent("task", "Updated task");
      UIStore.toast({ title: t`Updated` });
      return true;
    } else {
      const { data } = await updateScopedTask({
        variables: {
          input: {
            id: taskId,
            ...omit(input, "files"),
            addFiles: input.files,
          },
        },
      });

      if (!data?.updateScopedTask) return false;

      // @ts-expect-error
      onUpdate(data.updateScopedTask);
      trackEvent("task", "Updated task");
      UIStore.toast({ title: t`Updated` });
      return true;
    }
  };

  return (
    <ModalTask
      form={form}
      mode={mode}
      taskId={taskId}
      projectId={form.projectId.$}
      projectOptions={projectOptions}
      onSubmitLoading={updateLoading || scopedLoading}
      onSubmit={onSubmitWrapper}
      attachments={task?.files}
      loading={loading || !task}
      alreadyEdited={alreadyEdited}
      refetchTask={() => {
        setTask(null);
        refetch();
      }}
      {...props}
    />
  );
});
