import { Team, User } from "@src/__generated__/urql-graphql";
import { TextEditorDraftStorage } from "@src/components/ui-kit/TextEditor/TextEditorDraftStorage";
import { useUpload } from "@src/components/ui-kit/TextEditor/attachment/useUpload";
import {
  AttachmentNode,
  AttachmentStorage,
} from "@src/components/ui-kit/TextEditor/extensions/AttachmentNode";
import { MentionExtension } from "@src/components/ui-kit/TextEditor/extensions/MentionExtension";
import { Attachment } from "@src/components/ui-kit/TextEditor/extensions/UploadAttachmentPlugin";
import { UserType } from "@src/stores/models/Me";
import { makeHTMLSafe } from "@src/utils/security/makeHTMLSafe";
import { Content, Editor } from "@tiptap/core";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import CodeBlock from "@tiptap/extension-code-block";
import Document from "@tiptap/extension-document";
import Dropcursor from "@tiptap/extension-dropcursor";
import Gapcursor from "@tiptap/extension-gapcursor";
import History from "@tiptap/extension-history";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Strike from "@tiptap/extension-strike";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import { useEffect } from "react";

export type UseTextEditorProps = {
  placeholder?: string;
  autofocus?: boolean;
  outputFormat?: OutputFormat;
  initialValue?: string;
  attachments?: Attachment[];
  isDisabled?: boolean;
  isEditable?: boolean;
  allowedMentions?: UserType[];
  onInit?(editor: Editor): void;
  onChange: (
    value: Content,
    additionalData: {
      mentioned_user_ids: User["id"][];
      mentioned_team_ids: Team["id"][];
      mentioned_assignees: boolean;
      attachment_ids: Attachment["id"][];
      attachments: Attachment[];
    },
  ) => void;
  onFocus?({ editor, event }: { editor: Editor; event: Event }): void;
  onBlur?({ editor, event }: { editor: Editor; event: Event }): void;
  enableDrafts?: boolean;
  draftKey?: string;
};

type OutputFormat = "HTML" | "JSON";

const getFormattedOutput = (editor: Editor, format: OutputFormat) =>
  format === "HTML" ? editor.getHTML() : editor.getJSON();

export function useTextEditor({
  initialValue,
  attachments,
  onInit,
  onChange,
  enableDrafts = false,
  draftKey,
  isDisabled = false,
  outputFormat = "HTML",
  isEditable = true,
  placeholder,
  autofocus = false,
  onBlur,
  onFocus,
  allowedMentions = ['internal'],
  ...props
}: UseTextEditorProps) {
  const onUpload = useUpload();
  const draftStorage =
    enableDrafts && draftKey ? new TextEditorDraftStorage(draftKey) : null;

  const editor = useEditor(
    {
      extensions: [
        Document,
        Paragraph,
        Text,
        BulletList,
        OrderedList,
        ListItem,
        Gapcursor,
        Dropcursor,
        Link.configure({
          protocols: ["smb", "ftp"],
        }),
        CodeBlock,
        Bold,
        Italic,
        Strike,
        Underline,
        History,
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        Placeholder.configure({
          placeholder,
          showOnlyWhenEditable: false,
        }),
        // Temporarily disable YT extension because iframe will not pass BE validation
        // Youtube,
        MentionExtension(allowedMentions),
        AttachmentNode(onUpload),
      ],
      onBeforeCreate({ editor }) {
        editor.setOptions({
          content: initialValue ? makeHTMLSafe(initialValue) : null,
        });
      },
      onCreate: ({ editor }) => {
        onInit?.(editor);
      },
      onUpdate: ({ editor }) => {
        const value = getFormattedOutput(editor, outputFormat);
        const attachments = (editor.storage.attachment as AttachmentStorage)
          .attachments;
        onChange(value, {
          mentioned_user_ids: editor.commands.getUserMentionIds(),
          mentioned_team_ids: editor.commands.getTeamMentionIds(),
          mentioned_assignees: editor.commands.getAssigneesMention(),
          attachment_ids: editor.commands.getAttachmentIds(),
          attachments,
        });
        // INFO: In case some client wants to restore drafts
        // if (!isDisabled && isEditable) {
        //   draftStorage?.set(value.toString(), attachments)
        // }
      },
      onFocus({ editor, event }) {
        onFocus?.({ editor, event });
        // INFO: In case some client wants to restore drafts
        // if (isDisabled || !isEditable) return
        //
        // const draft = draftStorage?.get()
        //
        // if (!draft || draft.value.length <= EMPTY_EDITOR_LENGTH) return
        //
        // editor.storage.attachment.attachments = draft.attachments ?? []
        // editor.commands.setContent(draft.value)
        // onChange(draft.value, {
        //   mentioned_user_ids: editor.commands.getUserMentionIds(),
        //   mentioned_team_ids: editor.commands.getTeamMentionIds(),
        //   mentioned_assignees: editor.commands.getAssigneesMention(),
        //   attachment_ids: editor.commands.getAttachmentIds(),
        //   attachments: editor.storage.attachment.attachments,
        // })
      },
      onBlur({ editor, event }) {
        onBlur?.({ editor, event });
      },
      editable: !isDisabled && isEditable,
      autofocus,
      editorProps: {
        attributes: {
          spellcheck: "false",
        },
      },
    },
    [autofocus, isDisabled],
  );

  useEffect(() => {
    if (!editor) return;

    const storage = editor.storage.attachment as AttachmentStorage;

    if (Array.isArray(attachments)) {
      for (const attachment of attachments) {
        storage.attachments.push(attachment);
      }
    }

    editor.storage.props = props;
  }, [editor?.storage.attachment, props, attachments]);

  return {
    editor,
    draftStorage,
  };
}
