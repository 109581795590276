import { useBoolean } from "@chakra-ui/react";
import * as Sentry from "@sentry/nextjs";
import {
  SubscribeToPushNotificationsDocument,
  SubscribeToPushNotificationsMutation,
} from "@src/__generated__/graphql";
import { VAPID } from "@src/config/constants";
import { client } from "@src/services/apollo-client";
import { useEffect, useState } from "react";

/**
 * urlBase64ToUint8Array
 *
 * @param {string} base64String a public vapid key
 */
function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const usePushNotifications = ({
  onSub,
  onSubFail,
  onDenied,
}: {
  onSub?: () => void;
  onSubFail?: () => void;
  onDenied?: () => void;
}) => {
  const [subscription, setSubscription] = useState<PushSubscription | null>(
    null,
  );
  const [isSubscribed, setIsSubscribed] = useBoolean(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/sw.js")
        .then((serviceWorkerRegistration) => {
          serviceWorkerRegistration?.pushManager
            ?.getSubscription()
            .then((subscription) => {
              subscription ? setIsSubscribed.on() : setIsSubscribed.off();
              setSubscription(subscription);
            });
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
    }
  }, []);

  const requestPermission = () => {
    setLoading(true);
    new Promise<NotificationPermission>((resolve, reject) => {
      const permissionResult = Notification.requestPermission((result) => {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    })
      .then((permissionResult) => {
        if (permissionResult !== "granted") {
          setLoading(false);
          onDenied?.();
          return;
        }
        if (!isSubscribed) {
          subscribeUser();
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const subscribeUser = () => {
    navigator.serviceWorker.ready
      .then((registration) => {
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(VAPID),
        };

        return registration.pushManager.subscribe(subscribeOptions);
      })
      .then((pushSubscription) => {
        storePushSubscription(pushSubscription);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const storePushSubscription = (pushSubscription: PushSubscription) => {
    client
      .mutate<SubscribeToPushNotificationsMutation>({
        mutation: SubscribeToPushNotificationsDocument,
        variables: {
          endpoint: pushSubscription.endpoint,
          keys: {
            auth: pushSubscription.getKey("auth"),
            p256dh: pushSubscription.getKey("p256dh"),
          },
        },
      })
      .then((res) => {
        if (res.data?.subscribeToPushNotifications) {
          setIsSubscribed.on();
          onSub?.();
        }
      })
      .catch(() => {
        setIsSubscribed.off();
        onSubFail?.();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return {
    requestPermission,
    isSubscribed,
    subscription,
    subscriptionPending: loading,
  };
};
