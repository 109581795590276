import { t } from "@lingui/macro";
import { SqlOperator, UsersWhereColumn } from "@src/__generated__/graphql";
import { useUsersSelectOptionsQuery } from "@src/__generated__/urql-graphql";
import { Avatar, Select, SelectProps, SelectRef } from "@src/components/ui-kit";
import { UserType } from "@src/stores/models/Me";
import { Filter, Filters } from "@src/utils/components/filters/models";
import mapToOptions from "@src/utils/map-to-options";
import {
  UserOption,
  toUserOptionsParams,
} from "@src/utils/map-to-options/users";
import { userTypeToScope } from "@src/utils/userTypeToScope";
import { chakraComponents } from "chakra-react-select";
import { forwardRef, useEffect, useState } from "react";

type UserSelectProps = Omit<SelectProps, "options"> & {
  optionModifier?: toUserOptionsParams["modifier"];
  options?: SelectProps["options"];
  priorityIds?: string[];
  onlyPlannable?: boolean;
  userType?: UserType;
};

const customComponents = {
  Option: ({ children, ...props }: any) => (
    <chakraComponents.Option {...props}>
      <Avatar
        name={props.data.label}
        src={props.data.image}
        mr="2"
        colorScheme={props.data.profile?.hex_color}
      />
      {children}
    </chakraComponents.Option>
  ),
  SingleValue: ({ children, ...props }: any) => (
    <chakraComponents.SingleValue {...props}>
      <Avatar
        name={props.data.label}
        colorScheme={props.data.profile?.hex_color}
        src={props.data.image}
        mr="2"
        size="xs"
      />
      {children}
    </chakraComponents.SingleValue>
  ),
};

export const UserSelect = forwardRef<SelectRef, UserSelectProps>(
  function UserSelect(
    {
      optionModifier = (option) => option,
      options: passedOptions,
      priorityIds,
      onlyPlannable,
      userType = "internal",
      ...props
    },
    ref,
  ) {
    const [options, setOptions] = useState<UserOption[]>([]);
    const [{ data, fetching }] = useUsersSelectOptionsQuery({
      variables: {
        onlyPlannable,
        where: new Filters([
          new Filter({
            column: UsersWhereColumn.Scope,
            operator: SqlOperator.Eq,
            options: [],
            value: userTypeToScope(userType)?.toLowerCase(),
          }),
        ]).asGraphQueryParam,
      },
      pause: !!passedOptions,
    });

    useEffect(() => {
      if (!data?.userSimpleMap) return;

      setOptions(
        mapToOptions.users(
          data.userSimpleMap.sort(({ id }) =>
            priorityIds?.includes(id) ? -1 : 1,
          ),
          optionModifier,
        ),
      );
    }, [data]);

    return (
      <Select
        ref={ref}
        isLoading={fetching}
        placeholder={t`Select user`}
        components={customComponents}
        options={passedOptions ?? options}
        {...props}
      />
    );
  },
);
