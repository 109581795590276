import { t } from "@lingui/macro";
import {
  BudgetExportOptionsInput,
  BudgetOrderByColumnEnum,
  BudgetPdfExportOurWorkPositionsEnum,
  BudgetPdfExportOurWorkViewEnum,
  BudgetPdfSettings,
  BudgetTypeEnum,
  BudgetWhereColumn,
  BudgetsExportDocument,
  BudgetsExportQuery,
  BudgetsExportQueryVariables,
  BudgetsFilterOptionsDocument,
  BudgetsFilterOptionsQuery,
  BudgetsFilterOptionsQueryVariables,
  GetBudgetsDocument,
  GetBudgetsQuery,
  GetBudgetsQueryVariables,
  SqlOperator,
} from "@src/__generated__/urql-graphql";
import { IOption, TRow } from "@src/components/ui-kit";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { Filter, Filters } from "@src/utils/components/filters/models";
import { OrderBy } from "@src/utils/components/sorting/OrderBy";
import { PaginationState } from "@src/utils/mobx/states/PaginationState";
import { Prettify } from "@src/utils/types";
import { addDays } from "date-fns";
import { FieldState, FormState } from "formstate";
import { action, computed, makeObservable, observable } from "mobx";
import Router from "next/router";

export const getTypeOptions = (): IOption[] => [
  { label: t`Active assigned`, value: BudgetTypeEnum.ActiveAssigned },
  { label: t`Active unassigned`, value: BudgetTypeEnum.Assigned },
  { label: t`Proposals`, value: BudgetTypeEnum.Unassigned },
  { label: t`Archived`, value: BudgetTypeEnum.Archived },
];

export type TBudget = NonNullable<
  NonNullable<
    NonNullable<GetBudgetsQuery["budgets"]["data"]>[0]["budgets"]
  >[0] & {
    budget_name?: string | null | undefined;
  }
>;

export type TBudgetRow = TBudget & TRow<TBudget>;

type TFormKeys = Prettify<
  keyof NonNullable<
    (typeof BudgetsExportStore)["prototype"]["optionsForm"]
  >["$"]
>;

export class BudgetsExportStore implements BaseStore {
  appStore: AppStore;
  readonly tableKey = "budgets-export-listing";

  @observable
  pagination = new PaginationState(this.tableKey, {
    onChangePagination: () => {
      this.fetchBudgets();
    },
  });

  @observable
  orderBy = new OrderBy<BudgetOrderByColumnEnum>([]);

  @observable fetching = false;
  @observable tableData: TBudgetRow[] = [];
  @observable tableAllIds: string[] = [];
  @observable selectedIds: Set<string> = new Set();
  @observable optionsForm: ReturnType<typeof this.getOptionsForm> | null = null;
  @observable exportingBudgets = false;

  @observable
  where = new Filters<BudgetWhereColumn>(
    [
      new Filter({
        title: t`Brand`,
        column: BudgetWhereColumn.BrandId,
        operator: SqlOperator.In,
        options: [],
      }),
      new Filter({
        title: t`Project`,
        column: BudgetWhereColumn.ProjectId,
        operator: SqlOperator.In,
        options: [],
      }),
      // new Filter({
      //   title: t`Account manager`,
      //   column: BudgetWhereColumn.AccountManager,
      //   operator: SqlOperator.In,
      //   options: [],
      // }),
      new Filter({
        title: t`Type`,
        hidden: true,
        column: BudgetWhereColumn.Type,
        operator: SqlOperator.In,
        options: getTypeOptions(),
      }),
      new Filter({
        title: t`Internally approved`,
        column: BudgetWhereColumn.InternallyApproved,
        operator: SqlOperator.Eq,
        options: [
          { label: t`Yes`, value: "true" },
          { label: t`No`, value: "false" },
        ],
      }),
      new Filter({
        title: t`Client approved`,
        column: BudgetWhereColumn.ClientApproved,
        operator: SqlOperator.Eq,
        options: [
          { label: t`Yes`, value: "true" },
          { label: t`No`, value: "false" },
        ],
      }),
      new Filter({
        title: t`Date`,
        column: BudgetWhereColumn.Date,
        operator: SqlOperator.Between,
        dateRange: true,
        options: [],
      }),
    ],
    { onChange: () => this.fetchBudgets() },
  );

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  @action.bound public async fetchBudgets() {
    Router.replace({
      query: {
        where: this.where.asURLSearchParam.where,
        orderBy: this.orderBy.asURLSearchParam.orderBy,
        first: this.pagination.asURLSearchParam.first,
        page: this.pagination.asURLSearchParam.page,
      },
    });

    this.fetching = true;
    const { data } = await client
      .query<GetBudgetsQuery, GetBudgetsQueryVariables>(GetBudgetsDocument, {
        filters: {
          where: this.where.asWhereParam,
          page: this.pagination.asParams.page,
          first: this.pagination.asParams.first,
          orderBy: this.orderBy.asWhereParam,
        },
      })
      .toPromise();

    this.fetching = false;

    if (!data) return;

    this.setTableData(data);
  }

  @action public async setFilterOptions() {
    const { data } = await client
      .query<
        BudgetsFilterOptionsQuery,
        BudgetsFilterOptionsQueryVariables
      >(BudgetsFilterOptionsDocument, {})
      .toPromise();

    if (!data) return;

    this.where.filtersByColumn.get(BudgetWhereColumn.BrandId)?.setOptions(
      data.brandsSimpleMap.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    );
    this.where.filtersByColumn.get(BudgetWhereColumn.ProjectId)?.setOptions(
      data.projectsSimpleMap.map(({ id, title, code }) => ({
        value: id,
        label: code + " " + title,
      })),
    );
  }

  @action private setTableData(data: GetBudgetsQuery): void {
    this.pagination.setFromPaginatorInfo(data.budgets.paginatorInfo);
    this.tableAllIds = data.budgets.allIds.map((id) => id.toString());

    if (!data.budgets.data) return;
    this.tableData = [];
    const budgetGroupCount = data.budgets.data.length ?? 0;

    for (let i = 0; i < budgetGroupCount; i++) {
      const budgets = data.budgets.data[i].budgets;
      const budgetsCount = budgets?.length ?? 0;

      if (budgetsCount === 0) continue;
      if (!budgets?.[0]) continue;

      const row: TBudgetRow = {
        id: budgets[0].id,
        project_id: budgets[0].project_id,
        price: budgets[0].price,
        version: budgets[0].version,
        project: budgets[0].project,
        brand: budgets[0].brand,
        currency: budgets[0].currency,
        temporaryBrand: budgets[0].temporaryBrand,
        temporaryProject: budgets[0].temporaryProject,
        assignment_date: budgets[0].assignment_date,
        is_active: budgets[0].is_active,
        created_at: budgets[0].created_at,
        updated_at: budgets[0].updated_at,
        internally_approved: budgets[0].internally_approved,
        client_approved: budgets[0].client_approved,
        is_pinned: false,
        status: budgets[0].status,
        temporary_project_id: budgets[0].temporary_project_id,
        budget_name: data.budgets.data[i].budget_name,
        __subRows: [],
      };

      if (budgetsCount > 1) {
        for (let j = 0; j < budgetsCount; j++) {
          const budget = budgets[j];
          if (!budget) continue;

          row.__subRows?.push(budget);
        }
      }

      this.tableData.push(row);
    }
  }

  @action.bound handleSelect(ids: string[]) {
    this.selectedIds = new Set(ids);
  }

  @computed get budgetTypeFilter() {
    return this.where.filtersByColumn.get(BudgetWhereColumn.Type);
  }

  @action getOptionsForm(defaultOptions: BudgetPdfSettings | undefined | null) {
    return new FormState({
      date: new FieldState(new Date()),
      valid_for: new FieldState("30"),
      valid_until: new FieldState(addDays(new Date(), 30)),
      show_disclaimer: new FieldState(defaultOptions?.show_disclaimer),
      signature: new FieldState(defaultOptions?.signature),
      show_summary: new FieldState(defaultOptions?.show_summary),
      show_client_name: new FieldState(defaultOptions?.show_client_name),
      show_client_details: new FieldState(defaultOptions?.show_client_details),
      disclaimer: new FieldState(defaultOptions?.disclaimer),
      our_work_positions: new FieldState(
        defaultOptions?.our_work_positions ??
          BudgetPdfExportOurWorkPositionsEnum.Columns,
      ),
      our_work_view: new FieldState(
        defaultOptions?.our_work_view ??
          BudgetPdfExportOurWorkViewEnum.Detailed,
      ),
      our_work_description: new FieldState(
        defaultOptions?.our_work_description,
      ),
      our_work_vat_in_items: new FieldState(
        defaultOptions?.our_work_vat_in_items,
      ),
      our_work_show_discount: new FieldState(
        defaultOptions?.our_work_show_discount,
      ),
      // TODO return grammatically correct field name from backend
      // eslint-disable-next-line @cspell/spellchecker
      expense_show_commission: new FieldState(
        defaultOptions?.expense_show_commision,
      ),
      expense_show_description: new FieldState(
        defaultOptions?.expense_show_description,
      ),
      expense_show_quantity: new FieldState(
        defaultOptions?.expense_show_quantity,
      ),
      expense_show_discount: new FieldState(
        defaultOptions?.expense_show_discount,
      ),
      expense_show_vat_in_items: new FieldState(
        defaultOptions?.expense_show_vat_in_items,
      ),
      save_for_workspace: new FieldState(false),
      language: new FieldState(defaultOptions?.language ?? "EN"),
    });
  }

  @action.bound serializeOptions(): BudgetExportOptionsInput | undefined {
    if (!this.optionsForm?.$) return undefined;
    const options = new Map<TFormKeys, any>();
    const keysToOmit: Array<TFormKeys> = [
      "valid_for",
      "valid_until",
      "disclaimer",
    ];

    Object.entries(this.optionsForm.$).forEach(([key, value]) => {
      if (keysToOmit.includes(key as TFormKeys)) return;
      options.set(key as TFormKeys, value.$ !== null ? value.$ : false);
    });

    options.set(
      "valid_until",
      addDays(
        this.optionsForm.$.date.$,
        Number(this.optionsForm.$.valid_for.$),
      ),
    );

    if (this.optionsForm.$.show_disclaimer.$) {
      options.set("disclaimer", this.optionsForm.$.disclaimer.$ ?? "");
    }

    if (
      this.optionsForm.$.save_for_workspace.$ &&
      this.appStore.workspaceStore.settings?.budget_pdf_export_settings
    ) {
      this.appStore.workspaceStore.settings.budget_pdf_export_settings =
        Object.fromEntries(options);
    }

    return Object.fromEntries(options);
  }

  @action async exportBudgets(
    ids: string[],
    options: BudgetExportOptionsInput,
  ) {
    this.exportingBudgets = true;
    const { data } = await client
      .query<BudgetsExportQuery, BudgetsExportQueryVariables>(
        BudgetsExportDocument,
        {
          ids: ids,
          options: options,
        },
      )
      .toPromise();
    this.exportingBudgets = false;

    if (!data?.budgetsExport) return;
    window.open(data.budgetsExport, "_blank");
  }
}
