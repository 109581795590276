import { UserScopeEnum } from "@src/__generated__/graphql";
import { UserType } from "@src/stores/models/Me";

export function userTypeToScope(type: UserType) {
	switch (type) {
		case'client':
			return UserScopeEnum.Client;
		case 'partner':
			return UserScopeEnum.Partner;
		default: 
			return null
	}
}