import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { t } from "@lingui/macro";
import { captureEvent } from "@sentry/nextjs";
import {
  SimpleTaskFragment,
  SqlOperator,
  TaskOrderByColumnEnum,
  TaskSystemStatusEnum,
  TaskWhereColumn,
  TasksDocument,
  TasksFilterOptionsQuery,
  TasksForScopedUsersDocument,
  TasksForScopedUsersQuery,
  TasksForScopedUsersQueryVariables,
  TasksQuery,
  TasksQueryVariables,
} from "@src/__generated__/graphql";
import { getBillingCategoryOptions } from "@src/components/modules/projects/list/Store";
import { ProjectCategorySelectBottomExtraContent } from "@src/components/ui-kit/ProjectCategorySelect";
import { TaskModel } from "@src/components/widgets/Modals/ModalCommunication/models";
import { client } from "@src/services/apollo-client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import {
  commonQueryVariables,
  commonSearchParams,
} from "@src/utils/apolloHelpers";
import { Filter, Filters } from "@src/utils/components/filters/models";
import { cannot } from "@src/utils/components/permissions";
import { OrderBy } from "@src/utils/components/sorting/OrderBy";
import mapToOptions from "@src/utils/map-to-options";
import { BooleanState } from "@src/utils/mobx/states/BooleanState";
import { PaginationState } from "@src/utils/mobx/states/PaginationState";
import { action, computed, makeObservable, observable } from "mobx";
import Router from "next/router";
import { TTaskPageVariant } from "./TasksPage";

export type TaskType = NonNullable<
  TasksQuery["tasks"] | TasksForScopedUsersQuery["tasks"]
>["data"][0];
export type InternalTaskType = NonNullable<TasksQuery["tasks"]>["data"][0];
export type ScopedTaskType = NonNullable<
  TasksForScopedUsersQuery["tasks"]
>["data"][0];

export class TasksListingStore implements BaseStore {
  appStore: AppStore;
  @observable tasksMap: Map<TTaskPageVariant, TaskType[]> = new Map();
  @observable paginationMap: Map<TTaskPageVariant, PaginationState> = new Map();

  @observable.ref taskStatuses: TasksFilterOptionsQuery["taskStatuses"] = [];

  @observable subRowsOpen = new BooleanState(true);

  @observable searchTerm = "";
  @observable isFetching = new BooleanState(false);
  @observable requestNumber = 0;

  orderBy = new OrderBy<TaskOrderByColumnEnum>();

  private internalWhere?: Filters<TaskWhereColumn>;

  constructor(appStore: AppStore) {
    this.appStore = appStore;

    makeObservable(this);
  }

  // Lazy-load filters, because it uses `cannot` and `cannot` uses `appStore`, thus creating circular dependency.
  get where() {
    if (!this.internalWhere) {
      this.internalWhere = new Filters<TaskWhereColumn>([
        new Filter({
          column: TaskWhereColumn.BrandId,
          operator: SqlOperator.In,
          title: t`Client/Brand`,
          tagTitle: t`Brand`,
          options: [],
          sectioned: true,
          hasSelectAllOption: true,
        }),
        new Filter({
          column: TaskWhereColumn.ProjectId,
          operator: SqlOperator.In,
          title: t`Project`,
          options: [],
        }),
        new Filter({
          column: TaskWhereColumn.OurWorkBudgetItemId,
          operator: SqlOperator.In,
          title: t`Budget item`,
          options: [],
        }),
        new Filter({
          column: TaskWhereColumn.TeamId,
          operator: SqlOperator.In,
          title: t`Team`,
          options: [],
        }),
        new Filter({
          column: TaskWhereColumn.TaskStatusId,
          operator: SqlOperator.In,
          title: t`Task status`,
          options: [],
        }),
        new Filter({
          column: TaskWhereColumn.TaskPriorityId,
          operator: SqlOperator.In,
          title: t`Priority`,
          options: [],
        }),
        new Filter({
          column: TaskWhereColumn.Deadline,
          operator: SqlOperator.Between,
          title: t`Deadline`,
          dateRange: true,
          options: [],
        }),
        new Filter({
          column: TaskWhereColumn.UserId,
          operator: SqlOperator.In,
          title: t`People`,
          options: [],
        }),
        new Filter({
          column: TaskWhereColumn.TimeTrackingWorkTypeId,
          operator: SqlOperator.In,
          title: t`Position`,
          options: [],
        }),
        new Filter({
          column: TaskWhereColumn.CreatedByUserId,
          operator: SqlOperator.In,
          title: t`Created by`,
          options: [],
        }),
        new Filter({
          column: TaskWhereColumn.QuickFilter,
          operator: SqlOperator.Eq,
          hidden: true,
          options: [],
        }),
        new Filter({
          column: TaskWhereColumn.HideDone,
          operator: SqlOperator.Eq,
          hidden: true,
          options: [],
        }),
        new Filter({
          title: t`Project category`,
          column: TaskWhereColumn.ProjectCategoryId,
          operator: SqlOperator.In,
          hidden: cannot("projectCategory_query"),
          options: [],
          bottomExtraContent: <ProjectCategorySelectBottomExtraContent />,
        }),
        new Filter({
          column: TaskWhereColumn.Billable,
          operator: SqlOperator.Eq,
          title: t`Billing category`,
          options: getBillingCategoryOptions(),
        }),
      ]);
    }
    return this.internalWhere;
  }

  @action.bound resetPagination(variant: TTaskPageVariant = this.getVariant()) {
    this.paginationMap.get(variant)?.setFromPaginatorInfo({
      perPage: 10,
      currentPage: 1,
      total: undefined,
      lastItem: undefined,
    });
    this.tasksMap.clear();
  }

  @computed get searchParams() {
    return commonSearchParams(this);
  }

  @computed get queryParams() {
    return commonQueryVariables(this);
  }

  @computed get isActiveDoneTasksFilter(): boolean {
    const doneTasksStatusId = this.taskStatuses.find(
      (status) => status.system_status === TaskSystemStatusEnum.Done,
    )?.id;

    if (!doneTasksStatusId) return false;

    return this.where.filtersByColumn
      .get(TaskWhereColumn.TaskStatusId)!
      .value.includes(doneTasksStatusId);
  }

  @computed get doneTasksFilter() {
    return this.where.filtersByColumn.get(TaskWhereColumn.HideDone);
  }

  @computed get hideDoneTasks(): boolean {
    return !!this.doneTasksFilter?.value.length;
  }

  @action.bound toggleDoneTasksFilter() {
    if (this.hideDoneTasks) {
      this.doneTasksFilter?.clear();
    } else {
      this.doneTasksFilter?.setValue("true");
    }
  }

  @computed get budgetItemsFilter() {
    return this.where.filtersByColumn.get(TaskWhereColumn.OurWorkBudgetItemId);
  }

  @computed get selectedProjectsFilterValue(): string[] {
    return (
      this.where.filtersByColumn.get(TaskWhereColumn.ProjectId)?.value ?? []
    );
  }

  @action setFilterOptions(data: TasksFilterOptionsQuery) {
    this.taskStatuses = data.taskStatuses;
    this.where.filtersByColumn.get(TaskWhereColumn.TaskStatusId)?.setOptions(
      data.taskStatuses.map((status) => ({
        value: status.id,
        label: status.name,
      })),
    );
    this.where.filtersByColumn
      .get(TaskWhereColumn.TimeTrackingWorkTypeId)
      ?.setOptions(
        data.timeTrackingWorkTypes.map((status) => ({
          value: status.id,
          label: status.title,
        })),
      );
    this.where.filtersByColumn
      .get(TaskWhereColumn.TaskPriorityId)!
      .setOptions(mapToOptions.priorities.toOptions(data.taskPriorities));
    this.where.filtersByColumn
      .get(TaskWhereColumn.UserId)!
      .setOptions(mapToOptions.projectManagers.toOptions(data.userSimpleMap));
    this.where.filtersByColumn
      .get(TaskWhereColumn.CreatedByUserId)!
      .setOptions(mapToOptions.projectManagers.toOptions(data.userSimpleMap));

    this.where.filtersByColumn
      .get(TaskWhereColumn.ProjectId)
      ?.setOptions(mapToOptions.projects(data.projectsSimpleMap));

    this.where.filtersByColumn.get(TaskWhereColumn.BrandId)?.setOptions(
      data.clientsSimpleMap.map((i) => ({
        value: i?.id,
        label: i?.name,
        options: i?.brands.map((brand) => {
          return {
            label: brand.name,
            value: brand.id,
          };
        })!,
      })),
    );

    this.where.filtersByColumn.get(TaskWhereColumn.TeamId)?.setOptions(
      data.teamSimpleMap.map((i) => ({
        value: i?.id,
        label: i?.name,
      })),
    );
  }

  @computed get tasks(): TaskType[] {
    return this.tasksMap.get(this.getVariant()) ?? [];
  }

  @action.bound addTasks(
    tasks: TaskType[],
    variant: TTaskPageVariant = this.getVariant(),
  ) {
    const currentTasks = this.tasksMap.get(variant) ?? [];
    const tasksCount = tasks.length;

    for (let i = 0; i < tasksCount; i++) {
      const task = tasks[i];
      if (!task) continue;
      if (currentTasks.some(({ id }) => id === task.id)) continue;
      currentTasks.push(task);
    }

    this.tasksMap.set(variant, currentTasks);
  }

  @action.bound removeTask(task: TaskModel) {
    this.tasksMap.forEach((tasks, variant) => {
      if (tasks.find(({ id }) => id === task.id)) {
        this.tasksMap.set(
          variant,
          tasks.filter(({ id }) => id !== task.id),
        );
      }
    });
  }

  @action.bound updateTask(task: TaskModel | Partial<SimpleTaskFragment>) {
    this.tasksMap.forEach((tasks, variant) => {
      if (!tasks.some(({ id }) => id === task.id)) return;

      this.tasksMap.set(
        variant,
        tasks.map((currTask) =>
          currTask.id === task.id
            ? {
                ...currTask,
                ...task,
              }
            : currTask,
        ),
      );
    });
  }

  @action.bound getVariant(): TTaskPageVariant {
    let variant: TTaskPageVariant = "highlighted";

    switch (Router.pathname) {
      case "/tasks":
        variant = "all";
        break;
      case "/tasks/highlighted":
        variant = "highlighted";
        break;
      case "/tasks/my":
        variant = "my";
        break;
      case "/tasks/my-created":
        variant = "my";
        break;
    }

    return variant;
  }

  @action async fetchTasks({ variant }: { variant: TTaskPageVariant }) {
    this.isFetching.on();
    const pagination = this.paginationMap.get(variant);
    const query = {
      internal: TasksDocument as TypedDocumentNode<
        TasksQuery,
        TasksQueryVariables
      >,
      client: TasksForScopedUsersDocument as TypedDocumentNode<
        TasksForScopedUsersQuery,
        TasksForScopedUsersQueryVariables
      >,
      partner: TasksForScopedUsersDocument as TypedDocumentNode<
        TasksForScopedUsersQuery,
        TasksForScopedUsersQueryVariables
      >,
    }[this.appStore.authStore.user!.type];

    try {
      const { data } = await client.query({
        query,
        variables: {
          page: pagination?.currentPage ?? 1,
          first: pagination?.perPage,
          filters: {
            search: this.searchTerm,
            where: this.where.asWhereParam,
            orderBy: this.orderBy.asWhereParam,
          },
        },
      });
      if (data.tasks?.data) {
        this.addTasks(data.tasks.data ?? [], variant);
        pagination?.setFromPaginatorInfo(data.tasks.paginatorInfo);
        if (pagination?.hasNextPage) {
          pagination?.inc();
        }
      }
    } catch (error) {
      captureEvent({
        message: "Unable to load tasks",
        extra: { error },
      });
    }
    this.isFetching.off();
  }
}
