import { InputDatePicker } from "@components/ui-kit";
import { t } from "@lingui/macro";
import { useScheduleUserActivationMutation } from "@src/__generated__/graphql";
import { ModalConfirm, ModalConfirmProps } from "@src/components/ui-kit";
import { onError } from "@src/utils/apollo";
import { toApiDate } from "@src/utils/dates";
import { required } from "@src/utils/forms/validators";
import { FieldState } from "formstate";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

type ActivateUserModalProps = Pick<
  ModalConfirmProps,
  "isOpen" | "onClose" | "onConfirm"
> & {
  userId: string;
};

export const ActivateUserModal = observer(function ActivateUserModal({
  userId,
  onConfirm,
  ...props
}: ActivateUserModalProps) {
  const [active_at] = useState(() =>
    new FieldState<Date | undefined>(new Date()).validators(required),
  );

  const [activateUser, { loading }] = useScheduleUserActivationMutation({
    ...onError(),
    onCompleted(result) {
      if (result.scheduleUserActivation) {
        onConfirm();
        props.onClose();
      }
    },
  });
  /**
   * Reset field on close
   */
  useEffect(() => {
    if (props.isOpen === false) {
      active_at.reset(new Date());
    }
  }, [props.isOpen]);

  const onBeforeConfirm = async () => {
    const { hasError } = await active_at.validate();
    if (!hasError) {
      activateUser({
        variables: {
          id: userId,
          date: !active_at.value ? undefined : toApiDate(active_at.value),
        },
      });
    }
  };

  return (
    <ModalConfirm
      isLoading={loading}
      closeOnConfirm={false}
      onConfirm={onBeforeConfirm}
      size="2xl"
      {...props}
    >
      <InputDatePicker
        label={t`Activate at`}
        selected={active_at.value}
        onChange={(val) => active_at.onChange(val ? val.start : undefined)}
      />
    </ModalConfirm>
  );
});
