import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import { Icon } from "@src/components/ui-kit/Icon";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";

export const UserInvitationSentModal = observer(
  function UserInvitationSentModal() {
    const { userInvitationSentModalStore } = useStore();

    const texts = {
      client: {
        title: t`Client access invitation`,
        content: t`Invitation has been sent to the client. They will receive an email with instructions.`,
      },
      internal: {
        title: t`User invitation`,
        content: t`Invitation has been sent to the user. They will receive an email with instructions.`,
      },
      partner: {
        title: t`Partner access invitation`,
        content: t`Invitation has been sent to the partner. They will receive an email with instructions.`,
      },
    }[
      userInvitationSentModalStore.modalState.additionalData?.userType ??
        "internal"
    ];

    return (
      <Modal
        isCentered
        isOpen={userInvitationSentModalStore.modalState.isOpen}
        onClose={userInvitationSentModalStore.modalState.onClose}
        size="lg"
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton zIndex="2" bg="white" />

          <ModalHeader>{texts.title}</ModalHeader>
          <ModalBody>
            <VStack textAlign="center" spacing="3">
              <Icon w="16" h="16" color="green.500" name="check-circle" />
              <Heading color="green.500" fontWeight="semibold">
                <Trans>Invitation Sent</Trans>
              </Heading>
              <Text>{texts.content}</Text>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="violet"
              onClick={userInvitationSentModalStore.modalState.onClose}
            >
              <Trans>Done</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  },
);
