import { UserScopeEnum } from "@src/__generated__/graphql";
import { TaskQuery, User } from "@src/__generated__/graphql";
import { compact } from "lodash";
import { action, makeObservable, observable } from "mobx";

type TTask = TaskQuery["task"];
type TTaskPositionStat = TaskQuery["taskPositionStat"];

export class TaskModel {
  id: TTask["id"];
  name: TTask["name"];
  @observable status: TTask["status"];
  deadline: TTask["deadline"];
  cloudUrl: TTask["cloudUrl"];
  from?: TTask["from"];
  to?: TTask["to"];
  @observable.ref description: TTask["description"];
  @observable assignedUsers;
  @observable positions: TTask["positions"];
  priority: TTask["priority"];
  stats: TTask["stats"];
  ourWorkBudgetItem: TTask["ourWorkBudgetItem"];
  @observable linkedTasks: TTask["linkedTasks"];
  @observable files: TTask["files"];
  revision?: TTask["revision"];
  createdBy: TTask["createdBy"];
  otherPeopleTracked: TTask["otherPeopleTracked"];
  attachments_download_url: TTask["attachments_download_url"];
  otherPeopleTrackedTotal: TTask["otherPeopleTrackedTotal"];
  @observable.ref billable: TTask["billable"];

  @observable partners: TTask["users"];
  @observable clients: TTask["users"];

  userIdsWithEditPermission: User["id"][];
  commentCount: TTask["commentCount"];
  scopedCommentCount: TTask["scopedCommentCount"];

  constructor(task: TTask, positionsStats?: TTaskPositionStat) {
    makeObservable(this);
    this.id = task.id;
    this.name = task.name;
    this.status = task.status;
    this.createdBy = task.createdBy;
    this.to = task.to ?? undefined;
    this.from = task.from ?? undefined;
    this.deadline = task.deadline;
    this.priority = task.priority;
    this.description = task.description;
    this.positions = task.positions;
    this.stats = task.stats;
    this.ourWorkBudgetItem = task.ourWorkBudgetItem;
    this.linkedTasks = task.linkedTasks;
    this.files = task.files;
    this.cloudUrl = task.cloudUrl;
    this.revision = task.revision;
    this.otherPeopleTracked = task.otherPeopleTracked;
    this.attachments_download_url = task.attachments_download_url;
    this.otherPeopleTrackedTotal = task.otherPeopleTrackedTotal;
    this.billable = task.billable;
    this.commentCount = task.commentCount;
    this.scopedCommentCount = task.scopedCommentCount;
    this.clients = task.users.filter((i) => i.scope?.value === UserScopeEnum.Client);
    this.partners = task.users.filter((i) => i.scope?.value === UserScopeEnum.Partner);
    this.userIdsWithEditPermission = compact([
      task.createdBy?.id,
      task.ourWorkBudgetItem?.project.projectManager.id,
      ...(task.ourWorkBudgetItem?.project.otherProjectManagers ?? []).map(
        ({ id }) => id,
      ),
    ]);
    this.assignedUsers = [
    ...(positionsStats ?? []),
    ...[
      ...(this.partners ?? []),
      ...(this.clients ?? []),
    ].map((u) => {
        return {
          user: u,
          spent_time: undefined,
          planned_time: undefined,
          timeTrackingWorkType: undefined,
        } 
      }),
    ];
  }

  get deadlineDate() {
    return this.deadline ? new Date(this.deadline) : undefined;
  }

  @action.bound setDescription(value: TaskModel["description"]) {
    this.description = value;
  }

  @action.bound setFiles(files: TaskModel["files"]) {
    this.files = files;
  }

  @action.bound removeFile(publicId: TaskModel["files"][0]["public_id"]) {
    this.files = this.files.filter((i) => i.public_id !== publicId);
  }

  @action.bound setStatus(value: TaskModel["status"]) {
    this.status = value;
  }
}
