import { t } from "@lingui/macro";
import {
  VatRate,
  WorkspaceAccountingAccount,
  WorkspaceAccountingAccountSettings,
  WorkspaceFinancialSetting,
} from "@src/__generated__/urql-graphql";
import { TToFieldStates } from "@src/utils/forms/ts-utils";
import {
  onlyNumericWithoutDecimal,
  required,
} from "@src/utils/forms/validators";
import { FieldState, FormState } from "formstate";
import { action, makeObservable, observable } from "mobx";
import { v4 as uuidV4 } from "uuid";

type VatRateStateProps = Omit<VatRate, "amount" | "deleted" | "id" | "type"> & {
  amount: string;
};
class VatRateState extends FormState<TToFieldStates<VatRateStateProps>> {
  id?: VatRate["id"];
  internalId: string;
  constructor(
    id?: VatRate["id"],
    fields: VatRateStateProps = {
      amount: "",
      default: false,
    },
  ) {
    super({
      amount: new FieldState(String(fields.amount)).validators(
        required,
        onlyNumericWithoutDecimal,
      ),
      default: new FieldState(fields.default),
    });
    this.id = id;
    this.internalId = uuidV4();
  }
}

type AccountingAccountStateProps = Omit<WorkspaceAccountingAccount, "id">;
class AccountingAccountState extends FormState<
  TToFieldStates<AccountingAccountStateProps>
> {
  state: FinancialSettingsState;
  id?: WorkspaceAccountingAccount["id"];
  internalId: string;
  constructor(
    state: FinancialSettingsState,
    id?: WorkspaceAccountingAccount["id"],
    fields: AccountingAccountStateProps = {
      account_number: "",
      title: "",
    },
  ) {
    super({
      account_number: new FieldState(fields.account_number).validators(
        required,
        (value) =>
          !/^[\d ]+$/.test(value) && t`Only numbers and spaces are allowed`,
        (value) =>
          this.state.form.$.accountingAccounts.$.find(
            (a) => a.$.account_number.$ === value && a !== this,
          ) && t`Account number must be unique`,
      ),
      title: new FieldState(fields.title).validators(required),
    });
    this.state = state;
    this.id = id;
    this.internalId = uuidV4();
  }
}

export class FinancialSettingsState {
  @observable vatRateToDelete: VatRateState | undefined = undefined;
  @observable accountingAccountToDelete: AccountingAccountState | undefined =
    undefined;
  readonly form = new FormState({
    vatRates: new FormState<Array<VatRateState>>([]),
    accountingAccounts: new FormState<Array<AccountingAccountState>>([]),
  });

  constructor() {
    makeObservable(this);
  }

  init(
    financialSettings: WorkspaceFinancialSetting,
    accountingSettings: WorkspaceAccountingAccountSettings,
  ) {
    this.form.$.vatRates = new FormState(
      financialSettings.vat_rates
        .filter((vatRate) => !vatRate.deleted)
        .map((vatRate) => {
          const humanReadableVatRate = {
            ...vatRate,
            amount: String(vatRate.amount / 100),
          };
          return new VatRateState(vatRate.id, humanReadableVatRate);
        }),
    );
    this.form.$.accountingAccounts = new FormState(
      accountingSettings.accounting_accounts.map(
        (account) => new AccountingAccountState(this, account.id, account),
      ),
    );
  }

  addNewVatRate() {
    const newLength = this.form.$.vatRates.$.push(new VatRateState());

    // If we just added our very first VAT rate, then no VAT rate is default currently.
    // We need to make the new one default.
    if (newLength === 1) {
      this.form.$.vatRates.$[0].$.default.onChange(true);
    }
  }

  @action.bound requestVatRateRemoval(vatRate: VatRateState) {
    this.vatRateToDelete = vatRate;
  }

  @action.bound removeVatRate(vatRate: VatRateState) {
    // Make sure to reassign default flag to another VAT rate if the one being deleted was default.
    if (vatRate.$.default.$) {
      this.form.$.vatRates.$.find((v) => v !== vatRate)?.$.default.onChange(
        true,
      );
    }

    this.form.$.vatRates = new FormState(
      this.form.$.vatRates.$.filter((v) => v !== vatRate),
    );

    this.vatRateToDelete = undefined;
  }

  makeDefault(vatRate: VatRateState) {
    this.form.$.vatRates.$.forEach((v) => {
      v.$.default.onChange(v === vatRate);
    });
  }

  addNewAccountingAccount() {
    this.form.$.accountingAccounts.$.push(new AccountingAccountState(this));
  }

  @action.bound requestAccountingAccountRemoval(
    account: AccountingAccountState,
  ) {
    this.accountingAccountToDelete = account;
  }

  @action.bound removeAccountingAccount(account: AccountingAccountState) {
    this.form.$.accountingAccounts = new FormState(
      this.form.$.accountingAccounts.$.filter((a) => a !== account),
    );

    this.accountingAccountToDelete = undefined;
  }
}
