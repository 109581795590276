import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { UserType } from "@src/stores/models/Me";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { UserInvitationSentModal } from "@src/widgets/UserInvitationSentModal";
import { makeObservable, observable } from "mobx";

type UserInvitationSentModalOptions = {
  userType: UserType;
};

export class UserInvitationSentModalStore implements BaseStore, ModalStore {
  appStore: AppStore;
  readonly modalId = "userInvitationSentModal";

  @observable modalState = new DisclosureState<UserInvitationSentModalOptions>({
    onOpen: () => {
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <UserInvitationSentModal />,
      });
    },
    onClose: () => {
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
    },
  });

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }
}
