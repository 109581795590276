import { Checkbox, Grid } from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import {
  CreateTaskInput,
  Task,
  useDuplicateTaskMutation,
  useGetProjectOptionsLazyQuery,
  useTaskQuery,
} from "@src/__generated__/graphql";
import { FormRow, IOption } from "@src/components/ui-kit";
import { trackEvent } from "@src/services/amplitude";
import { appStore } from "@src/stores/AppStore";
import { useStore } from "@src/utils/hooks";
import mapToOptions from "@src/utils/map-to-options";
import { FormMode } from "@src/utils/types";
import { FieldState } from "formstate";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { TaskModel } from "./ModalCommunication/models";
import { ModalTaskForm } from "./ModalTask";
import { ModalTask } from "./ModalTask/ModalTask";
import { useAutocompletePositionsFromOurWorkBudgetItem } from "./ModalTask/useAutocompletePositionsFromOurWorkBudgetItem";

class FormState {
  attachments = new FieldState(false);
  linked_tasks = new FieldState(false);
  comments = new FieldState(false);
}

type DuplicateTaskModalProps = {
  modalId?: string;
  taskId: Task["id"];
};

export const DuplicateTaskModal: FC<DuplicateTaskModalProps> = observer(
  function DuplicateTaskModal({ modalId, taskId }) {
    const { UIStore, tasksListingStore, taskDetailModalStore } = useStore();
    const [attachmentsForm] = useState(() => new FormState());
    const [form] = useState(() => new ModalTaskForm(appStore));
    const [task, setTask] = useState<TaskModel | null>(null);
    const [duplicate, { loading: duplicating }] = useDuplicateTaskMutation();
    const [projectOptions, setProjectOptions] = useState<IOption[]>([]);

    const { autocompletePositions } =
      useAutocompletePositionsFromOurWorkBudgetItem(form);

    const {
      data,
      loading: fetchingTask,
      refetch: fetchTask,
    } = useTaskQuery({
      variables: { id: taskId },
      skip: !taskId,
    });

    const [fetchProjectOptions] = useGetProjectOptionsLazyQuery({
      onCompleted(data) {
        if (data.projectsSimpleMap) {
          setProjectOptions(mapToOptions.projects(data.projectsSimpleMap));
        }
      },
    });

    useEffect(() => {
      fetchProjectOptions();
    }, []);

    useEffect(() => {
      if (data?.task) {
        setTask(new TaskModel(data.task, data.taskPositionStat));
        form.editorRef.current?.setContent(data.task.description?.body ?? "");
      } else {
        setTask(null);
        form.editorRef.current?.setContent("");
      }
    }, [data]);

    useEffect(() => {
      if (!task) return;
      if (!task.ourWorkBudgetItem) return;
      if (!task.positions.length) {
        autocompletePositions({
          variables: {
            our_work_budget_item_id: task.ourWorkBudgetItem.id,
          },
        });
      }
      form.fillTaskForm(task);
      form.name.onChange(task.name + " - " + t`DUPLICATE`);
    }, [task]);

    const onSubmit = async (hasError: boolean) => {
      if (hasError) return false;
      const { data } = await duplicate({
        variables: {
          task_id: taskId,
          task_input: form.serialize() as CreateTaskInput,
          with_attachments: attachmentsForm.attachments.$,
          with_links: attachmentsForm.linked_tasks.$,
          with_comments: attachmentsForm.comments.$,
        },
      });

      if (!data?.duplicateTask) return false;
      onClose();
      trackEvent("task", "Duplicated task");
      tasksListingStore.resetPagination();
      taskDetailModalStore.task.set(new TaskModel(data.duplicateTask, []));
      taskDetailModalStore.taskId.set(data.duplicateTask.id);
      taskDetailModalStore.needsRevision.off();
      UIStore.toast({ title: t`Duplicated` });
      return true;
    };

    const onClose = () => {
      if (!modalId) return;
      UIStore.dialogs.closeModal(modalId);
    };

    return (
      <ModalTask
        isOpen={true}
        onClose={onClose}
        mode={FormMode.DUPLICATE}
        form={form}
        priorityOptions={taskDetailModalStore.priorityOptions}
        statusOptions={taskDetailModalStore.statusOptions}
        onSubmit={onSubmit}
        onSubmitLoading={duplicating}
        taskId={taskId}
        loading={fetchingTask}
        projectId={form.projectId.$}
        projectOptions={projectOptions}
        refetchTask={() => {
          setTask(null);
          fetchTask();
        }}
        extraBottomContent={
          <FormRow title={t`Attachments`}>
            <Grid gap="2" templateColumns="repeat(2, 1fr)">
              <Checkbox
                isChecked={attachmentsForm.comments.value}
                onChange={({ target }) => {
                  attachmentsForm.comments.onChange(target.checked);
                }}
              >
                <Trans>Include task comments</Trans>
              </Checkbox>
              <Checkbox
                isChecked={attachmentsForm.attachments.value}
                onChange={({ target }) => {
                  attachmentsForm.attachments.onChange(target.checked);
                }}
              >
                <Trans>Include task all attachments</Trans>
              </Checkbox>
              <Checkbox
                isChecked={attachmentsForm.linked_tasks.value}
                onChange={({ target }) => {
                  attachmentsForm.linked_tasks.onChange(target.checked);
                }}
              >
                <Trans>Include linked tasks</Trans>
              </Checkbox>
            </Grid>
          </FormRow>
        }
      />
    );
  },
);
