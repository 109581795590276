import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { PaginatorInfo } from "@src/__generated__/urql-graphql";
import {
  SelectInternal,
  SelectProps,
} from "@src/components/ui-kit/Select/Select";
import { IOption } from "@src/components/ui-kit/Select/types";
import {
  GroupBase,
  MenuListProps,
  SelectInstance,
  SetValueAction,
  chakraComponents,
} from "chakra-react-select";
import { uniqueId } from "lodash";
import { observer } from "mobx-react-lite";
import { Ref, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { withAsyncPaginate, wrapMenuList } from "react-select-async-paginate";
const DEFAULT_PER_PAGE = 5;

export interface LargeSelectProps extends SelectProps {
  perPage?: number;
  defaultOptions?: boolean | IOption[];
  ignoreFilterOptions?: boolean;

  loadOptions?: (
    page: number,
    perPage: number,
    searchPhrase: string,
  ) => Promise<{
    data: IOption[];
    paginatorInfo: PaginatorInfo | undefined;
  }>;
}
export type LargeSelectRef = {
  clearOptions: () => void;
  setValue: (val: unknown, action: SetValueAction, option?: unknown) => void;
  focus: () => void;
};

const ChakraAsyncPaginate = withAsyncPaginate(SelectInternal as any);

export const LargeSelect = observer<LargeSelectProps, LargeSelectRef>(
  forwardRef<LargeSelectRef, LargeSelectProps>(function LargeSelect(
    {
      loadOptions,
      perPage = DEFAULT_PER_PAGE,
      defaultOptions = true,
      error,
      label,
      ignoreFilterOptions,
      ...props
    },
    ref,
  ) {
    const selectRef = useRef<SelectInstance>(null);
    const [cacheUniq, setCacheUniq] = useState<string | undefined>(undefined);
    const _loadOptions = async (
      search: string,
      _: number,
      { page }: { page: number },
    ) => {
      const data = await loadOptions?.(page, perPage, search);

      return {
        options: data?.data,
        hasMore: data?.paginatorInfo?.hasMorePages,
        additional: {
          page: page + 1,
        },
      };
    };

    const CustomMenuList = (
      menuProps: MenuListProps<unknown, boolean, GroupBase<unknown>>,
    ) => (
      <chakraComponents.MenuList {...menuProps}>
        {/* eslint-disable-next-line react/prop-types */}
        {!!props.topExtraContent && props.topExtraContent}
        {menuProps.children}
      </chakraComponents.MenuList>
    );

    const MenuList = wrapMenuList(CustomMenuList);

    useImperativeHandle(ref, () => ({
      clearOptions: () => {
        setCacheUniq(uniqueId("cacheUniq"));
      },
      setValue(value, action, option) {
        selectRef.current?.setValue(value, action, option);
      },
      focus: () => {
        selectRef.current?.focus();
      },
    }));

    return (
      <FormControl w="full" isInvalid={!!error}>
        {label && <FormLabel>{label}</FormLabel>}
        <ChakraAsyncPaginate
          {...props}
          ignoreFilterOptions={ignoreFilterOptions}
          key={cacheUniq + defaultOptions.toString()}
          selectRef={
            selectRef as Ref<
              SelectInstance<unknown, boolean, GroupBase<unknown>>
            >
          }
          debounceTimeout={300}
          defaultOptions={defaultOptions}
          // @ts-expect-error
          loadOptions={_loadOptions}
          additional={{
            page: 1,
          }}
          components={{
            MenuList,
          }}
        />
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    );
  }),
);
