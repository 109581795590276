import {
  Box,
  Button,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useBoolean,
} from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import { useAddInternalUserToTaskMutation } from "@src/__generated__/graphql";
import { AddButton, NumberInput } from "@src/components/ui-kit";
import { onError } from "@src/utils/apollo";
import { fieldToInputProps } from "@src/utils/forms/inputHelpers";
import { required } from "@src/utils/forms/validators";
import { useStore } from "@src/utils/hooks";
import { PositionSelect } from "@src/widgets/PositionSelect";
import { UserSelect } from "@src/widgets/UserSelect";
import { hoursToSeconds } from "date-fns";
import { FieldState, FormState } from "formstate";
import { observer } from "mobx-react-lite";
import { useState } from "react";

export const AddInternalUsersPopover = observer(
  function AddInternalUsersPopover() {
    const { taskDetailModalStore, UIStore } = useStore();
    const [isLoading, setIsLoading] = useBoolean(false);
    const [form] = useState(
      new FormState({
        planned_time: new FieldState<string>("0"),
        time_tracking_work_type_id: new FieldState<string | undefined>(
          undefined,
        ).validators(required),
        user_id: new FieldState<string | undefined>(undefined),
      }),
    );

    const [updateTask] = useAddInternalUserToTaskMutation({
      ...onError(),
    });

    const submit = async () => {
      const { hasError } = await form.validate();
      if (hasError) return;

      setIsLoading.on();
      const res = await updateTask({
        variables: {
          id: taskDetailModalStore.taskId.value!,
          positions: [
            ...(taskDetailModalStore.task.value?.positions.map((p) => ({
              time_tracking_work_type_id: p.timeTrackingWorkType.id,
              planned_time: p.planned_time,
              user_id: p.user?.id,
            })) ?? []),
            {
              time_tracking_work_type_id: form.$.time_tracking_work_type_id.$!,
              planned_time: hoursToSeconds(Number(form.$.planned_time.$)),
              user_id: form.$.user_id.$,
            },
          ],
        },
      });

      if (res.data?.updateTask) {
        UIStore.toast({
          title: t`Users updated`,
          status: "success",
        });
        form.reset();
        taskDetailModalStore.fetchTask({ silently: true });
      }

      setIsLoading.off();
    };

    return (
      <Popover>
        <PopoverTrigger>
          <Box>
            <AddButton
              variant="outline"
              rounded="full"
              colorScheme="grey"
              size="sm"
              iconType="normal"
            >
              <Trans>User</Trans>
            </AddButton>
          </Box>
        </PopoverTrigger>

        <PopoverContent>
          <PopoverBody p="5">
            <Heading mb="3" size="xs">
              <Trans>Add User</Trans>
            </Heading>
            <Stack spacing="2">
              <NumberInput
                minValue={0}
                value={form.$.planned_time.value}
                onChange={form.$.planned_time.onChange}
                error={form.$.planned_time.error}
              />
              <PositionSelect
                asPortal
                placeholder={t`Select work type`}
                {...fieldToInputProps(form.$.time_tracking_work_type_id)}
                error={form.$.time_tracking_work_type_id.error}
              />
              <UserSelect
                asPortal
                isClearable
                placeholder={t`Unassigned`}
                {...fieldToInputProps(form.$.user_id)}
                error={form.$.user_id.error}
              />
              <Button isLoading={isLoading} onClick={submit}>
                <Trans>Save</Trans>
              </Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  },
);
