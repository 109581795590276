import { Box, Divider, Flex, Heading, HStack, Stack } from "@chakra-ui/layout";
import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  AddButton,
  ColorPicker,
  FormRow,
  InputDatePicker,
  RemoveButton,
  Select,
  TimeInput,
} from "@components/ui-kit";
import { t, Trans } from "@lingui/macro";
import { PlanningRangeTypeEnum } from "@src/__generated__/graphql";
import { can } from "@src/utils/components/permissions";
import { WrapComponent } from "@src/utils/components/WrapComponent";
import { getWeekdays } from "@src/utils/dates";
import {
  fieldToCheckboxProps,
  fieldToSelectProps,
} from "@src/utils/forms/inputHelpers";
import { useStore } from "@src/utils/hooks";
import { WeekDays } from "@src/utils/types";
import { subDays } from "date-fns";
import { observer } from "mobx-react-lite";
import { Fragment, useMemo } from "react";

export const PlanningUtilization = observer(function PlanningUtilization() {
  const { workspaceStore, editUserModalStore } = useStore();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const formattedWeekDays = useMemo(() => {
    return getWeekdays(workspaceStore.settings?.startOfWeekNumber ?? 0).map(
      (day) => ({
        key: day
          .toLocaleString("en-us", {
            weekday: "long",
          })
          .toLowerCase() as keyof WeekDays<unknown>,
        label: day.toLocaleString("en-us", { weekday: "short" }),
      }),
    );
  }, [workspaceStore.settings?.startOfWeekNumber]);

  const planningRangeTypeOptions = [
    { label: t`Work week (5)`, value: PlanningRangeTypeEnum.Workweek },
    { label: t`Whole week (7)`, value: PlanningRangeTypeEnum.Week },
    { label: t`Custom`, value: PlanningRangeTypeEnum.Custom },
  ];

  return (
    <Stack>
      {editUserModalStore.form.hasAccessToField("plannable") &&
        can("user_updatePlanningData") && (
          <FormRow title={<Trans>Plannable</Trans>}>
            <Checkbox
              {...fieldToCheckboxProps(editUserModalStore.form.plannable)}
            >
              <Trans>Show user in planning</Trans>
            </Checkbox>
          </FormRow>
        )}

      <FormRow title={<Trans>Color</Trans>}>
        <ColorPicker
          color={editUserModalStore.form.hex_color.value}
          onChange={editUserModalStore.form.hex_color.onChange}
        />
      </FormRow>

      {editUserModalStore.form.hasAccessToField("plannable_capacity") &&
        can("user_updatePlanningData") && (
          <Fragment>
            <Heading as="h3" size="sm" mb="2">
              <Trans>Capacity in planning</Trans>
            </Heading>
            <Stack>
              <HStack spacing="3">
                <FormLabel w="130px" mr="0">
                  <Trans>From</Trans>
                </FormLabel>
                <FormLabel w="130px" mr="0">
                  <Trans>To</Trans>
                </FormLabel>
                <FormLabel w="150px" mr="0">
                  <Trans>Planning range</Trans>
                </FormLabel>
                <FormLabel w="100px" mr="0">
                  <Trans>Daily capacity</Trans>
                </FormLabel>
                <FormLabel w="170px" mr="0">
                  <Trans>Show in utilization report</Trans>
                </FormLabel>
              </HStack>
              <Stack spacing="4">
                {editUserModalStore.form.planningRanges.$.map((rate, index) => (
                  <HStack key={rate.$.id.value} pos="relative">
                    <FormControl isInvalid={rate.hasError}>
                      <HStack alignItems="start" spacing="3">
                        <WrapComponent
                          if={index === 0}
                          with={(children) => (
                            <Tooltip label={t`User creation date`}>
                              <div>{children}</div>
                            </Tooltip>
                          )}
                        >
                          <InputDatePicker
                            usePortal
                            w="130px"
                            isDisabled={index === 0}
                            selected={rate.$.valid_from.value}
                            onChange={(val) => {
                              const newValue = val?.start;
                              rate.$.valid_from.onChange(newValue);
                              const prevRate =
                                editUserModalStore.form.planningRanges.$[
                                  index - 1
                                ];
                              if (!prevRate || newValue === undefined) return;
                              prevRate.$.valid_to.onChange(
                                subDays(newValue, 1),
                              );
                              editUserModalStore.form.planningRanges.validate();
                            }}
                            error={rate.$.valid_from.error}
                            placeholder=""
                          />
                        </WrapComponent>
                        <InputDatePicker
                          usePortal
                          w="130px"
                          isDisabled={
                            index !==
                              editUserModalStore.form.planningRanges.$.length -
                                1 && !!rate.$.valid_to.value
                          }
                          selected={rate.$.valid_to.value}
                          onChange={(val) => {
                            rate.$.valid_to.onChange(val?.start);
                            editUserModalStore.form.planningRanges.validate();
                          }}
                          error={rate.$.valid_to.error}
                          placeholder={t`Not specified`}
                        />

                        <Box w="150px">
                          <Select
                            asPortal
                            label={""}
                            {...fieldToSelectProps(
                              rate.$.type,
                              planningRangeTypeOptions,
                            )}
                          />
                        </Box>
                        <Box w="100px">
                          {rate.$.type.value ===
                          PlanningRangeTypeEnum.Custom ? (
                            <Popover
                              isOpen={isOpen}
                              onClose={onClose}
                              onOpen={onOpen}
                            >
                              <PopoverTrigger>
                                <Button h="36px" variant="link">
                                  <Trans>Edit</Trans>
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent w="580px">
                                <PopoverBody>
                                  <HStack mb="3">
                                    {formattedWeekDays.map(({ key, label }) => (
                                      <FormControl key={key}>
                                        <FormLabel>{label}</FormLabel>
                                        <TimeInput
                                          onChange={(val) => {
                                            rate.$.weekly_capacities.onChange({
                                              ...rate.$.weekly_capacities.value,
                                              [key]: val ?? 0,
                                            });
                                          }}
                                          value={
                                            rate.$.weekly_capacities.value[
                                              key
                                            ] ?? 0
                                          }
                                        />
                                      </FormControl>
                                    ))}
                                  </HStack>
                                  <Button onClick={onClose}>
                                    <Trans>Apply</Trans>
                                  </Button>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          ) : (
                            <FormControl>
                              <TimeInput
                                onChange={(val) =>
                                  rate.$.daily_capacity.onChange(val ?? 0)
                                }
                                value={rate.$.daily_capacity.value}
                              />
                            </FormControl>
                          )}
                        </Box>
                        <Flex h="36px" alignItems="center">
                          <Switch {...fieldToCheckboxProps(rate.$.utilising)} />
                        </Flex>
                        {index !== 0 && (
                          <RemoveButton
                            onClick={() =>
                              editUserModalStore.form.removePlanningRange(index)
                            }
                          />
                        )}
                      </HStack>
                      <FormErrorMessage>{rate.formError}</FormErrorMessage>
                    </FormControl>
                    {index !==
                      editUserModalStore.form.planningRanges.$.length - 1 && (
                      <HStack w="700px" position="absolute" bottom="-4" h="3">
                        <Divider />
                        <AddButton
                          size="xs"
                          iconType="normal"
                          alignSelf="center"
                          onClick={() =>
                            editUserModalStore.form.addPlanningRange(
                              index + 1,
                              "split",
                            )
                          }
                        />
                      </HStack>
                    )}
                  </HStack>
                ))}
              </Stack>
              <AddButton
                iconType="normal"
                alignSelf="start"
                onClick={() => editUserModalStore.form.addPlanningRange()}
              >
                <Trans>Add</Trans>
              </AddButton>
            </Stack>
          </Fragment>
        )}
    </Stack>
  );
});
