import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { t } from "@lingui/macro";
import {
  SimpleTaskFragment,
  Task,
  TaskComment,
  TaskDocument,
  TaskForScopedUsersDocument,
  TaskForScopedUsersQuery,
  TaskForScopedUsersQueryVariables,
  TaskQuery,
  TaskQueryVariables,
} from "@src/__generated__/graphql";
import { IOption } from "@src/components/ui-kit";
import { TASK_ID_QUERY_KEY } from "@src/components/widgets/Modals/ModalCommunication/CommunicationModalHeader";
import { ModalCommunication } from "@src/components/widgets/Modals/ModalCommunication/ModalCommunication";
import { TaskModel } from "@src/components/widgets/Modals/ModalCommunication/models";
import { CommentModel } from "@src/components/widgets/Modals/ModalCommunication/models/CommentModel";
import { TaskTabIdEnum } from "@src/constants/tasks";
import { trackEvent } from "@src/services/amplitude";
import { client } from "@src/services/apollo-client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import { UserType } from "@src/stores/models/Me";
import { BooleanState } from "@src/utils/mobx/states/BooleanState";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { PaginationState } from "@src/utils/mobx/states/PaginationState";
import { UnionState } from "@src/utils/mobx/states/UnionState";
import { ValueState } from "@src/utils/mobx/states/ValueState";
import { omit } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import router from "next/router";
import React, { createRef } from "react";

type DescriptionState = "write" | "read";
type ModalCommunicationOptions = {
  id: Task["id"];
  onChange?: (task: TaskModel | SimpleTaskFragment) => void;
  onDelete?: (task: TaskModel) => void;
  onDuplicate?: (task: SimpleTaskFragment) => void;
  onTimeTrackingItemCreated?: (trackedTime: number | undefined) => void;
};

// TODO: rename store file to `TaskDetailModalStore` after Forecasting merge to avoid conflicts
export class ModalCommunicationStore implements BaseStore, ModalStore {
  appStore: AppStore;

  readonly modalId = "taskDetailModal";

  drawerState = new DisclosureState<ModalCommunicationOptions>({
    onOpen: (additionalData) => {
      trackEvent("task", "Opening task drawer");
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <ModalCommunication />,
      });
      if (additionalData?.id) {
        this.taskId.set(additionalData?.id);
        this.descriptionState.set("read");
        this.seenLastComment.off();
        if (!router.query[TASK_ID_QUERY_KEY]) {
          router.replace(
            {
              pathname: router.pathname,
              query: {
                ...router.query,
                [TASK_ID_QUERY_KEY]: additionalData?.id,
              },
            },
            undefined,
            { shallow: true },
          );
        }
      }
    },
    onClose: () => {
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
      router.replace(
        {
          pathname: router.pathname,
          query: omit(router.query, TASK_ID_QUERY_KEY),
        },
        undefined,
        { shallow: true },
      );
    },
  });
  task = new ValueState<TaskModel | null>(null);
  taskStatuses = new ValueState<TaskQuery["taskStatuses"]>([]);
  taskPriorities = new ValueState<TaskQuery["taskPriorities"]>([]);
  activeTabId = new ValueState<TaskTabIdEnum>(TaskTabIdEnum.Description);
  internalComments = new ValueState<CommentModel[]>([]);
  clientComments = new ValueState<CommentModel[]>([]);
  partnerComments = new ValueState<CommentModel[]>([]);
  internalCommentsPagination = new PaginationState("internal-comments");
  partnerCommentsPagination = new PaginationState("partner-comments");
  clientCommentsPagination = new PaginationState("client-comments");
  taskId = new ValueState<string | undefined>(undefined);
  attachmentsCollapsed = new BooleanState();
  isLoading = new BooleanState();
  isLoadingDuplicate = new BooleanState();
  @observable priorityOptions: IOption[] = [];

  deleteModal = new DisclosureState();
  editTaskModal = new DisclosureState<{ moveMode: boolean }>();

  firstCommentRef: React.RefObject<HTMLDivElement> = createRef();
  commentsContainerRef: React.RefObject<HTMLDivElement> = createRef();
  commentsLoaderRef: React.RefObject<HTMLDivElement> = createRef();
  drawerBodyRef = createRef<HTMLDivElement>();

  @observable newlyCreatedCommentID?: TaskComment["id"] = undefined;
  seenLastComment = new BooleanState();
  needsRevision = new BooleanState();

  @observable descriptionLoading = new BooleanState(false);

  descriptionState = new UnionState<DescriptionState>("read");
  @observable descriptionChanged = false;
  @observable commentChanged = false;

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  get COMMUNICATION_MAP(): Record<
    UserType,
    {
      comments: ValueState<CommentModel[]>;
      pagination: PaginationState;
      allowedMentions: UserType[];
    }
  > {
    return {
      internal: {
        comments: this.internalComments,
        pagination: this.internalCommentsPagination,
        allowedMentions: ["internal"],
      },
      client: {
        comments: this.clientComments,
        pagination: this.clientCommentsPagination,
        allowedMentions: ["internal", "client"],
      },
      partner: {
        comments: this.partnerComments,
        pagination: this.partnerCommentsPagination,
        allowedMentions: ["internal", "partner"],
      },
    };
  }

  @computed get statusOptions() {
    return this.taskStatuses.value.map((status) => ({
      label: status.name,
      value: status.id,
    }));
  }

  @action async fetchTask(
    { silently }: { silently?: boolean } = { silently: false },
  ) {
    if (!this.taskId.value) return;
    if (!silently) this.isLoading.on();
    const query = {
      internal: TaskDocument as TypedDocumentNode<
        TaskQuery,
        TaskQueryVariables
      >,
      client: TaskForScopedUsersDocument as TypedDocumentNode<
        TaskForScopedUsersQuery,
        TaskForScopedUsersQueryVariables
      >,
      partner: TaskForScopedUsersDocument as TypedDocumentNode<
        TaskForScopedUsersQuery,
        TaskForScopedUsersQueryVariables
      >,
    }[this.appStore.authStore.user!.type];

    try {
      const { data } = await client.query({
        query,
        variables: {
          id: this.taskId.value,
        },
      });

      if (data.task) {
        this.task.set(
          new TaskModel(
            data.task,
            "taskPositionStat" in data ? data.taskPositionStat : undefined,
          ),
        );
        this.taskStatuses.set(data.taskStatuses);
        this.taskPriorities.set(data.taskPriorities);
      }
    } catch (error) {
      if (!silently) {
        this.appStore.UIStore.toast({
          title: t`Unable to get task. Please try again later.`,
          status: "error",
        });
      }
    }
    if (!silently) this.isLoading.off();
  }
}
