import { UserScopeEnum } from "@src/__generated__/graphql";

export function userScopeToType(scope?: UserScopeEnum) {
	switch (scope) {
		case UserScopeEnum.Client:
			return 'client';
		case UserScopeEnum.Partner:
			return 'partner';
		default: 
			return 'internal'
	}
}