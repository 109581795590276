import { IconButton, Tooltip, useBoolean } from "@chakra-ui/react";
import { t } from "@lingui/macro";
import {
  Project,
  Task,
  UpdateProjectScopesDocument,
  UpdateProjectScopesMutation,
  UpdateProjectScopesMutationVariables,
  UpdateTaskScopesDocument,
  UpdateTaskScopesMutation,
  UpdateTaskScopesMutationVariables,
  UserScopeEnum,
} from "@src/__generated__/graphql";
import { Icon } from "@src/components/ui-kit/Icon";
import { client } from "@src/services/apollo-client";
import { IconNames } from "@src/utils/types/iconNames";
import { FunctionComponent, useState } from "react";

export type ExternalAccessIconProps =
  | {
      type: "task";
      id: Task["id"];
      scopes: Task["scopes"];
    }
  | {
      type: "project";
      id: Project["id"];
      scopes: Project["scopes"];
    };

const DATA_RESPONSE_MAP = {
  task: "updateTask",
  project: "updateProject",
} as const;

const ACCESS_SCOPE_MAP = {
  task: [UserScopeEnum.Client, UserScopeEnum.Partner],
  project: [UserScopeEnum.Client],
} as const;

export const ExternalAccessIcon: FunctionComponent<ExternalAccessIconProps> = ({
  type,
  id,
  scopes,
}) => {
  const [accessState, setAccessState] = useState<"enabled" | "disabled">(
    scopes.length ? "enabled" : "disabled",
  );
  const [isLoading, setIsLoading] = useBoolean(false);

  const updateEntity = async (id: string, scopes: UserScopeEnum[]) => {
    const mutation = {
      task: UpdateTaskScopesDocument,
      project: UpdateProjectScopesDocument,
    }[type];
    return client.mutate<
      UpdateTaskScopesMutation | UpdateProjectScopesMutation,
      UpdateTaskScopesMutationVariables | UpdateProjectScopesMutationVariables
    >({
      mutation,
      variables: {
        input: {
          id,
          scopes,
        },
      },
    });
  };

  const actions: Record<
    typeof accessState,
    {
      tooltip: string;
      icon: IconNames;
      onClick: () => void;
    }
  > = {
    enabled: {
      tooltip: {
        task: t`Task is accessible for clients and partners with access to this task. Toggle to set private.`,
        project: t`Project is accessible for clients with access to this project. Toggle to set private.`,
      }[type],
      icon: "users-group",
      onClick: async () => {
        setIsLoading.on();
        const res = await updateEntity(id, []);
        setIsLoading.off();

        if ((res.data as any)?.[DATA_RESPONSE_MAP[type]].id) {
          setAccessState("disabled");
        }
      },
    },
    disabled: {
      tooltip: {
        task: t`Task is private and only accessible to internal agency users. Toggle to make accessible for clients and partners.`,
        project: t`Project is private and only accessible to internal agency users. Toggle to make accessible for clients.`,
      }[type],
      icon: "users-group-locked",
      onClick: async () => {
        setIsLoading.on();
        const res = await updateEntity(id, [...ACCESS_SCOPE_MAP[type]]);
        setIsLoading.off();

        if ((res.data as any)?.[DATA_RESPONSE_MAP[type]].id) {
          setAccessState("enabled");
        }
      },
    },
  };

  const currentAction = actions[accessState];

  return (
    <Tooltip label={currentAction.tooltip}>
      <IconButton
        color="grey.500"
        opacity={0}
        _hover={{ color: "grey.900" }}
        _groupHover={{ opacity: 1 }}
        transition="opacity 0.2s"
        aria-label={currentAction.tooltip}
        colorScheme="gray"
        icon={<Icon name={currentAction.icon} w="5" h="5" />}
        isLoading={isLoading}
        onClick={(e) => {
          e.stopPropagation();
          currentAction.onClick();
        }}
        size="sm"
        variant="plain"
      />
    </Tooltip>
  );
};
