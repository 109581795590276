import {
  Button,
  HStack,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  StackProps,
} from "@chakra-ui/react";
import { t } from "@lingui/macro";
import {
  MultiMonthCalendar,
  MultiMonthCalendarProps,
} from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import { DateRange } from "@src/utils/types";
import { CalendarValues } from "@uselessdev/datepicker";
import {
  addDays,
  addMonths,
  addWeeks,
  differenceInDays,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getWeek,
  isSameDay,
  isSameYear,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";
import { action, computed, makeObservable, observable } from "mobx";
import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { CalendarActionsProps } from "../ui-kit/datePicker/CalendarActions";

type PeriodPickerProps = {
  value: DateRange;
  onChange: (value: DateRange) => void;
} & Omit<StackProps, "onChange"> &
  Pick<CalendarActionsProps, "customActions"> &
  Pick<MultiMonthCalendarProps, "topExtraContent">;

type PeriodType = "month" | "week" | "range";

class PeriodState {
  @observable.ref period: DateRange;
  onChange: (value: DateRange) => void;
  constructor(period: DateRange, onChange: (value: DateRange) => void) {
    makeObservable(this);
    this.period = period;
    this.onChange = onChange;
  }

  @computed get currentPeriodType(): PeriodType {
    if (
      isSameDay(this.period.start, startOfMonth(this.period.start)) &&
      isSameDay(this.period.end, endOfMonth(this.period.end)) &&
      Math.abs(differenceInDays(this.period.start, this.period.end)) <= 31
    ) {
      return "month";
    } else if (
      isSameDay(this.period.start, startOfWeek(this.period.start)) &&
      isSameDay(this.period.end, endOfWeek(this.period.end))
    ) {
      return "week";
    } else {
      return "range";
    }
  }

  @computed get currentPeriodLabel() {
    switch (this.currentPeriodType) {
      case "month":
        return format(this.period.start, "MMMM yyyy");
      case "week":
        return t`Week ${getWeek(this.period.start)} (${format(this.period.start, "d.M")} - ${format(
          this.period.end,
          "d.M.yyyy",
        )})`;
      case "range":
        if (isSameYear(this.period.start, this.period.end)) {
          return `${format(this.period.start, "d.M")} - ${format(this.period.end, "d.M.yyyy")}`;
        }
        return `${format(this.period.start, "d.M.yyyy")} - ${format(this.period.end, "d.M.yyyy")}`;
    }
  }

  @action.bound goToNextPeriod() {
    switch (this.currentPeriodType) {
      case "month":
        this.period = {
          start: startOfMonth(addMonths(this.period.start, 1)),
          end: endOfMonth(addMonths(this.period.end, 1)),
        };
        this.onChange(this.period);
        break;
      case "week":
        this.period = {
          start: startOfWeek(addWeeks(this.period.start, 1)),
          end: endOfWeek(addWeeks(this.period.end, 1)),
        };
        this.onChange(this.period);
        break;
      case "range":
        const daysCount = eachDayOfInterval({
          start: this.period.start,
          end: this.period.end,
        }).length;
        this.period = {
          start: addDays(this.period.start, daysCount),
          end: addDays(this.period.end, daysCount),
        };
        this.onChange(this.period);
        break;
    }
  }
  @action.bound goToPrevPeriod() {
    switch (this.currentPeriodType) {
      case "month":
        this.period = {
          start: startOfMonth(subMonths(this.period.start, 1)),
          end: endOfMonth(subMonths(this.period.end, 1)),
        };
        this.onChange(this.period);
        break;
      case "week":
        this.period = {
          start: startOfWeek(subWeeks(this.period.start, 1)),
          end: endOfWeek(subWeeks(this.period.end, 1)),
        };
        this.onChange(this.period);
        break;
      case "range":
        const daysCount = eachDayOfInterval({
          start: this.period.start,
          end: this.period.end,
        }).length;
        this.period = {
          start: subDays(this.period.start, daysCount),
          end: subDays(this.period.end, daysCount),
        };
        this.onChange(this.period);
        break;
    }
  }
}

export const PeriodPicker: FC<PeriodPickerProps> = observer(
  function PeriodPicker({
    value,
    onChange,
    customActions,
    topExtraContent,
    ...props
  }) {
    const [state] = useState(() => new PeriodState(value, onChange));

    return (
      <HStack spacing="0" {...props}>
        <IconButton
          aria-label={t`Prev period`}
          colorScheme="grey"
          icon={<Icon name="chevron-left" />}
          onClick={state.goToPrevPeriod}
          roundedRight="none"
          variant="outline"
        />
        <Popover placement="bottom-start">
          <PopoverTrigger>
            <Button
              borderX="none"
              colorScheme="grey"
              rightIcon={<Icon name="chevron-down" />}
              rounded="none"
              variant="outline"
            >
              {state.currentPeriodLabel}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent w="auto">
              <MultiMonthCalendar
                topExtraContent={topExtraContent}
                customActions={customActions}
                value={{ start: value.start, end: value.end }}
                onSelectDate={(date) => {
                  if (!date) return;
                  state.period = {
                    start: (date as unknown as CalendarValues).start as Date,
                    end: (date as unknown as CalendarValues).end as Date,
                  };
                  onChange(state.period);
                }}
              />
            </PopoverContent>
          </Portal>
        </Popover>
        <IconButton
          aria-label={t`Next period`}
          colorScheme="grey"
          icon={<Icon name="chevron-right" />}
          onClick={state.goToNextPeriod}
          roundedLeft="none"
          variant="outline"
        />
      </HStack>
    );
  },
);
