import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseModalProps,
} from "@chakra-ui/react";
import {
  FormRow,
  InputDatePicker,
  Select,
  TimeInput,
} from "@components/ui-kit";
import { Trans, t } from "@lingui/macro";
import { useTimeTrackingOptionsForProjectQuery } from "@src/__generated__/graphql";
import {
  RunningTimerDocument,
  RunningTimerQuery,
  RunningTimerQueryVariables,
  useTimeTrackingProjectsQuery,
} from "@src/__generated__/urql-graphql";
import Title from "@src/components/modules/time-tracking/TimeSheet/components/ModalEntry/Title";
import { BillableButton } from "@src/components/ui-kit/BillableButton";
import {
  TextEditor,
  TextEditorRef,
} from "@src/components/ui-kit/TextEditor/TextEditor";
import { useSetEditorContent } from "@src/components/ui-kit/TextEditor/hooks/useSetContent";
import { trackEvent } from "@src/services/amplitude";
import { client } from "@src/services/client";
import { onError } from "@src/utils/apollo";
import { toApiDate } from "@src/utils/dates";
import {
  fieldToInputProps,
  fieldToSelectProps,
} from "@src/utils/forms/inputHelpers";
import { useStore } from "@src/utils/hooks";
import map from "@src/utils/map-to-options";
import { isTrackingEnabled } from "@src/utils/time-tracking";
import { AllocationItemsSelect } from "@src/widgets/AllocationItemsSelect/AllocationItemsSelect";
import { TaskOption, TaskSelect } from "@src/widgets/TaskSelect/TaskSelect";
import { groupBy, map as lodashMap } from "lodash";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useRef } from "react";
import { EntryForm } from "../../form";
import { Store } from "../../store";
import DeleteButton from "./DeleteButton";
import SaveButton from "./SaveButton";
import SaveRowButton from "./SaveRowButton";
import StartButton from "./StartButton";

interface ModalEntryProps extends UseModalProps {
  userId: string;
  item?: Store["entries"][0];
  forDate?: Date;
  onSaveCompleted?: () => void;
  newWeekEntryMode?: boolean;
  withAllocationSelect?: boolean;
  redirectOnClose?: boolean;
}

const ModalEntry = ({
  userId,
  item,
  forDate,
  onSaveCompleted,
  newWeekEntryMode,
  withAllocationSelect,
  redirectOnClose = true,
  ...modalProps
}: ModalEntryProps) => {
  const { timeTrackingStore, authStore } = useStore();
  const router = useRouter();
  const editorRef = useRef<TextEditorRef>(null);
  const editMode = !!item?.id;
  const form = useMemo(() => new EntryForm(), []);

  useEffect(() => {
    if (!modalProps.isOpen) return;

    form.reset();
    if (item) {
      form.id.onChange(item.id);
      form.notes.onChange(item.note ?? "");
      if (item.project?.id) {
        form.project_id.onChange(item.project.id);
      }
      if (item.task?.ourWorkBudgetItem) {
        form.budget_item_id.onChange(item.task.ourWorkBudgetItem.id);
      }
      form.task_id.onChange(item.task.id);
      form.time_tracking_work_type_id.onChange(item.timeTrackingWorkType.id);
      form.time.onChange(item.tracked_time);
      form.tracked_for_date.onChange(new Date(item.tracked_for_date));
      form.capacity_allocation_item_id.onChange(
        item.capacityAllocationItem?.id,
      );
      form.billable.onChange(item.billable);
    } else {
      forDate && form.tracked_for_date.onChange(forDate);
    }
  }, [modalProps.isOpen]);

  const [projectsReq] = useTimeTrackingProjectsQuery({
    pause: !modalProps.isOpen,
  });

  const projectsOptions = useMemo(() => {
    if (!projectsReq?.data?.projectsSimpleMap) return [];
    const options = map.projects(projectsReq.data.projectsSimpleMap);
    // Preset
    if (!form.project_id.value && options.length === 1) {
      form.project_id.onChange(options[0].value);
    }
    return options;
  }, [projectsReq.data]);

  const forProjectReq = useTimeTrackingOptionsForProjectQuery({
    skip: !modalProps.isOpen,
    variables: {
      id: form.project_id.value,
      date: toApiDate(form.tracked_for_date.value),
    },
    ...onError(),
  });

  const [ourWorkOptions, workTypeOptions] = useMemo(() => {
    const forProject = forProjectReq.data?.timeTrackingOptionsForProject;
    if (!forProject) return [[], []];

    const groupedOurWork = groupBy(forProject.ourWork, "category.name");
    const groupedOurWorkOptions = lodashMap(
      groupedOurWork,
      (item, category) => ({
        label: category,
        options: item
          .filter(({ timeTrackingSettings }) => {
            const {
              tracking_enabled,
              tracking_enabled_from: from,
              tracking_enabled_to: to,
            } = timeTrackingSettings;

            return isTrackingEnabled(
              {
                tracking_enabled: tracking_enabled,
                tracking_enabled_from: from ? new Date(from) : undefined,
                tracking_enabled_to: to ? new Date(to) : undefined,
              },
              forDate,
            );
          })
          .map(({ id, title }) => ({
            label: title,
            value: id,
          })),
      }),
    );

    const workTypeOptions = map.toOptions(
      forProject.availableTimeTrackingWorkTypes,
      "title",
    );

    return [groupedOurWorkOptions, workTypeOptions];
  }, [forProjectReq.data]);

  // preset ourWork
  useEffect(() => {
    if (!form.budget_item_id.value && ourWorkOptions.length === 1) {
      form.budget_item_id.onChange(ourWorkOptions[0]?.options[0]?.value);
    }
  }, [form.budget_item_id.value, ourWorkOptions]);

  // preset workType
  useEffect(() => {
    if (
      !form.time_tracking_work_type_id.value &&
      workTypeOptions.length === 1
    ) {
      form.time_tracking_work_type_id.onChange(workTypeOptions[0].value);
    }
  }, [form.time_tracking_work_type_id.value, workTypeOptions]);

  // preset workType by task
  useEffect(() => {
    if (
      form.time_tracking_work_type_id.value ||
      !form.task_id.value ||
      workTypeOptions.length === 0
    )
      return;

    const myTaskPositions =
      forProjectReq.data?.timeTrackingOptionsForProject.ourWork
        .flatMap((i) => i.tasks)
        .find((i) => i.id === form.task_id.value)
        ?.positions.filter((i) => i.user?.id === userId);
    if (myTaskPositions && myTaskPositions.length === 1) {
      form.time_tracking_work_type_id.onChange(
        myTaskPositions[0].timeTrackingWorkType.id,
      );
      return;
    }

    if (
      workTypeOptions.find(
        (i) => i.value === authStore.user!.profile?.defaultWorktype.id,
      )
    ) {
      form.time_tracking_work_type_id.onChange(
        authStore.user!.profile!.defaultWorktype.id,
      );
    }
  }, [form.task_id.value, forProjectReq.data?.timeTrackingOptionsForProject]);

  useSetEditorContent({ ref: editorRef?.current, note: form.notes.value });

  const onSelectProject = (val: string) => {
    form.project_id.onChange(val);
    resetOptionsForProject();
  };

  const onSelectBudgetItem = (val: string | undefined) => {
    form.budget_item_id.onChange(val || "");
    form.task_id.reset();
  };

  const resetOptionsForProject = () => {
    form.budget_item_id.reset();
    form.task_id.reset();
    form.time_tracking_work_type_id.reset();
  };

  const _onAction = async () => {
    onSaveCompleted?.();
    handleModalClose();

    const { data } = await client
      .query<RunningTimerQuery, RunningTimerQueryVariables>(
        RunningTimerDocument,
        {
          user_id: authStore.user?.id ?? "",
        },
      )
      .toPromise();

    if (!data) return;
    trackEvent(
      "time-entry",
      newWeekEntryMode ? "New week row created" : "New time entry created",
    );

    if (!data.runningTimer) return;
    timeTrackingStore.setRunningEntry({
      ...data,
      runningTimer: {
        ...data.runningTimer,
        // @ts-expect-error
        timeTrackingItem: {
          ...data.runningTimer?.timeTrackingItem,
          tracked_for_date: toApiDate(form.tracked_for_date.value),
        },
      },
    });
  };

  const onTaskSelectChange = (
    ...[id, taskOption]: [value: string, option: Partial<TaskOption>]
  ) => {
    form.task_id.onChange(id);
    if (taskOption.label) {
      form.selectedTask = {
        value: id,
        label: taskOption.label,
      };
    }
    taskOption.budgetItemId &&
      form.budget_item_id.onChange(taskOption.budgetItemId);
    taskOption.projectId && form.project_id.onChange(taskOption.projectId);
    form.billable.onChange(taskOption?.billable ?? false);

    runInAction(() => {
      form.timeTrackingSettings = taskOption.timeTrackingSettings;
    });
  };

  const handleModalClose = () => {
    modalProps.onClose();
    if (redirectOnClose) {
      router.replace("/time-tracking/day");
    }
    form.reset();
  };

  return (
    <Modal
      isCentered
      isOpen={modalProps.isOpen}
      onClose={handleModalClose}
      size="2xl"
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pr="12">
          <Title
            title={
              newWeekEntryMode
                ? t`Add new row`
                : editMode
                  ? t`Edit time entry`
                  : t`Add new time entry`
            }
            date={form.tracked_for_date.value}
            weekMode={newWeekEntryMode}
          />
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <FormRow title={t`Task`}>
            <TaskSelect
              asPortal
              options={[]}
              disableDoneTasks
              placeholder={t`Select task`}
              onChange={(value, option) =>
                onTaskSelectChange(
                  value as string,
                  option as Partial<TaskOption>,
                )
              }
              value={form.task_id.value}
              projectFilter={
                form.project_id.value ? [form.project_id.value] : undefined
              }
              budgetItemFilter={
                form.budget_item_id.value
                  ? [form.budget_item_id.value]
                  : undefined
              }
              defaultOptions={
                form.selectedTask ? [form.selectedTask] : undefined
              }
              error={form.task_id.error}
              hideNonTrackableTasks
              hideNonTrackableTasksForDate={forDate}
            />
          </FormRow>
          <FormRow title={t`Project`}>
            <Select
              isClearable
              autoFocus={form.project_id.value === ""}
              placeholder={t`Select project`}
              isLoading={projectsReq.fetching}
              value={form.project_id.value}
              error={form.project_id.error}
              options={projectsOptions}
              onChange={onSelectProject}
              asPortal
            />
          </FormRow>
          <FormRow title={t`Budget item`}>
            <Select
              isClearable
              placeholder={t`Select budget item`}
              isLoading={forProjectReq.loading}
              value={form.budget_item_id.value}
              error={form.budget_item_id.error}
              onChange={onSelectBudgetItem}
              options={ourWorkOptions}
              asPortal
            />
          </FormRow>
          <FormRow title={t`Position`}>
            <Select
              placeholder={t`Select position`}
              isLoading={forProjectReq.loading}
              {...fieldToSelectProps(
                form.time_tracking_work_type_id,
                workTypeOptions,
              )}
              asPortal
            />
          </FormRow>
          {withAllocationSelect && (
            <FormRow title={t`Capacity allocation`}>
              <AllocationItemsSelect
                value={form.capacity_allocation_item_id.value}
                onChange={form.capacity_allocation_item_id.onChange}
                userId={userId}
                taskId={form.task_id.value}
                date={form.tracked_for_date.value}
                asPortal
              />
            </FormRow>
          )}
          {!newWeekEntryMode && (
            <FormRow
              alignItems="start"
              title={
                <HStack spacing="2">
                  <BillableButton field={form.billable} size="lg" />
                  <TimeInput
                    height="16"
                    fontSize="3xl"
                    value={form.time.value}
                    onChange={(val) => form.time.onChange(val)}
                  />
                </HStack>
              }
            >
              <TextEditor
                ref={editorRef}
                attachments={[]}
                placeholder={t`Notes`}
                h="64px"
                overflow="hidden"
                maxH="64px"
                px="3"
                minHeight="60px"
                borderWidth="thin"
                initialValue={form.notes.value}
                borderColor="grey.200"
                borderRadius="base"
                transition="border-color 150ms ease-in"
                _focusWithin={{
                  borderColor: "purple.500",
                }}
                {...fieldToInputProps(form.notes)}
              />
            </FormRow>
          )}
          {!newWeekEntryMode && (
            <Box mt="4">
              <FormRow title={t`Date`}>
                <InputDatePicker
                  onChange={(val) => {
                    if (!val) return;
                    form.tracked_for_date.onChange(val.start);
                  }}
                  selected={form.tracked_for_date.value}
                  error={form.tracked_for_date.error}
                />
              </FormRow>
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <HStack justify="end" w="full" spacing="3">
            {newWeekEntryMode ? (
              <SaveRowButton userId={userId} form={form} onSave={_onAction} />
            ) : (
              <React.Fragment>
                {editMode && (
                  <Box mr="auto">
                    <DeleteButton id={form.id.value} onDelete={_onAction} />
                  </Box>
                )}

                <Button
                  colorScheme="grey"
                  onClick={handleModalClose}
                  variant="outline"
                >
                  <Trans>Cancel</Trans>
                </Button>

                {((editMode || (!editMode && form.time.value)) && (
                  <SaveButton userId={userId} form={form} onSave={_onAction} />
                )) || (
                  <StartButton
                    userId={userId}
                    form={form}
                    onStart={_onAction}
                  />
                )}
              </React.Fragment>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default observer(ModalEntry);
