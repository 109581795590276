import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  IconButton,
  Input,
  Radio,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { Trans, t } from "@lingui/macro";
import { ModalConfirm } from "@src/components/ui-kit";
import { Icon } from "@src/components/ui-kit/Icon";
import { useStore } from "@src/utils/hooks";
import { observer } from "mobx-react-lite";
import { Fragment, FunctionComponent } from "react";

export const Financial: FunctionComponent = observer(function Financial() {
  const {
    settingsModalStore: { financialStore },
  } = useStore();

  return (
    <Fragment>
      <TableContainer>
        <Table size="sm" variant="simple">
          <TableCaption paddingInlineStart="0" placement="top">
            <Heading as="h3" textAlign="left" size="md">
              <Trans>VAT rates</Trans>
            </Heading>
          </TableCaption>
          <Thead>
            <Tr>
              <Th textAlign="center">
                <Trans>Default</Trans>
              </Th>
              <Th w="99%">
                <Trans>Amount</Trans>
              </Th>
              <Th textAlign="center">
                <Trans>Actions</Trans>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {financialStore.form.form.$.vatRates.$.map((vatRate) => (
              <Tr key={vatRate.internalId}>
                <Td>
                  <Flex as="label" align="center" justify="center">
                    <Radio
                      isChecked={vatRate.$.default.value}
                      name="default"
                      onChange={(event) => {
                        if (event.target.checked) {
                          financialStore.form.makeDefault(vatRate);
                        }
                      }}
                    />
                  </Flex>
                </Td>
                <Td>
                  <FormControl isInvalid={vatRate.$.amount.hasError}>
                    <Input
                      inputMode="decimal"
                      onChange={(event) =>
                        vatRate.$.amount.onChange(event.target.value)
                      }
                      type="number"
                      value={vatRate.$.amount.value}
                    />
                    <FormErrorMessage>
                      <Trans>VAT rate amount is required</Trans>
                    </FormErrorMessage>
                  </FormControl>
                </Td>
                <Td>
                  <Flex align="center" justify="center" gap="3">
                    <Tooltip
                      hasArrow
                      label={
                        financialStore.form.form.$.vatRates.$.length === 1
                          ? t`You cannot remove the last VAT rate`
                          : t`Remove this VAT rate`
                      }
                    >
                      <IconButton
                        aria-label={t`Delete this VAT rate`}
                        icon={<Icon name="trash-03" />}
                        isDisabled={
                          financialStore.form.form.$.vatRates.$.length === 1
                        }
                        onClick={() =>
                          financialStore.form.requestVatRateRemoval(vatRate)
                        }
                        variant="ghost"
                      />
                    </Tooltip>
                    <ModalConfirm
                      heading={t`Confirm removal of VAT rate`}
                      onClose={() =>
                        (financialStore.form.vatRateToDelete = undefined)
                      }
                      isOpen={financialStore.form.vatRateToDelete === vatRate}
                      onConfirm={() =>
                        financialStore.form.removeVatRate(vatRate)
                      }
                    >
                      <Trans>
                        Removal of this VAT rate will keep past uses untouched,
                        but you will not be able to use it in the future
                        anymore. Do you want to remove this VAT rate?
                      </Trans>
                    </ModalConfirm>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th colSpan={3}>
                <Flex justify="end" paddingBlock="3">
                  <Button
                    leftIcon={<Icon name="plus" />}
                    onClick={() => financialStore.form.addNewVatRate()}
                    variant="outline"
                  >
                    <Trans>Add new VAT rate</Trans>
                  </Button>
                </Flex>
              </Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      <TableContainer>
        <Table size="sm" variant="simple">
          <TableCaption placement="top">
            <Heading as="h3" textAlign="left" size="md">
              <Trans>Accounting accounts</Trans>
            </Heading>
          </TableCaption>
          <Thead>
            <Tr>
              <Th minW="280px">
                <Trans>Number</Trans>
              </Th>
              <Th w="99%">
                <Trans>Title</Trans>
              </Th>
              <Th textAlign="center">
                <Trans>Actions</Trans>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {financialStore.form.form.$.accountingAccounts.$.map((account) => (
              <Tr key={account.internalId}>
                <Td verticalAlign="top">
                  <FormControl isInvalid={account.$.account_number.hasError}>
                    <Input
                      onChange={(event) =>
                        account.$.account_number.onChange(event.target.value)
                      }
                      type="text"
                      value={account.$.account_number.value}
                    />
                    <FormErrorMessage>
                      {account.$.account_number.error}
                    </FormErrorMessage>
                  </FormControl>
                </Td>
                <Td verticalAlign="top">
                  <FormControl isInvalid={account.$.title.hasError}>
                    <Input
                      onChange={(event) =>
                        account.$.title.onChange(event.target.value)
                      }
                      type="text"
                      value={account.$.title.value}
                    />
                    <FormErrorMessage>
                      <Trans>Title is required</Trans>
                    </FormErrorMessage>
                  </FormControl>
                </Td>
                <Td>
                  <Flex align="center" justify="center" gap="3">
                    <Tooltip
                      hasArrow
                      label={
                        financialStore.form.form.$.accountingAccounts.$
                          .length === 1
                          ? t`You cannot remove the last account`
                          : t`Remove this account`
                      }
                    >
                      <IconButton
                        aria-label={t`Delete this account`}
                        icon={<Icon name="trash-03" />}
                        isDisabled={
                          financialStore.form.form.$.accountingAccounts.$
                            .length === 1
                        }
                        onClick={() =>
                          financialStore.form.requestAccountingAccountRemoval(
                            account,
                          )
                        }
                        variant="ghost"
                      />
                    </Tooltip>
                    <ModalConfirm
                      heading={t`Confirm removal of accounting account`}
                      onClose={() =>
                        (financialStore.form.accountingAccountToDelete =
                          undefined)
                      }
                      isOpen={
                        financialStore.form.accountingAccountToDelete ===
                        account
                      }
                      onConfirm={() =>
                        financialStore.form.removeAccountingAccount(account)
                      }
                    >
                      <Trans>
                        Removal of this accounting account will keep past uses
                        untouched, but you will not be able to use it in the
                        future anymore. Do you want to remove this account?
                      </Trans>
                    </ModalConfirm>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th colSpan={3}>
                <Flex justify="end" paddingBlock="3">
                  <Button
                    leftIcon={<Icon name="plus" />}
                    onClick={() =>
                      financialStore.form.addNewAccountingAccount()
                    }
                    variant="outline"
                  >
                    <Trans>Add new accounting account</Trans>
                  </Button>
                </Flex>
              </Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </Fragment>
  );
});
